import { useFonts } from 'expo-font';
import { StyleSheet, Text, View } from 'react-native';
import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';

import { Button, TextField } from '@mui/material';
import colors from '../utils/colors';
import CloseIcon from '@mui/icons-material/Close';
import { DesktopDatePicker, MobileDateTimePicker, TimePicker } from '@mui/x-date-pickers';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSnackbar } from 'material-ui-snackbar-provider';


//IMPORT DATAS // 
import employeeApi from '../api/employee';

//  FIN DATAS // 


const screenHeight = window.innerHeight;


function LayoutScreen({ popUp, setPopUp, data, setLastModification, lastModification }) {


    console.log("DATAS ->");

    const snackbar = useSnackbar();

    //alert(JSON.stringify(data));


    const validateForm = async () => {
        if (newDate.employeeId == null || newDate.date == null || newDate.type == null || newDate.ampm == null) snackbar.showMessage("Certains champs sont vides.");
        else {
            newDate.date = moment(newDate.date).format("YYYY-MM-DD");
            snackbar.showMessage("Modification de l'emploi du temps...");
            const response = await employeeApi.setEmployeeDate(newDate);
            var logId = null;
            var logId = response.data.log;
            /*if (newDate.employeeId2 != null) {
                newDate.employeeId = newDate.employeeId2;
                const response = await employeeApi.setEmployeeDate(newDate);
                if(logId != null && logId != "") logId = logId +" & "+ response.data.log;
            }*/
            setLastModification(logId);
            setNewDate(emptyDateForm);
            setPopUp(false);    
        }
    };

    var getDates = function (thisDate) {
        console.log(thisDate);
        var year = thisDate.getFullYear();
        var month = thisDate.getMonth();
        var date = new Date(year, month, 1);
        console.log(year, month);
        var names = ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'];
        var result = { "": [], "lun": [], "mar": [], "mer": [], "jeu": [], "ven": [], "sam": [], "dim": [] };
        var firstDay = names[date.getDay()];
        for (var i = 0; i < names.length; i++) {
            if (firstDay == names[i] && firstDay != "dim") break;
            if (names[i] != "dim") result[names[i]].push({ "completeDate": null });
        }

        while (date.getMonth() == month) {

            var element = { "completeDate": date.toLocaleDateString('fr-FR'), 'date': date.getDate(), "day": names[date.getDay()] };
            result[element.day].push(element);
            date.setDate(date.getDate() + 1);


        }
        return result;
    }

    const [listOptions, setListOptions] = useState([]);

    const loadOptions = async () => {
        const response = await employeeApi.getEmployeeDateTypes();
        setListOptions(response.data);
    }

    const cancelMeeting = async (id) => {
        if (id == null) snackbar.showMessage("Un problème est servenu");
        else {
            const response = await meetingApi.cancelMeeting({ meetingId: id });
            if (response.ok) {
                snackbar.showMessage("Rendez-vous correctement annulé.");
                setPopUp(false);
            }
            else snackbar.showMessage("Un problème est servenu");
        }
    };

    const closeMeeting = async (id) => {
        if (id == null) snackbar.showMessage("Un problème est servenu");
        else {
            const response = await meetingApi.cancelMeeting({ meetingId: id });
            if (response.ok) {
                snackbar.showMessage("Rendez-vous correctement terminé. Si les sms sont activés, le client recevra confirmation.");
                setPopUp(false);
            }
            else snackbar.showMessage("Un problème est servenu");
        }
    };

    const noShowMeeting = async (id) => {
        if (noShowReason == null || noShowReason == "") snackbar.showMessage("Le commentaire ne peut pas être vide.");
        else {
            if (id == null) snackbar.showMessage("Un problème est servenu");
            else {
                const response = await meetingApi.noshowMeeting({ meetingId: id, reason: noShowReason });
                if (response.ok) {
                    snackbar.showMessage("NoShow validé.");
                    setnoShowReason("");
                    setPopUp(false);
                }
                else snackbar.showMessage("Un problème est servenu");
            }
        }
    };


    const getCurrentDateString = function (type) {
        var days = ["Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche", "Lundi"];
        var months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
        var day = days[currentDate.getDay()];
        var month = months[currentDate.getMonth()];
        if (dayMonth == "day" && type == "long") {
            return day + " " + currentDate.getDate() + " " + month + " " + currentDate.getFullYear();
        }
        else {
            return month + " " + currentDate.getFullYear();
        }
    }

    const updateDates = function (type, value) {

        console.log("-------------");
        console.log(currentDate);
        console.log(dayMonth);

        if (type === "change") {
            setCurrentDate(value);
        }
        if (type == "add") {
            setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + value)));
        }
        if (type == "remove") {
            setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - value)))
        }

    }

    const [value, setValue] = useState(null);
    const [dayMonth, setDayMonth] = useState("day");
    const [currentDate, setCurrentDate] = useState(new Date());
    const [currentDateString, setCurrentDateString] = useState({ "short": getCurrentDateString("short"), "long": getCurrentDateString("long") });
    const [datePickerOpened, setDatePickerOpened] = useState(false);

    const emptyDateForm = { date: data.date ? data.date : moment(), salonId: null, employeeId: data.employeeId ? data.employeeId : null, employeeId2: null, type: data.type ? data.type : null, ampm: null, dayOff: "0", dayOff2: "0" };
    const [newDate, setNewDate] = useState(emptyDateForm);
    const [disableForm, setDisableForm] = useState(false);

    const [noShowReason, setnoShowReason] = useState(null);
    const [disableNoShowForm, setDisableNoShowForm] = useState(true);

    var workingTime = ["8:00", "20:00"];


    var times = function (hours) {
        var timeList = [];
        var startingHour = hours[0];
        var lastHour = hours[1];
        var date = moment("01/01/2001 " + startingHour);

        console.log(date.format("HH:mm"));

        while (date.format("HH:mm") != lastHour) {
            timeList.push(date.format("HH:mm"));
            date.add(60, "m");
        }

        timeList.push(date.format("HH:mm"))

        return timeList;
    };

    const [employees, setListEmployees] = useState([]);

    const loadEmployees = async () => {

        const response = await employeeApi.getActiveEmployees();
        console.log("REPONSE");
        console.log(response.data.employeeFound);
        if (!response.ok) {
            console.log("test");
            console.log(response.data);
        }

        setListEmployees(response.data);
    };


    const timesList = times(workingTime);
    const [selectedEmployee, setSelectedEmployee] = useState("");


    var dates = getDates(currentDate);

    useEffect(() => {
        setCurrentDateString({ "short": getCurrentDateString("short"), "long": getCurrentDateString("long") });
        loadOptions();
        loadEmployees();
    }, [currentDate, dayMonth]);

    const [selectedType, setSelectedType] = useState("");

    const [loaded] = useFonts({
        poppins: require('../fonts/Poppins-Regular.ttf'),
        poppinsMedium: require('../fonts/Poppins-Medium.ttf'),
        poppinsSemiBold: require('../fonts/Poppins-SemiBold.ttf'),
        poppinsBold: require('../fonts/Poppins-Bold.ttf')
    });

    if (!loaded) {
        return null;
    }



    return (

        <View style={[styles.screenContainerExtraMarges, { backgroundColor: "#ffffff", marginLeft: 200, marginRight: 400, marginTop: 50, marginBottom: 50, shadowColor: "#000", shadowOffset: 0, shadowOpacity: 0.23, shadowRadius: 5 }]}>
            <Button variant="contained" onClick={() => (setPopUp(false))} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 14, backgroundColor: "transparent", color: colors.ebony, borderRadius: 7, marginBottom: 20, textTransform: 'none', width: 180, alignSelf: "flex-end", height: 50 }} endIcon={<CloseIcon />}>Fermer</Button>
            {
                !disableNoShowForm ? (
                    <View style={styles.formContainer}>
                        <View style={styles.formColumn}>
                            <View style={styles.inputRow}>
                                <View style={styles.formLabelColumn}>
                                    <Text style={styles.inputLabel}>Infos noShow</Text>
                                </View>
                                <View style={styles.formInputsColumn}>
                                    <TextField
                                        value={noShowReason ? noShowReason : ""}
                                        onChange={(value) => (setnoShowReason(value.target.value))}
                                        fullWidth label="" multiline rows={4} autoCorrect='false' placeholder='Informations noshow' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                </View>
                            </View>
                        </View>
                    </View>
                ) :

                    disableForm ? (
                        <View style={styles.formContainer}>
                            <View style={styles.formColumn}>
                            </View>
                            <View style={[styles.formColumn, { marginLeft: 50 }]}>
                            </View>
                        </View>
                    ) : (
                        <View style={styles.formContainer}>
                            <View style={styles.formColumn}>
                                <View style={styles.inputRow}>
                                    <View style={styles.formLabelColumn}>
                                        <Text style={styles.inputLabel}>Date</Text>
                                    </View>
                                    <View style={styles.formInputsColumn}>
                                        <DesktopDatePicker
                                            fullWidth
                                            sx={{ width: "100%" }}
                                            label=""
                                            variant="standard"
                                            placeholder="Date"
                                            inputFormat="DD/MM/YYYY"
                                            value={newDate.date ? newDate.date : moment()}
                                            onChange={(value) => (setNewDate((prevState) => ({
                                                ...prevState,
                                                date: value
                                            })))}
                                            renderInput={(params) => <TextField sx={{ width: "100%", backgroundColor: colors.smokedWhite, paddingLeft: "30px", borderRadius: 25, border: "none", "& fieldset": { border: "none" } }} {...params} />}
                                        />
                                    </View>
                                </View>
                                <View style={styles.inputRow}>
                                    <View style={styles.formLabelColumn}>
                                        <Text style={styles.inputLabel}>Type</Text>
                                    </View>
                                    <View style={styles.formInputsColumn}>
                                        <Select
                                            fullWidth
                                            sx={{
                                                "& fieldset": { border: 'none' },
                                            }}
                                            value={selectedType ? selectedType : ""}
                                            onChange={(value) => {
                                                (setNewDate((prevState) => ({
                                                    ...prevState,
                                                    type: JSON.parse(value.target.value).shortName,
                                                    salonId: JSON.parse(value.target.value).salonId,
                                                }))); setSelectedType(value.target.value)
                                            }}
                                            disableUnderline={true}
                                            displayEmpty
                                            IconComponent={(props) => (
                                                <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                            )}
                                        >
                                            {listOptions.map((item, key) => (
                                                <MenuItem value={JSON.stringify({ shortName: item.shortName, salonId: item.salonId ? item.salonId : null })}>{item.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </View>
                                </View>
                                <View style={styles.inputRow}>
                                    <View style={styles.formLabelColumn}>
                                        <Text style={styles.inputLabel}>Employé</Text>
                                    </View>
                                    <View style={styles.formInputsColumn}>
                                        <Select
                                            fullWidth
                                            sx={{
                                                "& fieldset": { border: 'none' },
                                            }}
                                            value={newDate.employeeId ? newDate.employeeId : ""}
                                            onChange={(value) => {
                                                (setNewDate((prevState) => ({
                                                    ...prevState,
                                                    employeeId: value.target.value
                                                })));
                                            }}
                                            disableUnderline={true}
                                            displayEmpty
                                            IconComponent={(props) => (
                                                <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                            )}
                                        >
                                            {
                                                employees != [] ? employees.map((item, key) => (
                                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                                )) : null
                                            }
                                        </Select>
                                    </View>
                                </View>
                                <View style={styles.inputRow}>
                                    <View style={styles.formLabelColumn}>
                                        <Text style={styles.inputLabel}>Employé 2 (Facultatif)</Text>
                                    </View>
                                    <View style={styles.formInputsColumn}>
                                        <Select
                                            fullWidth
                                            sx={{
                                                "& fieldset": { border: 'none' },
                                            }}
                                            value={newDate.employeeId2 ? newDate.employeeId2 : ""}
                                            onChange={(value) => {
                                                (setNewDate((prevState) => ({
                                                    ...prevState,
                                                    employeeId2: value.target.value
                                                })));
                                            }}
                                            disableUnderline={true}
                                            displayEmpty
                                            IconComponent={(props) => (
                                                <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                            )}
                                        >
                                            {
                                                employees != [] ? employees.map((item, key) => (
                                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                                )) : null
                                            }
                                        </Select>
                                    </View>
                                </View>
                                <View style={styles.inputRow}>
                                    <View style={styles.formLabelColumn}>
                                        <Text style={styles.inputLabel}>Durée</Text>
                                    </View>
                                    <View style={styles.formInputsColumn}>
                                        <Select
                                            fullWidth
                                            sx={{
                                                "& fieldset": { border: 'none' },
                                            }}
                                            value={newDate.ampm ? newDate.ampm : ""}
                                            onChange={(value) => {
                                                (setNewDate((prevState) => ({
                                                    ...prevState,
                                                    ampm: value.target.value
                                                })));
                                            }}
                                            disableUnderline={true}
                                            displayEmpty
                                            IconComponent={(props) => (
                                                <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                            )}
                                        >
                                            <MenuItem value="am">Matin</MenuItem>
                                            <MenuItem value="pm">Après-Midi</MenuItem>
                                            <MenuItem value="day">Journée</MenuItem>
                                            <MenuItem value="week">Semaine</MenuItem>
                                            <MenuItem value="month">Mois</MenuItem>
                                        </Select>
                                    </View>
                                </View>

                                <View style={styles.inputRow}>
                                    <View style={styles.formLabelColumn}>
                                        <Text style={styles.inputLabel}>Jour de repos 1</Text>
                                    </View>
                                    <View style={styles.formInputsColumn}>
                                        <Select
                                            fullWidth
                                            sx={{
                                                "& fieldset": { border: 'none' },
                                            }}
                                            value={newDate.dayOff ? newDate.dayOff : ""}
                                            onChange={(value) => {
                                                (setNewDate((prevState) => ({
                                                    ...prevState,
                                                    dayOff: value.target.value
                                                })));
                                            }}
                                            disableUnderline={true}
                                            displayEmpty
                                            IconComponent={(props) => (
                                                <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                            )}
                                        >
                                            <MenuItem value="0">Aucun</MenuItem>
                                            <MenuItem value="1">Lundi</MenuItem>
                                            <MenuItem value="2">Mardi</MenuItem>
                                            <MenuItem value="3">Mercredi</MenuItem>
                                            <MenuItem value="4">Jeudi</MenuItem>
                                            <MenuItem value="5">Vendredi</MenuItem>
                                            <MenuItem value="6">Samedi</MenuItem>
                                            <MenuItem value="7">Dimanche</MenuItem>
                                        </Select>
                                    </View>
                                </View>
                                <View style={styles.inputRow}>
                                    <View style={styles.formLabelColumn}>
                                        <Text style={styles.inputLabel}>Jour de repos 2</Text>
                                    </View>
                                    <View style={styles.formInputsColumn}>
                                        <Select
                                            fullWidth
                                            sx={{
                                                "& fieldset": { border: 'none' },
                                            }}
                                            value={newDate.dayOff2 ? newDate.dayOff2 : ""}
                                            onChange={(value) => {
                                                (setNewDate((prevState) => ({
                                                    ...prevState,
                                                    dayOff2: value.target.value
                                                })));
                                            }}
                                            disableUnderline={true}
                                            displayEmpty
                                            IconComponent={(props) => (
                                                <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                            )}
                                        >
                                            <MenuItem value="0">Aucun</MenuItem>
                                            <MenuItem value="1">Lundi</MenuItem>
                                            <MenuItem value="2">Mardi</MenuItem>
                                            <MenuItem value="3">Mercredi</MenuItem>
                                            <MenuItem value="4">Jeudi</MenuItem>
                                            <MenuItem value="5">Vendredi</MenuItem>
                                            <MenuItem value="6">Samedi</MenuItem>
                                            <MenuItem value="7">Dimanche</MenuItem>
                                        </Select>
                                    </View>
                                </View>
                            </View>
                        </View>
                    )}

            <View style={styles.centeredControllers}>

                {
                    !disableNoShowForm ? (
                        <Fragment>
                            <Button variant="contained" onClick={() => { noShowMeeting(data.id) }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, fontSize: 12, backgroundColor: colors.blue, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 300, alignSelf: "center", height: 50 }}>VALIDER LE NOSHOW</Button>
                            <Button variant="contained" onClick={() => { setnoShowReason(""); setDisableNoShowForm(true); snackbar.showMessage('Formulaire réinitialisé.') }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, fontSize: 12, backgroundColor: colors.red, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 300, alignSelf: "center", height: 50 }}>ANNULER LE NOSHOW</Button>
                        </Fragment>
                    ) :
                        disableForm ?

                            (<Fragment>
                                {
                                    data.state == 0 ?
                                        (
                                            <Fragment>
                                                <Button variant="contained" onClick={() => { setDisableNoShowForm(false) }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, fontSize: 12, backgroundColor: colors.cashmere, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 180, alignSelf: "center", height: 50 }}>NO SHOW</Button>
                                                <Button variant="contained" onClick={() => { closeMeeting(data.id) }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, fontSize: 12, backgroundColor: colors.lightBlue, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 180, alignSelf: "center", height: 50 }}>SMS</Button>
                                                <Button variant="contained" onClick={() => { setDisableForm(false) }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, fontSize: 12, backgroundColor: colors.blue, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 180, alignSelf: "center", height: 50 }}>MODIFIER</Button>
                                                <Button variant="contained" onClick={() => { cancelMeeting(data.id) }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: colors.red, color: "#FFFFFF", borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 180, alignSelf: "center", height: 50 }}>ANNULER RDV</Button>
                                            </Fragment>
                                        )
                                        :
                                        null
                                }
                            </Fragment>)


                            :
                            <Fragment>
                                <Button variant="contained" onClick={() => { validateForm() }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, fontSize: 12, backgroundColor: colors.blue, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 300, alignSelf: "center", height: 50 }}>VALIDER</Button>
                            </Fragment>
                }



            </View>
        </View>
    );
}

export default LayoutScreen;


const styles = StyleSheet.create({

    body: {
        backgroundColor: colors.lightBlue
    },

    mainView: {
        backgroundColor: colors.smokedWhite,
        flex: 1,
    },

    //FORM

    formContainer: {
        flex: 1,
        flexDirection: "row",
        marginRight: 50,
        marginLeft: 50,
        marginBottom: 50

    },

    formColumn: {
        flex: 1,
        flexDirection: "column",
        width: "auto",

    },

    formLabelColumn: {
        flex: 1,
        flexDirection: "row",
        paddingTop: 20,

    },

    formInputsColumn: {
        width: "70%",
        flexDirection: "row",
        paddingLeft: 20,
        paddingRight: 20
    },

    formInputsGroup: {
        flexDirection: "row",
        flex: 1,
        justifyContent: "space-evenly"
    },

    inputRow: {
        flexDirection: "row",
        marginBottom: 20,
    },

    inputLabel: {
        fontFamily: "poppinsSemiBold",
        color: colors.ebony,
        lineHeight: "100%",
        height: "100%",
    },

    separator: {
        height: 2,
        backgroundColor: colors.ebony,
        width: "100%",
        marginBottom: 50
    },

    // END FORM



    monthPicker: {
        borderColor: "#E2E2EA"
    },

    customSearchInputContainer: {
        borderColor: colors.ebony,
        borderWidth: 1,
        width: 400,
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        flexDirection: "row",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 5,
        paddingBottom: 5,
        borderRadius: 25,

    },

    customSearchInput: {
        borderWidth: 0,
        borderColor: "transparent",
        flex: 1,
        height: "100%",
        width: "auto",
        fontFamily: "poppinsSemiBold",
        fontWeight: "bold",
        fontSize: 14,
        color: colors.ebony,
        outlineColor: 'transparent'

    },

    eventText: {
        flex: 1,
        flexDirection: "row",
        wdith: "100%",
        marginBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: "rgba(0, 0, 0, 0.1)",
        paddingTop: 5,
        paddingBottom: 5
    },

    eventTextTitle: {
        fontFamily: "poppinsSemiBold",
        marginRight: 10
    },

    eventGrid: {
        flex: 1,
        width: "100%",
        flexDirection: "row",
        top: 0,
        position: "absolute"

    },

    eventRow: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },

    eventColumn: {
        flex: 1,
        flexDirection: "column",
        paddingLeft: 5,
        paddingRight: 5
    },

    eventHeader: {
        height: 40,
        borderRadius: 25,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.yellow,
        marginBottom: 20
    },

    eventHeaderTitle: {
        fontFamily: "poppinsSemiBold",
        fontSize: 16
    },

    eventContent: {
        flex: 1,
        flexDirection: "column",
    },

    eventBox: {
        backgroundColor: colors.egg,
        borderWidth: 4,
        borderColor: colors.yellow,
        borderRadius: 10,
        height: 100,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20
    },

    eventButtons: {
        flex: 1,
        flexDirection: "row",
    },

    hourText: {
        textAlign: "center",
        width: 60,
        fontFamily: "poppinsSemiBold"
    },

    hoursGrid: {
        marginLeft: 10,
        marginRight: 10,
        paddingTop: 60,
        position: "relative",
    },

    hourRow: {
        height: 100,
        borderBottomWidth: 1,
        borderColor: "#e0e0e0",
        justifyContent: "center",

    },

    screenControllers: {
        marginBottom: 40,
        alignContent: "center",
        justifyContent: "center",
        textAlign: "center"
    },

    centeredControllers:
    {
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center",
        marginBottom: 20,
        paddingLeft: 50,
        paddingRight: 50
    },


    controllerTitle: {
        fontSize: 12,
        fontFamily: "poppinsSemiBold",
        textAlignVertical: "center",
        marginTop: 8,
        marginRight: 10
    },

    buttonGroup:
    {
        flexDirection: "row",
        borderRadius: 7,
        padding: 2,
        backgroundColor: colors.milk
    },

    anchorRightControllers: {
        flexDirection: "row",
        position: "absolute",
        right: 15,
    },

    monthCalendar: {
        width: "100%",
        flex: 1,
        zIndex: 50,
        flexDirection: "row"
    },

    periodLabel: {
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center"
    },

    employeeDay: {
        flexDirection: "column",
        flex: 1,
        marginBottom: 2,
    },

    gridEmployeeName: {
        flex: 1
    },

    gridEmployeeLabel:
    {
        fontFamily: "poppinsSemiBold",
        color: colors.ebony
    },

    gridBox: {
        width: "100%",
        flex: 1,
        alignItems: "center"
    },

    gridBorder: {
        borderWidth: 0.5,
        borderColor: colors.charcoal
    },

    dayColumn:
    {
        flex: 1,
    },

    dayTitle:
    {
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: 50,
        backgroundColor: colors.smokedWhite,
        marginBottom: 20
    },

    dateBox:
    {
        height: 100,
        borderWidth: 1,
        borderColor: colors.charcoal,
        paddingLeft: 10,
        paddingRight: 10,
    },

    dayNumber: {
        paddingLeft: 10,
        fontFamily: "poppins",
        color: colors.mist,
        fontSize: 12,
        paddingTop: 10,
        paddingBttom: 10
    },

    navbar: {
        height: 75,
        width: "100%",
        backgroundColor: "#FFFFFF",
        borderBottomColor: "#E2E2EA",
        borderBottomWidth: 2,
        flexDirection: "row",
        position: "fixed",
        zIndex: 10
    },

    switchScreen: {
        backgroundColor: colors.ebony,
        width: "100%",
        height: 50,
        alignItems: "center",
        justifyContent: "center"
    },

    screenContainer: {
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        width: "100%",
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 20,
        paddingBottom: 20,
        flex: 1
    },

    screenContainerExtraMarges: {
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        width: "100%",
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 50,
        paddingBottom: 50,
        flex: 1
    },

    categoryButton: {
        backgroundColor: colors.linen,
        width: "100%",
        height: 50,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 0
    },

    logoContainer: {
        width: 225,
        height: "100%",
        padding: 5,
        alignItems: "center",
        borderRightColor: "#E2E2EA",
        borderRightWidth: 1
    },

    shortcutContainer: {
        flex: 1,
        flexDirection: "row",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10
    },

    searchContainer: {
        flex: 1
    },

    screenTitle: {
        borderRadius: 5,
        width: "100%",
        height: 50,
        backgroundColor: colors.linen,
        marginBottom: 10,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },

    titleSeparator: {
        marginLeft: 20,
        marginRight: 20,
        fontSize: 20,
        color: "#D8D8D8"
    },

    pageAction: {
        fontSize: 18,
        fontWeight: "600",
        color: colors.mist
    },

    pageTitle: {
        fontFamily: "poppinsSemiBold",
        textTransform: "uppercase",
        fontWeight: "bold",
        fontSize: 20,
        color: colors.ebony
    },

    searchInput: {
        width: 300
    },

    mainContainer: {
        flex: 1,
        flexDirection: "row"
    },

    aside: {
        paddingTop: 20,
        marginTop: 75,
        width: 225,
        height: screenHeight - 75,
        backgroundColor: colors.snow,
        borderRightColor: "#E2E2EA",
        borderRightWidth: 1,
        position: "fixed",
        zIndex: 10
    },

    asideContainer: {
        flex: 1,
    },

});
