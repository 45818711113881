import client from './client';

const newPrestation = (datas) => client.post("/newPrestation", datas);
const updatePrestation = (datas) => client.post("/updatePrestation", datas);
/*const getAllInterventions = () => client.get(endpoint + 'getAllInterventions');
const updateIntervention = (datas) => client.put(endpoint + "updateIntervention", datas);
const getPOS = () => client.get("pointOfSale/getAllPointOfSales");
const getSellerPOS = () => client.get("pointOfSale/getSellerPos");
const newIntervention = (datas) => client.post(endpoint + "createIntervention", datas);
const generatePDF = (datas) => client.put(endpoint + "generateRapport2", datas);
const downloadPDF = (name) => client.get(endpoint+"downloadPDF/"+name);*/

export default
{ 
    newPrestation, updatePrestation
}