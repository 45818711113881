import client from './client';

const newCustomer = (datas) => client.post("/newCustomer", datas);
const updateCustomer = (datas) => client.post("/updateCustomer", datas);
const getCustomerInfos = (customerId) => client.get("/getCustomerInfos?customerId="+customerId);
const getCustomerLastMeetings = (customerId) => client.get("/getCustomerLastMeetings?customerId="+customerId);
const getCustomerNextMeetings = (customerId) => client.get("/getCustomerNextMeetings?customerId="+customerId);
/*const getAllInterventions = () => client.get(endpoint + 'getAllInterventions');
const updateIntervention = (datas) => client.put(endpoint + "updateIntervention", datas);
const getPOS = () => client.get("pointOfSale/getAllPointOfSales");
const getSellerPOS = () => client.get("pointOfSale/getSellerPos");
const newIntervention = (datas) => client.post(endpoint + "createIntervention", datas);
const generatePDF = (datas) => client.put(endpoint + "generateRapport2", datas);
const downloadPDF = (name) => client.get(endpoint+"downloadPDF/"+name);*/

export default
{ 
    newCustomer, getCustomerInfos, getCustomerNextMeetings, getCustomerLastMeetings, updateCustomer
}