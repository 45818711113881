import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import React, { useEffect, useState, useContext } from 'react';
import {Button, Input} from '@mui/material';
import colors from '../utils/colors';




function ElementsScreen({ route, navigation }) {
    


    const squares = [];
    for (let key in colors) {
        console.log(key, colors[key]);
        squares.push(<View style={[styles.square,{backgroundColor: colors[key]}]}><Text style={{color: "#ab169a"}}>{key} {colors[key]}</Text></View>);
    }

  
    

    return (
        <View style={styles.mainView}>
            {squares}
            <View style={styles.container}>
                <Image style={styles.logo} source={require("../../assets/logo-dog-model.png")} resizeMode="contain"></Image>
            </View>
        </View >
    );
}

export default ElementsScreen;

const styles = StyleSheet.create({

    input: {
        marginBottom: 20,
        borderRadius: 100,
        backgroundColor: "#F8F4EF",
    },

    square: {
        width: 200,
        height: 50,
        marginBottom: 10,
        marginTop: 10
    },

    navTitle:
    {
        fontFamily: "Brandon Bold"
    },

    logo: {
        width: "90%",
        minHeight: 200,
        marginBottom: 50
    },

    mainView: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: "center",
        alignItems: "center",
        //backgroundColor: colors.cashmere
    },

    container: {
        width: 500,
        backgroundColor: "white",
        borderRadius: 10,
        padding: 20,
    },

    buttonContainer:
    {
        backgroundColor: "black",
        borderRadius: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5
    },


    buttonText:
    {
        fontWeight: "bold",
        color: "white",
        justifyContent: "center",
        textAlign: "center",
        width: "100%"
    },

});
