import * as SecureStore from 'expo-secure-store';
import jwtDecode from 'jwt-decode';
import AsyncStorage from '@react-native-async-storage/async-storage';

const key = "authToken";

const setAppLaunched = () => {
    AsyncStorage.getItem("HAS_LAUNCHED", 'true');
};

const checkFirstLaunch = async () => {
    try {
        const hasLaunched = await AsyncStorage.getItem("HAS_LAUNCHED");
        if (!hasLaunched) {
            setAppLaunched();
            return true;
        }
        return false;
    } catch (error) {
        return false;
    }
};

const storeToken = async authToken => {
    console.log('store token', JSON.stringify(authToken));
    try {
        await AsyncStorage.setItem(key, JSON.stringify(authToken));
        console.log("stored");
        console.log(JSON.stringify(authToken));
    } catch (error) {
        console.log('Error storing the auth token', error);
    }
}

const getToken = async () => {
    try {
        const thisToken = await AsyncStorage.getItem(key);
        console.log('thisToken');
        console.log(thisToken);
        return await JSON.parse(thisToken).token;
    } catch (error) {
        console.log('Error getting the auth token', error);
    }

}

const getUser = async () => {
    try {
        const thisToken = await AsyncStorage.getItem(key);
        return await (thisToken) ? JSON.parse(thisToken).user : null;
    } catch (error) {
        console.log('Error getting the auth token', error);
    }
}

const removeToken = async () => {
    try {
        await AsyncStorage.removeItem(key);
    } catch (error) {
        console.log('Error removing the auth token', error);
    }
}


export default { getToken, getUser, removeToken, storeToken, setAppLaunched, checkFirstLaunch }