import * as React from "react"
import Svg, { Path } from "react-native-svg"

const ContactIcon = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <Path
      d="M32.4 36h-27A5.406 5.406 0 0 1 0 30.6v-27A3.6 3.6 0 0 1 3.6 0h28.8A3.6 3.6 0 0 1 36 3.6v28.8a3.6 3.6 0 0 1-3.6 3.6Zm-27-7.2a1.8 1.8 0 1 0 0 3.6h27v-3.6ZM3.6 3.6v21.907a5.389 5.389 0 0 1 1.8-.307h1.8V3.6Zm7.2 0v21.6h21.6V3.6Zm15.277 17.122h-9a1.8 1.8 0 1 1 0-3.6h9a1.8 1.8 0 1 1 0 3.6Zm-3.6-5.4h-5.4a1.8 1.8 0 1 1 0-3.6h5.4a1.8 1.8 0 1 1 0 3.6Z"
      style={{
        fill: "#92929d",
      }}
    />
  </Svg>
)

export default ContactIcon
