import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from 'moment';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


const generateNewPdf = function (date, listDate, listEmployees, employeeDates, listSalons, name, action) {

    console.log(JSON.stringify(employeeDates));

    const listEmployeesAtDate = function (datas) {
        console.log("ListEmployeeFunction");
        console.log(datas);
        var employees = [];
        var string = " ";
        Object.entries(employeeDates).forEach(function (value, index) {
            value[1].forEach(function (item, key) {
                if (moment(item.date).format('DD/MM/YYYY') == datas.date && item.ampm == datas.ampm && item.Employee.id == datas.employeeId) employees.push(item.type);
            });
        });
        return employees.join(" - ") != "" ? employees.join(" - ") : " ";

    }

    var bodyMeetings = function () {
        var header = [{ colSpan: 2, text: date }, ''];
        listEmployees.forEach(function (value, index) {
            header.push(value.name);
        });
        var bodyContent = [];
        bodyContent.push(header);


        listDate.map(function (date, index) {
            if (date.day != "dim") {
                var firstRow = [{ rowSpan: 2, text: date.day + " " + date.date }, "A"];
                var secondRow = ["", { text: "M", pageBreak: date.day == "sam" ? 'after' : null }];
                listEmployees.forEach(function (value, index) {
                    firstRow.push(listEmployeesAtDate({ employeeId: value.id, date: date.completeDate, ampm: "am" }));
                    secondRow.push(listEmployeesAtDate({ employeeId: value.id, date: date.completeDate, ampm: "pm" }));
                })
                bodyContent.push(firstRow);
                bodyContent.push(secondRow);
            }
        });


        console.log(bodyContent);
        return bodyContent;
    }

    var listMeetings = bodyMeetings();


    var dd = {
        pageOrientation: 'landscape',
        content: [
            {
                text: 'Jours de présences ' + date,
                style: 'header'
            },
            {
                style: 'tableExample',
                table: {
                    widths: '*',
                    body: listMeetings,

                }
            }
        ],
        styles: {
            tableExample:
            {
                fontSize: 8,
                width: '100%'
            },
            header: {
                fontSize: 18,
                bold: true,
                alignment: "center",
                marginBottom: 20,
            },
            subheader: {
                fontSize: 15,
                bold: true
            },
            quote: {
                italics: true
            },
            small: {
                fontSize: 8
            }
        }

    }

    if (action == "print") {
        pdfMake.createPdf(dd).print();
    }
    else {
        pdfMake.createPdf(dd).open();
    }

};

export default generateNewPdf
