import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';


import LoginScreen from '../screens/LoginScreen';
import { StatusBar, Platform } from 'react-native';

const Stack = createStackNavigator();



const AuthNavigator = () => (

    <Stack.Navigator>
        <Stack.Screen name="Login" component={LoginScreen} options={{ headerShown: Platform.OS === 'ios' ? false : false, title: "", animationEnabled: false, }} />
    </Stack.Navigator>

)

export default AuthNavigator;