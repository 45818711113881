import { useFonts } from 'expo-font';
import { Image, StyleSheet, Text, TouchableOpacity, View, ScrollView } from 'react-native';
import React, { useEffect, useState, useContext } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import moment, { calendarFormat } from 'moment';
import ReturnIcon from '../../assets/svg/returnIcon';

import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { Button, Input, TextField, ButtonGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import EventNoteIcon from '@mui/icons-material/EventNoteOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import colors from '../utils/colors';
import { fr } from 'date-fns/locale'
//import contactIcon from '../../assets/contactIcon.svg';
//import planningIcon from '../../assets/planningIcon.svg';
import { style } from '@mui/system';
import { Fragment } from 'react';
import { TouchableWithoutFeedback } from 'react-native';
import CalendarIcon from '../../assets/svg/calendarIcon';
import ContactIcon from '../../assets/svg/contactIcon';
import { TextInput } from 'react-native';
import { TimePicker } from '@mui/x-date-pickers';
import { useSnackbar } from 'material-ui-snackbar-provider';


// IMPORT SVG // 
import DogIcon from '../../assets/svg/dogIcon';
import ClientIcon from '../../assets/svg/clientIcon';
// FIN SVG // 


//IMPORT DATAS // 
import salonsApi from '../api/salons';
import customerApi from '../api/customer';
import animalApi from '../api/animal';
import prestaApi from '../api/presta';
import authStorage from '../auth/storage';
import AuthContext from '../auth/context';
import customer from '../api/customer';

//  FIN DATAS // 

const screenHeight = window.innerHeight;


function LayoutScreen({ currentScreen, setCurrentScreen, id, name, lastMeetingView, setLastMeetingView }) {




    const [customerInfos, setCustomerInfos] = useState([]);
    const [customerLastMeetings, setCustomerLastMeetings] = useState([]);
    const [customerNextMeetings, setCustomerNextMeetings] = useState([]);
    const [customerAnimalsList, setCustomerAnimalsList] = useState([]);


    const getCustomerInfos = async () => {

        const response = await customerApi.getCustomerInfos(id);
        console.log("REPONSE");
        if (!response.ok) {
            console.log("test");
            console.log(response.data);
        }

        setCustomerInfos(response.data);
        setNewCustomer({ origin: response.data.origin.toUpperCase(), name: response.data.name, lastName: response.data.lastName, tel1: response.data.tel1, tel2: response.data.tel2, sms: response.data.sms, infos: response.data.infos });

        const nextResponse = await customerApi.getCustomerNextMeetings(id);
        setCustomerNextMeetings(nextResponse.data);

        const lastResponse = await customerApi.getCustomerLastMeetings(id);
        setCustomerLastMeetings(lastResponse.data);

        const animalResponse = await animalApi.getAnimalByCustomer(id);
        setCustomerAnimalsList(animalResponse.data);

    };

    const snackbar = useSnackbar();

    var getDates = function (thisDate) {
        console.log(thisDate);
        var year = thisDate.getFullYear();
        var month = thisDate.getMonth();
        var date = new Date(year, month, 1);
        console.log(year, month);
        var names = ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'];
        var result = { "": [], "lun": [], "mar": [], "mer": [], "jeu": [], "ven": [], "sam": [], "dim": [] };
        var firstDay = names[date.getDay()];
        for (var i = 0; i < names.length; i++) {
            if (firstDay == names[i] && firstDay != "dim") break;
            if (names[i] != "dim") result[names[i]].push({ "completeDate": null });
        }

        while (date.getMonth() == month) {

            var element = { "completeDate": date.toLocaleDateString('fr-FR'), 'date': date.getDate(), "day": names[date.getDay()] };
            result[element.day].push(element);
            date.setDate(date.getDate() + 1);


        }
        return result;
    }


    const getCurrentDateString = function (type) {
        var days = ["Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche", "Lundi"];
        var months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
        var day = days[currentDate.getDay()];
        var month = months[currentDate.getMonth()];
        if (dayMonth == "day" && type == "long") {
            return day + " " + currentDate.getDate() + " " + month + " " + currentDate.getFullYear();
        }
        else {
            return month + " " + currentDate.getFullYear();
        }
    }

    const updateDates = function (type, value) {

        console.log("-------------");
        console.log(currentDate);
        console.log(dayMonth);

        if (type === "change") {
            setCurrentDate(value);
        }
        if (type == "add") {
            setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + value)));
        }
        if (type == "remove") {
            setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - value)))
        }

    }

    const [value, setValue] = useState(null);
    const [dayMonth, setDayMonth] = useState("day");
    const [currentDate, setCurrentDate] = useState(new Date());
    const [currentDateString, setCurrentDateString] = useState({ "short": getCurrentDateString("short"), "long": getCurrentDateString("long") });
    const [datePickerOpened, setDatePickerOpened] = useState(false);

    const emptyCustomerForm = { origin: null, name: null, lastName: null, tel1: null, tel2: null, sms: 1, infos: null, noShow: 0, animal: { name: null, type: "dog", race: null, remise: "0", presta: { name: null, durationDate: null, comments: null, price: null } } };
    const [disableForm, setDisableForm] = useState(true);
    const [animalView, setAnimalView] = useState(false);
    const [newCustomer, setNewCustomer] = useState(emptyCustomerForm);

    const validateForm = async () => {
        if (newCustomer.tel1 == null || newCustomer.name == null) snackbar.showMessage("Les champs nom et téléphone portable doivent être renseignés.");
        else {
            newCustomer.customerId = customerInfos.id;
            const response = await customerApi.updateCustomer(newCustomer);
            if (!response.ok) snackbar.showMessage(response.error);
            else {
                snackbar.showMessage("Fiche client mise à jour.");
            }
            setDisableForm(true);

        }
    };

    var workingTime = ["8:00", "20:00"];


    var times = function (hours) {
        var timeList = [];
        var startingHour = hours[0];
        var lastHour = hours[1];
        var date = moment("01/01/2001 " + startingHour);

        console.log(date.format("HH:mm"));

        while (date.format("HH:mm") != lastHour) {
            timeList.push(date.format("HH:mm"));
            date.add(60, "m");
        }

        timeList.push(date.format("HH:mm"))

        return timeList;
    };

    const timesList = times(workingTime);

    var dates = getDates(currentDate);

    useEffect(() => {
        getCustomerInfos();
    }, [currentDate, dayMonth, disableForm]);


    const [loaded] = useFonts({
        poppins: require('../fonts/Poppins-Regular.ttf'),
        poppinsMedium: require('../fonts/Poppins-Medium.ttf'),
        poppinsSemiBold: require('../fonts/Poppins-SemiBold.ttf'),
        poppinsBold: require('../fonts/Poppins-Bold.ttf')
    });

    if (!loaded) {
        return null;
    }



    return (
        <ScrollView style={styles.body}>
            {
                animalView ?
                    (
                        <View style={styles.screenContainerExtraMarges}>
                            <View style={[styles.anchorLeftControllers, { zIndex: 300 }]}>
                                {
                                    currentScreen.lastScreen === undefined ? (
                                        <Button variant="contained" onClick={() => { setCurrentScreen({ type: lastMeetingView.type, data: { id: lastMeetingView.id, name: lastMeetingView.name, date: lastMeetingView.date } }) }} style={{ boxShadow: "none", fontFamily: "poppins", fontSize: 12, backgroundColor: "transparent", color: colors.ebony, borderRadius: 7, marginTop: 5, marginBottom: 5, textTransform: 'none', marginRight: 20 }} startIcon={<ReturnIcon height={25} width={30} style={{ fill: colors.ebony, marginRight: 10 }} resizeMode="contain" />}>Retour</Button>

                                    ) : (currentScreen.lastScreen.type == "animal" || currentScreen.lastScreen.type == "employee" || currentScreen.lastScreen.type == "salon") ?
                                        (
                                            <Button variant="contained" onClick={() => { setCurrentScreen({ type: lastMeetingView.type, data: { id: lastMeetingView.id, name: lastMeetingView.name, date: lastMeetingView.date } }) }} style={{ boxShadow: "none", fontFamily: "poppins", fontSize: 12, backgroundColor: "transparent", color: colors.ebony, borderRadius: 7, marginTop: 5, marginBottom: 5, textTransform: 'none', marginRight: 20 }} startIcon={<ReturnIcon height={25} width={30} style={{ fill: colors.ebony, marginRight: 10 }} resizeMode="contain" />}>Retour</Button>

                                        ) : (
                                            <Button variant="contained" onClick={() => { currentScreen.lastScreen !== undefined && currentScreen.lastScreen !== null ? setCurrentScreen({ type: currentScreen.lastScreen.type, data: currentScreen.lastScreen.data }) : lastMeetingView != null ? (setCurrentScreen({ type: lastMeetingView.type, data: { id: lastMeetingView.id, name: lastMeetingView.name, date: lastMeetingView.date } })) : null }} style={{ boxShadow: "none", fontFamily: "poppins", fontSize: 12, backgroundColor: "transparent", color: colors.ebony, borderRadius: 7, marginTop: 5, marginBottom: 5, textTransform: 'none', marginRight: 20 }} startIcon={<ReturnIcon height={25} width={30} style={{ fill: colors.ebony, marginRight: 10 }} resizeMode="contain" />}>Retour</Button>
                                        )
                                }

                            </View>
                            <View style={styles.centeredControllers}>

                                <TouchableOpacity style={{ marginRight: 50, backgroundColor: colors.smokedWhite, padding: 10, borderRadius: 50, width: 60, height: 60 }} onPress={() => (setAnimalView(false))}>
                                    <ClientIcon height={40} width={40} style={{ fill: colors.ebony }} resizeMode="contain" />
                                </TouchableOpacity>

                                <TouchableOpacity style={{ backgroundColor: colors.blue, padding: 10, borderRadius: 50, width: 60, height: 60 }} onPress={() => (setAnimalView(true))}>
                                    <DogIcon height={40} width={40} style={{ fill: "#ffffff" }} resizeMode="contain" />
                                </TouchableOpacity>

                            </View>
                            <Text style={[styles.pageTitle, { color: colors.ebony, textAlign: "center", width: "100%", marginBottom: 20 }]}>LISTE ANIMAUX CLIENT {name.name} {name.lastName}</Text>

                            {customerAnimalsList.map((item, key) => (
                                <View style={styles.searchResultRow}>
                                    <View style={[styles.searchResultText, { width: "10%" }]}><b>Animal : {item.name}</b></View>
                                    <View style={[styles.searchResultText, { width: "10%" }]}><b>Type : {item.type == "dog" ? "Chien" : "Chat"}</b></View>
                                    <View style={[styles.searchResultText, { width: "10%" }]}><b>Race : {item.race}</b></View>
                                    <View style={[styles.searchResultText, { width: "10%" }]}><b>Statut : {item.isActive == 0 ? "Inactif (décédé)" : "Actif"}</b></View>
                                    {
                                        item.isActive == 1 ? (<Button variant="contained" onClick={() => { setCurrentScreen({ type: "newMeeting", animal: { "id": item.id, "name": item.name, "infos": item.infos, "type": item.type, "race": item.race, "Customer": { "id": customerInfos.id, "name": customerInfos.name, "lastName": customerInfos.lastName, "infos": customerInfos.infos } }, lastScreen: { type: currentScreen.type, data: currentScreen.data } }); }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: colors.blue, color: colors.ebony, borderRadius: 7, textTransform: 'none', alignSelf: "center", height: 30 }}>Nouveau Rendez-vous</Button>) : null
                                    }

                                    <Button variant="contained" onClick={() => setCurrentScreen({ type: "animal", data: { "id": item.id, "name": item.name, "customerId": customerInfos.id, "customerName": customerInfos.name + " " + customerInfos.lastName, "current": "newPresta" }, lastScreen: { type: currentScreen.type, data: currentScreen.data } })} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: colors.blue, color: colors.ebony, borderRadius: 7, textTransform: 'none', alignSelf: "center", height: 30 }}>Ajouter prestation</Button>
                                    <Button variant="contained" onClick={() => setCurrentScreen({ type: "animal", data: { "id": item.id, "name": item.name, "customerId": customerInfos.id, "customerName": customerInfos.name + " " + customerInfos.lastName }, lastScreen: { type: currentScreen.type, data: currentScreen.data } })} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: colors.blue, color: colors.ebony, borderRadius: 7, textTransform: 'none', width: 100, alignSelf: "center", height: 30 }}>Consulter</Button>
                                </View>
                            ))}

                            <Button variant="contained" onClick={() => { setCurrentScreen({ type: "newAnimal", customer: { id: customerInfos.id, name: customerInfos.name, lastName: customerInfos.lastName }, lastScreen: { type: currentScreen.type, data: currentScreen.data } }); }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, fontSize: 12, backgroundColor: colors.blue, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 300, alignSelf: "center", height: 50 }}>AJOUTER ANIMAL</Button>

                        </View>
                    )
                    : (
                        <View style={styles.screenContainerExtraMarges}>
                            <View style={[styles.anchorLeftControllers, { zIndex: 300 }]}>
                                {
                                    currentScreen.lastScreen === undefined ? (
                                        <Button variant="contained" onClick={() => { setCurrentScreen({ type: lastMeetingView.type, data: { id: lastMeetingView.id, name: lastMeetingView.name, date: lastMeetingView.date, view: "day" } }) }} style={{ boxShadow: "none", fontFamily: "poppins", fontSize: 12, backgroundColor: "transparent", color: colors.ebony, borderRadius: 7, marginTop: 5, marginBottom: 5, textTransform: 'none', marginRight: 20 }} startIcon={<ReturnIcon height={25} width={30} style={{ fill: colors.ebony, marginRight: 10 }} resizeMode="contain" />}>Retour</Button>

                                    ) : (currentScreen.lastScreen.type == "animal" || currentScreen.lastScreen.type == "employee" || currentScreen.lastScreen.type == "salon") ?
                                        (
                                            <Button variant="contained" onClick={() => { setCurrentScreen({ type: lastMeetingView.type, data: { id: lastMeetingView.id, name: lastMeetingView.name, date: lastMeetingView.date, view: "day" } }) }} style={{ boxShadow: "none", fontFamily: "poppins", fontSize: 12, backgroundColor: "transparent", color: colors.ebony, borderRadius: 7, marginTop: 5, marginBottom: 5, textTransform: 'none', marginRight: 20 }} startIcon={<ReturnIcon height={25} width={30} style={{ fill: colors.ebony, marginRight: 10 }} resizeMode="contain" />}>Retour</Button>

                                        ) : (
                                            <Button variant="contained" onClick={() => { currentScreen.lastScreen !== undefined && currentScreen.lastScreen !== null ? setCurrentScreen({ type: currentScreen.lastScreen.type, data: currentScreen.lastScreen.data }) : lastMeetingView != null ? (setCurrentScreen({ type: lastMeetingView.type, data: { id: lastMeetingView.id, name: lastMeetingView.name, date: lastMeetingView.date } })) : null }} style={{ boxShadow: "none", fontFamily: "poppins", fontSize: 12, backgroundColor: "transparent", color: colors.ebony, borderRadius: 7, marginTop: 5, marginBottom: 5, textTransform: 'none', marginRight: 20 }} startIcon={<ReturnIcon height={25} width={30} style={{ fill: colors.ebony, marginRight: 10 }} resizeMode="contain" />}>Retour</Button>
                                        )
                                }
                            </View>
                            <View style={styles.centeredControllers}>
                                <TouchableOpacity style={{ backgroundColor: colors.blue, padding: 10, borderRadius: 50, marginRight: 50, width: 60, height: 60 }} onPress={() => (setAnimalView(false))}>
                                    <ClientIcon height={40} width={40} style={{ fill: "#ffffff" }} resizeMode="contain" />
                                </TouchableOpacity>

                                <TouchableOpacity style={{ backgroundColor: colors.smokedWhite, padding: 10, borderRadius: 50, width: 60, height: 60 }} onPress={() => (setAnimalView(true))}>
                                    <DogIcon height={40} width={40} style={{ fill: colors.ebony }} resizeMode="contain" />
                                </TouchableOpacity>

                            </View>
                            <Text style={[styles.pageTitle, { color: colors.ebony, textAlign: "center", width: "100%", marginBottom: 20 }]}>FICHE CLIENT {name.name} {name.lastName}</Text>
                            {disableForm ?
                                <View style={styles.formContainer}>
                                    <View style={styles.formColumn}>
                                        <View style={styles.inputRow}>
                                            <View style={styles.formLabelColumn}>
                                                <Text style={styles.inputLabel}>Origine</Text>
                                            </View>
                                            <View style={styles.formInputsColumn}>
                                                <TextField
                                                    value={customerInfos.origin} disabled
                                                    fullWidth label="" autoCorrect='false' placeholder='Origine' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                            </View>
                                        </View>
                                        <View style={styles.inputRow}>
                                            <View style={styles.formLabelColumn}><Text style={styles.inputLabel}>Date création</Text>
                                            </View>
                                            <View style={styles.formInputsColumn}>
                                                <TextField
                                                    value={new Date(customerInfos.createdAt).toLocaleDateString('fr-FR')} disabled
                                                    fullWidth label="" autoCorrect='false' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                            </View>
                                        </View>
                                        <View style={styles.inputRow}>
                                            <View style={styles.formLabelColumn}>
                                                <Text style={styles.inputLabel}>Nom client</Text>
                                            </View>
                                            <View style={styles.formInputsColumn}>
                                                <TextField
                                                    value={customerInfos.lastName} disabled={disableForm}
                                                    fullWidth label="" autoCorrect='false' placeholder='Nom du client' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                            </View>
                                        </View>
                                        <View style={styles.inputRow}>
                                            <View style={styles.formLabelColumn}>
                                                <Text style={styles.inputLabel}>Prénom client</Text>
                                            </View>
                                            <View style={styles.formInputsColumn}>
                                                <TextField
                                                    value={customerInfos.name} disabled={disableForm}
                                                    fullWidth label="" autoCorrect='false' placeholder='Nom du client' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                            </View>
                                        </View>
                                        <View style={styles.inputRow}>
                                            <View style={styles.formLabelColumn}><Text style={styles.inputLabel}>Tel port.</Text>
                                            </View>
                                            <View style={styles.formInputsColumn}>
                                                <TextField
                                                    value={customerInfos.tel1} disabled={disableForm}
                                                    fullWidth label="" autoCorrect='false' placeholder='Renseigner le n° de portable' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                            </View>
                                        </View>
                                        <View style={styles.inputRow}>
                                            <View style={styles.formLabelColumn}><Text style={styles.inputLabel}>Tel</Text>
                                            </View>
                                            <View style={styles.formInputsColumn}>
                                                <TextField
                                                    value={customerInfos.tel2} disabled={disableForm}
                                                    fullWidth label="" autoCorrect='false' placeholder='Rensigner le n° de fixe' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                            </View>
                                        </View>
                                        <View style={styles.inputRow}>
                                            <View style={styles.formLabelColumn}>
                                                <Text style={styles.inputLabel}>Envoi SMS</Text>
                                            </View>
                                            <View style={styles.formInputsColumn}>
                                                <View style={styles.formInputsGroup}>
                                                    <Button variant="contained"
                                                        style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", backgroundColor: customerInfos.sms == 1 ? colors.cashmere : colors.smokedWhite, color: colors.ebony, borderRadius: 25, textTransform: 'none' }}>OUI</Button>
                                                    <Button
                                                        variant="contained"
                                                        style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", backgroundColor: customerInfos.sms == 0 ? colors.cashmere : colors.smokedWhite, color: colors.ebony, borderRadius: 25, textTransform: 'none' }}>NON</Button>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={[styles.formColumn, { marginLeft: 50 }]}>
                                        <View style={styles.inputRow}>
                                            <View style={styles.formLabelColumn}>
                                                <Text style={styles.inputLabel}>Infos client</Text>
                                            </View>
                                            <View style={styles.formInputsColumn}>
                                                <TextField
                                                    value={customerInfos.infos} disabled={disableForm}
                                                    fullWidth label="" multiline rows={4} autoCorrect='false' placeholder='Informations complémentaires' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                            </View>
                                        </View>
                                        <View style={styles.inputRow}>
                                            <View style={styles.formLabelColumn}><Text style={styles.inputLabel}>No Show</Text>
                                            </View>
                                            <View style={styles.formInputsColumn}>
                                                <TextField
                                                    value={customerInfos.noshowNb} disabled
                                                    fullWidth label="" autoCorrect='false' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                            </View>
                                        </View>

                                    </View>
                                </View>
                                :
                                <View style={styles.formContainer}>
                                    <View style={styles.formColumn}>
                                        <View style={styles.inputRow}>
                                            <View style={styles.formLabelColumn}>
                                                <Text style={styles.inputLabel}>Prénom client</Text>
                                            </View>
                                            <View style={styles.formInputsColumn}>
                                                <TextField
                                                    value={newCustomer.name ? newCustomer.name : ""}
                                                    onChange={(value) => (setNewCustomer((prevState) => ({
                                                        ...prevState,
                                                        name: value.target.value
                                                    })))}
                                                    fullWidth label="" autoCorrect='false' placeholder='Nom du client' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                            </View>
                                        </View>
                                        <View style={styles.inputRow}>
                                            <View style={styles.formLabelColumn}>
                                                <Text style={styles.inputLabel}>Nom client</Text>
                                            </View>
                                            <View style={styles.formInputsColumn}>
                                                <TextField
                                                    value={newCustomer.lastName ? newCustomer.lastName : ""}
                                                    onChange={(value) => (setNewCustomer((prevState) => ({
                                                        ...prevState,
                                                        lastName: value.target.value
                                                    })))}
                                                    fullWidth label="" autoCorrect='false' placeholder='Nom du client' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                            </View>
                                        </View>
                                        <View style={styles.inputRow}>
                                            <View style={styles.formLabelColumn}>
                                                <Text style={styles.inputLabel}>Origine</Text>
                                            </View>
                                            <View style={styles.formInputsColumn}>
                                                <Select
                                                    fullWidth
                                                    sx={{
                                                        "& fieldset": { border: 'none' },
                                                    }}
                                                    value={newCustomer.origin ? newCustomer.origin : ""}
                                                    onChange={(value) => (setNewCustomer((prevState) => ({
                                                        ...prevState,
                                                        origin: value.target.value
                                                    })))}
                                                    disableUnderline={true}
                                                    displayEmpty
                                                    IconComponent={(props) => (
                                                        <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                                    )}

                                                >

                                                    <MenuItem value="BRIGNAIS">BRIGNAIS</MenuItem>
                                                    <MenuItem value="CHAPONOST">CHAPONOST</MenuItem>
                                                    <MenuItem value="CRAPONNE">CRAPONNE</MenuItem>
                                                    <MenuItem value="FRANCHEVILLE">FRANCHEVILLE</MenuItem>
                                                    <MenuItem value="VOURLES">VOURLES</MenuItem>
                                                </Select>
                                            </View>
                                        </View>
                                        <View style={styles.inputRow}>
                                            <View style={styles.formLabelColumn}><Text style={styles.inputLabel}>Tel port.</Text>
                                            </View>
                                            <View style={styles.formInputsColumn}>
                                                <TextField
                                                    value={newCustomer.tel1 ? newCustomer.tel1 : ""}
                                                    onChange={(value) => (setNewCustomer((prevState) => ({
                                                        ...prevState,
                                                        tel1: value.target.value.replace(/\D/g, ''),
                                                    })))}
                                                    fullWidth label="" autoCorrect='false' placeholder='Renseigner le n° de portable' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                            </View>
                                        </View>
                                        <View style={styles.inputRow}>
                                            <View style={styles.formLabelColumn}><Text style={styles.inputLabel}>Tel</Text>
                                            </View>
                                            <View style={styles.formInputsColumn}>
                                                <TextField
                                                    value={newCustomer.tel2 ? newCustomer.tel2 : ""}
                                                    onChange={(value) => (setNewCustomer((prevState) => ({
                                                        ...prevState,
                                                        tel2: value.target.value.replace(/\D/g, ''),
                                                    })))}
                                                    fullWidth label="" autoCorrect='false' placeholder='Rensigner le n° de fixe' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                            </View>
                                        </View>
                                        <View style={styles.inputRow}>
                                            <View style={styles.formLabelColumn}>
                                                <Text style={styles.inputLabel}>Envoi SMS</Text>
                                            </View>
                                            <View style={styles.formInputsColumn}>
                                                <View style={styles.formInputsGroup}>
                                                    <Button variant="contained"
                                                        onClick={() => (setNewCustomer((prevState) => ({
                                                            ...prevState,
                                                            sms: 1
                                                        })))}
                                                        style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", backgroundColor: newCustomer.sms == 1 ? colors.cashmere : colors.smokedWhite, color: colors.ebony, borderRadius: 25, textTransform: 'none' }}>OUI</Button>
                                                    <Button
                                                        onClick={() => (setNewCustomer((prevState) => ({
                                                            ...prevState,
                                                            sms: 0
                                                        })))}
                                                        variant="contained"
                                                        style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", backgroundColor: newCustomer.sms == 0 ? colors.cashmere : colors.smokedWhite, color: colors.ebony, borderRadius: 25, textTransform: 'none' }}>NON</Button>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={[styles.formColumn, { marginLeft: 50 }]}>
                                        <View style={styles.inputRow}>
                                            <View style={styles.formLabelColumn}>
                                                <Text style={styles.inputLabel}>Infos client</Text>
                                            </View>
                                            <View style={styles.formInputsColumn}>
                                                <TextField
                                                    value={newCustomer.infos ? newCustomer.infos : ""}
                                                    onChange={(value) => (setNewCustomer((prevState) => ({
                                                        ...prevState,
                                                        infos: value.target.value
                                                    })))}
                                                    fullWidth label="" multiline rows={4} autoCorrect='false' placeholder='Informations complémentaires' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            }


                            <View style={[styles.separator, { marginTop: 170 }]}></View>

                            <View style={styles.formContainer}>
                                <View style={styles.formColumn}>
                                    {customerLastMeetings.map((item, key) => (

                                        <View style={styles.inputRow}>
                                            <View style={styles.formLabelColumn}>
                                                {key == 0 ? <Text style={styles.inputLabel}>Derniers Rendez-vous</Text> : null}
                                            </View>
                                            <View style={styles.formInputsColumn}>
                                                <TextField
                                                    value={new Date(item.datePlanned).toLocaleDateString('fr-FR') + " - " + item.Prestation.name + (item.endPrice > 0 ? " - " + item.endPrice + " €" : "")}
                                                    disabled
                                                    fullWidth label="" autoCorrect='false' placeholder="Nom de l'animal" variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                            </View>
                                        </View>
                                    ))}


                                </View>
                                <View style={[styles.formColumn, { marginLeft: 50 }]}>
                                    {customerNextMeetings.map((item, key) => (
                                        <View style={styles.inputRow}>
                                            <View style={styles.formLabelColumn}>
                                                {key == 0 ? <Text style={styles.inputLabel}>Prochains Rendez-vous</Text> : null}
                                            </View>
                                            <View style={styles.formInputsColumn}>
                                                <TextField
                                                    value={new Date(item.datePlanned).toLocaleDateString('fr-FR') + " - " + item.Prestation.name}
                                                    disabled
                                                    fullWidth label="" autoCorrect='false' placeholder="Nom de l'animal" variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                            </View>
                                        </View>
                                    ))}


                                </View>
                            </View>

                            <View style={styles.centeredControllers}>
                                { /*<Button variant="contained" onClick={() => { setCurrentScreen({ type: "newMeeting", data: { "id": null, "name": null } }); }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, fontSize: 12, backgroundColor: colors.cashmere, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 300, alignSelf: "center", height: 50 }}>CREER UN RENDEZ VOUS</Button> */}
                                {
                                    disableForm ?
                                        <Button variant="contained" onClick={() => { setDisableForm(false) }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, fontSize: 12, backgroundColor: colors.blue, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 300, alignSelf: "center", height: 50 }}>MODIFIER LES INFOS</Button>
                                        :
                                        <Fragment>
                                            <Button variant="contained" onClick={() => { validateForm() }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, fontSize: 12, backgroundColor: colors.blue, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 300, alignSelf: "center", height: 50 }}>VALIDER LES MODIFICATIONS</Button>
                                            <Button variant="contained" onClick={() => { setNewCustomer(emptyCustomerForm); setDisableForm(true); snackbar.showMessage('Formulaire réinitialisé.') }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, fontSize: 12, backgroundColor: colors.red, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 300, alignSelf: "center", height: 50 }}>ANNULER LES MODIFICATIONS</Button>
                                        </Fragment>
                                }
                            </View>
                        </View>
                    )
            }

        </ScrollView>
    );
}

export default LayoutScreen;


const styles = StyleSheet.create({

    anchorLeftControllers: {
        flexDirection: "row",
        position: "absolute",
        left: 15,
    },

    searchResultRow: {
        backgroundColor: colors.smokedWhite,
        height: 50,
        borderRadius: 25,
        flexDirection: "row",
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        marginBottom: 20,
        marginLeft: 50,
        marginRight: 50,
    },

    searchResultText:
    {
        fontFamily: "poppins",
        fontStyle: "normal",
        color: colors.ebony
    },

    mainView: {
        backgroundColor: colors.smokedWhite,
        flex: 1,
    },

    //FORM

    formContainer: {
        flex: 1,
        flexDirection: "row",
        marginRight: 50,
        marginLeft: 50,
        marginBottom: 50

    },

    formColumn: {
        flex: 1,
        flexDirection: "column",
        width: "auto",

    },

    formLabelColumn: {
        flex: 1,
        flexDirection: "row",
        paddingTop: 20,

    },

    formInputsColumn: {
        width: "70%",
        flexDirection: "row",
        paddingLeft: 20,
        paddingRight: 20
    },

    formInputsGroup: {
        flexDirection: "row",
        flex: 1,
        justifyContent: "space-evenly"
    },

    inputRow: {
        flexDirection: "row",
        marginBottom: 20,
    },

    inputLabel: {
        fontFamily: "poppinsSemiBold",
        color: colors.ebony,
        lineHeight: "100%",
        height: "100%",
    },

    separator: {
        height: 2,
        backgroundColor: colors.ebony,
        width: "100%",
        marginBottom: 50
    },

    // END FORM



    monthPicker: {
        borderColor: "#E2E2EA"
    },

    customSearchInputContainer: {
        borderColor: colors.ebony,
        borderWidth: 1,
        width: 400,
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        flexDirection: "row",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 5,
        paddingBottom: 5,
        borderRadius: 25,

    },

    customSearchInput: {
        borderWidth: 0,
        borderColor: "transparent",
        flex: 1,
        height: "100%",
        width: "auto",
        fontFamily: "poppinsSemiBold",
        fontWeight: "bold",
        fontSize: 14,
        color: colors.ebony,
        outlineColor: 'transparent'

    },

    eventText: {
        flex: 1,
        flexDirection: "row",
        wdith: "100%",
        marginBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: "rgba(0, 0, 0, 0.1)",
        paddingTop: 5,
        paddingBottom: 5
    },

    eventTextTitle: {
        fontFamily: "poppinsSemiBold",
        marginRight: 10
    },

    eventGrid: {
        flex: 1,
        width: "100%",
        flexDirection: "row",
        top: 0,
        paddingLeft: 70,
        position: "absolute"

    },

    eventRow: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },

    eventColumn: {
        flex: 1,
        flexDirection: "column",
        paddingLeft: 5,
        paddingRight: 5
    },

    eventHeader: {
        height: 40,
        borderRadius: 25,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.yellow,
        marginBottom: 20
    },

    eventHeaderTitle: {
        fontFamily: "poppinsSemiBold",
        fontSize: 16
    },

    eventContent: {
        flex: 1,
        flexDirection: "column",
    },

    eventBox: {
        backgroundColor: colors.egg,
        borderWidth: 4,
        borderColor: colors.yellow,
        borderRadius: 10,
        height: 100,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20
    },

    eventButtons: {
        flex: 1,
        flexDirection: "row",
    },

    hourText: {
        textAlign: "center",
        width: 60,
        fontFamily: "poppinsSemiBold"
    },

    hoursGrid: {
        marginLeft: 10,
        marginRight: 10,
        paddingTop: 60,
        position: "relative",
    },

    hourRow: {
        height: 100,
        borderBottomWidth: 1,
        borderColor: "#e0e0e0",
        justifyContent: "center",

    },

    screenControllers: {
        marginBottom: 40,
        alignContent: "center",
        justifyContent: "center",
        textAlign: "center"
    },

    centeredControllers:
    {
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center",
        marginBottom: 20
    },


    controllerTitle: {
        fontSize: 12,
        fontFamily: "poppinsSemiBold",
        textAlignVertical: "center",
        marginTop: 8,
        marginRight: 10
    },

    buttonGroup:
    {
        flexDirection: "row",
        borderRadius: 7,
        padding: 2,
        backgroundColor: colors.milk
    },

    anchorRightControllers: {
        flexDirection: "row",
        position: "absolute",
        right: 15,
    },

    monthCalendar: {
        width: "100%",
        flex: 1,
        zIndex: 50,
        flexDirection: "row"
    },

    periodLabel: {
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center"
    },

    employeeDay: {
        flexDirection: "column",
        flex: 1,
        marginBottom: 2,
    },

    gridEmployeeName: {
        flex: 1
    },

    gridEmployeeLabel:
    {
        fontFamily: "poppinsSemiBold",
        color: colors.ebony
    },

    gridBox: {
        width: "100%",
        flex: 1,
        alignItems: "center"
    },

    gridBorder: {
        borderWidth: 0.5,
        borderColor: colors.charcoal
    },

    dayColumn:
    {
        flex: 1,
    },

    dayTitle:
    {
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: 50,
        backgroundColor: colors.smokedWhite,
        marginBottom: 20
    },

    dateBox:
    {
        height: 100,
        borderWidth: 1,
        borderColor: colors.charcoal,
        paddingLeft: 10,
        paddingRight: 10,
    },

    dayNumber: {
        paddingLeft: 10,
        fontFamily: "poppins",
        color: colors.mist,
        fontSize: 12,
        paddingTop: 10,
        paddingBttom: 10
    },

    navbar: {
        height: 75,
        width: "100%",
        backgroundColor: "#FFFFFF",
        borderBottomColor: "#E2E2EA",
        borderBottomWidth: 2,
        flexDirection: "row",
        position: "fixed",
        zIndex: 10
    },

    switchScreen: {
        backgroundColor: colors.ebony,
        width: "100%",
        height: 50,
        alignItems: "center",
        justifyContent: "center"
    },

    screenContainer: {
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        width: "100%",
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 20,
        paddingBottom: 20,
        flex: 1
    },

    screenContainerExtraMarges: {
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        width: "100%",
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 50,
        paddingBottom: 50,
        flex: 1
    },

    categoryButton: {
        backgroundColor: colors.linen,
        width: "100%",
        height: 50,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 0
    },

    logoContainer: {
        width: 225,
        height: "100%",
        padding: 5,
        alignItems: "center",
        borderRightColor: "#E2E2EA",
        borderRightWidth: 1
    },

    shortcutContainer: {
        flex: 1,
        flexDirection: "row",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10
    },

    searchContainer: {
        flex: 1
    },

    screenTitle: {
        borderRadius: 5,
        width: "100%",
        height: 50,
        backgroundColor: colors.linen,
        marginBottom: 10,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },

    titleSeparator: {
        marginLeft: 20,
        marginRight: 20,
        fontSize: 20,
        color: "#D8D8D8"
    },

    pageAction: {
        fontSize: 18,
        fontWeight: "600",
        color: colors.mist
    },

    pageTitle: {
        fontFamily: "poppinsSemiBold",
        textTransform: "uppercase",
        fontWeight: "bold",
        fontSize: 20,
        color: colors.ebony
    },

    searchInput: {
        width: 300
    },

    mainContainer: {
        flex: 1,
        flexDirection: "row"
    },

    aside: {
        paddingTop: 20,
        marginTop: 75,
        width: 225,
        height: screenHeight - 75,
        backgroundColor: colors.snow,
        borderRightColor: "#E2E2EA",
        borderRightWidth: 1,
        position: "fixed",
        zIndex: 10
    },

    asideContainer: {
        flex: 1,
    },

});
