import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SvgComponent = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 146.4 136.3"
    style={{
      enableBackground: "new 0 0 146.4 136.3",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <Path d="M146.4 134.8c0-26-21.2-47.2-47.2-47.2h-52c-26 0-47.2 21.2-47.2 47.2v1.5h6v-1.5c0-22.7 18.5-41.2 41.2-41.2h51.9c22.7 0 41.2 18.5 41.2 41.2v1.5h6l.1-1.5zM73.2 79.5c21.9 0 39.7-17.8 39.7-39.7 0-22-17.8-39.8-39.7-39.8-10.6 0-20.6 4.1-28.1 11.6S33.5 29.1 33.5 39.7c0 21.9 17.8 39.7 39.7 39.8zm33.7-39.8c0 18.6-15.1 33.7-33.7 33.7S39.5 58.3 39.5 39.7C39.5 21.2 54.6 6 73.2 6c18.5.1 33.7 15.2 33.7 33.7z" />
  </Svg>
)

export default SvgComponent
