import * as React from "react"
import Svg, { Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */

const SvgComponent = (props) => (
  <Svg
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 161 136.2"
    style={{
      enableBackground: "new 0 0 161 136.2",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <Path
      className="st0"
      d="M28.6 98.2c-1.9-5.1-3.6-9.9-3.6-22.5 0-5.9 1.7-11.1 3.3-15.8.4-1.3.9-2.6 1.2-3.9 2.6.7 7.5 1.6 15.8 1.6h1.1c4.3 0 8.5-.7 12.6-2.1l-1-2.8c-4.1 1.4-8.3 2-12.6 1.9-8.1 0-12.8-.9-15.1-1.5.3-1.3.5-2.6.5-3.9v-1c0-4 0-8.5-3.1-11.7-2.6-2.6-6.8-3.8-12.8-3.8-3.7 0-6.5-1.1-8.5-3.3-3.1-3.5-3.3-9-3.2-11.3l16.2-2c.6-.1 1.2-.6 1.3-1.2 0-.5 2.2-11.9 13.7-11.9h8.7C47.5 3 57.8 9.3 63 21.7l2.8-1.1C60.2 7.3 48.9 0 43.1 0h-8.7C22.7 0 18.9 9.9 17.9 13.2L1.5 15.3c-.7.1-1.2.6-1.3 1.3C.2 17-.9 25.8 4 31.4c2.5 2.9 6.2 4.3 10.7 4.3 5.2 0 8.7 1 10.7 2.9 2.3 2.3 2.3 6 2.3 9.5v1c0 2.8-1.1 6-2.3 9.7-1.6 5-3.4 10.6-3.4 16.9 0 13.2 1.8 18.2 3.8 23.6 1 2.7 2 5.5 2.8 9.5 1.7 8.7.6 20.5.1 24.4H16.9v3H30c.7 0 1.4-.5 1.5-1.3.1-.6 2.2-15.7 0-26.7-.8-4.3-1.9-7.2-2.9-10z"
    />
    <Path
      className="st0"
      d="M39.4 16.8c0 .9.1 20.9 9.5 26.3l1.5-2.6c-7.9-4.6-8-23.5-8-23.7h-3zM28.5 12.4v4.4c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5v-4.4c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5zM54.5 101.4c2.7-5.3 7.4-7.4 10.9-8.3l-.7-2.9c-4.1 1-9.6 3.6-12.8 9.9-3.9 7.7-3.1 18.8 2.3 33.1H40.9v3h17.5v-3h-1.2c-5.4-14-6.3-24.7-2.7-31.8zM73.7 28.4c-.8 0-1.5.7-1.5 1.5v4.4c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5v-4.4c0-.9-.7-1.5-1.5-1.5z"
    />
    <Path
      className="st0"
      d="M150.1 82.7c3.4-5.4 10.8-5.5 10.9-5.5v-3c-.4 0-9.1.1-13.4 6.9-3.7 5.8-2.8 14.5 2.6 25.9 4.2 8.8 4.7 15.5 1.8 19.9-3.7 5.5-12.2 6.2-15.6 6.2v-24.7c0-24.6-10.8-29.7-19.4-33.8-6.2-2.9-11.2-5.3-11.2-14.2 0-21.5-2.5-30.1-6.7-37.8-6.7-11.9-16-13.8-16.4-13.8-.4-.1-.9 0-1.2.3-.3.3-.5.7-.5 1.2v9.4h-5c-5.4 0-11.7 2.9-12.3 10.9h-5.2c-.8 0-3.6.7-3.6 8 0 7.8 3.8 12.4 10.2 12.4 4.2 0 7.2 1 8.4 2.7 1 1.4.9 3.6-.2 6.4l-.3.7c-2.6 6.4-5 12.4-5 28.1 0 8.2 1 12.4 2.5 18.7.5 2.2 1.1 4.7 1.8 7.8 1.3 5.9 1.4 11.9.4 17.8H60.6v3h13.1c.7 0 1.3-.5 1.5-1.2 1.4-6.7 1.3-13.5-.1-20.2-.7-3.1-1.3-5.7-1.8-7.9-1.5-6.3-2.4-10.1-2.4-18 0-13.3 1.8-19.3 3.9-24.8 4.5 1.5 9.2 2.3 14 2.3h.2c7 0 11.5-1.7 13.9-3.1 1 8.7 6.9 11.5 12.7 14.2 8.3 3.9 17.7 8.4 17.7 31.1V136l1.4.1h1.3c3.6 0 13.7-.6 18.4-7.6 3.6-5.4 3.1-13-1.5-22.9-5-10.3-5.9-18-2.8-22.9zM88.9 63.3c-4.5 0-8.9-.7-13.1-2.1 1.5-3.8 1.5-6.9-.1-9.2-1.8-2.7-5.5-4-10.9-4-6.3 0-7.2-5.9-7.2-9.4 0-3.7.8-4.8 1-5h6.2c.8 0 1.5-.7 1.5-1.5 0-8.7 7.2-9.4 9.4-9.4h6.5c.8 0 1.5-.7 1.5-1.5v-8.9c2.8 1.1 8.2 4.1 12.5 11.8 4.1 7.4 6.3 15.3 6.4 35.7-1.2.9-5.4 3.5-13.7 3.5z"
    />
    <Path
      className="st0"
      d="M96.6 106.9c4.8-7.7 16.2-7.9 16.3-7.9v-3c-.5 0-13.2.1-18.9 9.3-4.1 6.6-3.4 15.9 2 27.8H85.5v3h38.4v-3H99.4c-5.5-11.5-6.4-20.3-2.8-26.2z"
    />
  </Svg>
)

export default SvgComponent
