import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SvgComponent = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48.6 21.6"
    style={{
      enableBackground: "new 0 0 48.6 21.6",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <Path d="M47.1 9.3H5.8L12 3.1c.7-.7.7-1.8 0-2.5s-1.8-.7-2.5 0l-9 9c-.7.7-.7 1.8 0 2.5l9 9c.7.7 1.8.7 2.5 0s.7-1.8.1-2.5l-6.2-6.2h41.3c.8 0 1.5-.7 1.5-1.5s-.8-1.6-1.6-1.6z" />
  </Svg>
)

export default SvgComponent
