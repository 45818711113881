import { useFonts } from 'expo-font';
import { Image, StyleSheet, Text, TouchableOpacity, View, ScrollView } from 'react-native';
import React, { useEffect, useState } from 'react';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import moment from 'moment';

import { Button, TextField, ButtonGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import colors from '../utils/colors';
import { Fragment } from 'react';
import CalendarIcon from '../../assets/svg/calendarIcon';
import ContactIcon from '../../assets/svg/contactIcon';
import ReactToPrint from 'react-to-print';
import ReturnIcon from '../../assets/svg/returnIcon';

const screenHeight = window.innerHeight;

// IMPORT SCREENS //
import InlineMeetingScreen from "./InlineMeetingScreen";
// FIN SCREENS //

//IMPORT DATAS // 
import salonsApi from '../api/salons';
import customerApi from '../api/customer';
import animalApi from '../api/animal';
import prestaApi from '../api/presta';
import authStorage from '../auth/storage';
import AuthContext from '../auth/context';
import meetingApi from '../api/meeting';
import employeeApi from '../api/employee';
//  FIN DATAS // 

//Gestion couleurs//
const alternanceColors = [colors.blue, colors.yellow, colors.red];
//Gestions couleurs//


import generateNewPdf from '../utils/pdfSalonAdmin';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import salons from '../api/salons';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


function PlanningSalon({ id, name, currentScreen, setCurrentScreen }) {



    const [salonId, setSalonId] = useState(id);
    const [meetingList, setMeetingList] = useState([]);
    const [popUp, setPopUp] = useState(false);
    const [popUpData, setPopUpData] = useState(null);





    var getDates = function (thisDate) {
        console.log(thisDate);
        var year = thisDate.getFullYear();
        var month = thisDate.getMonth();
        var date = new Date(year, month, 1);
        var result = [];
        console.log(year, month);

        var names = ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'];

        while (date.getMonth() == month) {

            var element = { "dateObject": date.toISOString(), "completeDate": date.toLocaleDateString('fr-FR'), 'date': date.getDate(), "day": names[date.getDay()] };
            result.push(element);
            date.setDate(date.getDate() + 1);


        }
        console.log("date list");
        console.log(result);
        return result;
    }


    const getCurrentDateString = function (type) {
        var days = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
        var months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
        var day = days[currentDate.getDay() - 1];
        var month = months[currentDate.getMonth()];
        if (dayMonth == "day" && type == "long") {
            return day + " " + currentDate.getDate() + " " + month + " " + currentDate.getFullYear();
        }
        else {
            return month + " " + currentDate.getFullYear();
        }
    }



    const updateDates = function (type, value) {

        console.log("-------------");
        console.log(currentDate);
        console.log(dayMonth);

        if (type === "change") {
            setCurrentDate(value);
        }
        if (type == "add") {
            setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + value)));
        }
        if (type == "remove") {
            setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - value)))
        }

    }

    const [value, setValue] = useState(null);
    const [dayMonth, setDayMonth] = useState("month");
    const [currentDate, setCurrentDate] = useState(new Date());
    const [currentDateString, setCurrentDateString] = useState({ "short": getCurrentDateString("short"), "long": getCurrentDateString("long") });
    const [datePickerOpened, setDatePickerOpened] = useState(false);

    var workingTime = ["8:00", "20:00"];


    var times = function (hours) {
        var timeList = [];
        var startingHour = hours[0];
        var lastHour = hours[1];
        var date = moment("01/01/2001 " + startingHour);

        console.log(date.format("HH:mm"));

        while (date.format("HH:mm") != lastHour) {
            timeList.push(date.format("HH:mm"));
            date.add(60, "m");
        }

        timeList.push(date.format("HH:mm"))

        return timeList;
    };

    const timesList = times(workingTime);


    const setBgColor = function (meetings, type) {
        var durationMorning = 0.0;
        var durationAfternoon = 0.0;

        meetings.forEach(function (element) {
            var time = new Date(element.datePlanned).getUTCHours();
            if (time < 12) {
                durationMorning += element.duration;
            }
            else {
                durationAfternoon += element.duration;
            }
        });

        if (type == "morning") {
            if (durationMorning == 0) {
                return "#FFFFFF";
            }
            else if (durationMorning < 2) {
                return "#F8F7E2";
            }
            else {
                return "#EFEA80";
            }
        }

        else if (type == "afternoon") {
            if (durationAfternoon == 0) {
                return "#FFFFFF";
            }
            else if (durationAfternoon < 2) {
                return "#F8F7E2";
            }
            else {
                return "#EFEA80";
            }
        }

    }

    const generateDayEmployee = function (value, date) {
        //VALUE = 0-JULIE 1-[...RDV]
        var thisDayEmployee = [];
        value[1].map(function (element, index) {
            if (new Date(element.datePlanned).toLocaleDateString('fr-FR') == date.completeDate) {
                thisDayEmployee.push(element);
            }
        });

        console.log(thisDayEmployee);
        if (thisDayEmployee.length > 0) {
            return (
                <View style={[styles.employeeDay, { backgroundColor: datas.index % 3 == 0 ? colors.lightBlue : datas.index % 2 == 0 ? colors.linen : colors.lightPink }]}>
                    <View style={styles.gridEmployeeName}><Text style={styles.gridEmployeeLabel}>{value[0]}</Text></View>
                    <TouchableOpacity onPress={() => {
                        updateDates("change", new Date(date.dateObject)); setDayMonth("day"); console.log("RESULTAT HEURES");
                    }} style={[styles.gridBox, styles.gridBorder, { backgroundColor: setBgColor(thisDayEmployee, "morning") }]}></TouchableOpacity>
                    <TouchableOpacity onPress={() => { updateDates("change", new Date(date.dateObject)); setDayMonth("day") }} style={[styles.gridBox, styles.gridBorder, { backgroundColor: setBgColor(thisDayEmployee, "afternoon") }]}></TouchableOpacity>
                </View>
            );
        }
        else {
            return (
                <View style={styles.employeeDay}>

                </View>
            );
        }
    }

    const [salons, setListSalons] = useState([]);

    const loadSalons = async () => {

        const response = await salonsApi.getSalons();
        console.log("REPONSE");
        console.log(response.data.salonFound);
        if (!response.ok) {
            console.log("test");
            console.log(response.data.salonFound);
        }

        setListSalons(response.data.salonFound);
        setCurrentScreen({ type: "salon", data: { "id": response.data.salonFound[0].id, "name": response.data.salonFound[0].name }, lastScreen: { type: currentScreen.type, data: currentScreen.data } });
    };

    const [employeeDates, setEmployeeDates] = useState([]);

    const loadEmployees = async () => {
        const response = await employeeApi.getAllActiveEmployeeDate({ date: currentDate });
        console.log("REPONSE");
        console.log(response.data);
        if (!response.ok) {
            console.log("test");
            console.log(response.data);
        }
        setEmployeeDates(response.data);
    }

    const listEmployeesAtDate = function (datas) {
        console.log("ListEmployeeFunction");
        console.log(datas);
        var employees = [];
        var string = " ";
        employeeDates.forEach(function (item, key) {
            if (moment(item.date).format('DD/MM/YYYY') == datas.date && item.ampm == datas.ampm && item.salonId == datas.salonId) employees.push(item.Employee.name);
        });

        return employees.join(" - ") != "" ? employees.join(" - ") : " ";

    }

    var dates = getDates(currentDate);
    const generatePdf = (action) => {
        generateNewPdf(meetingList, getCurrentDateString("short"), dates, employeeDates, salons, action);
    }

    useEffect(() => {
        setCurrentDateString({ "short": getCurrentDateString("short"), "long": getCurrentDateString("long") });
        loadSalons();
        loadEmployees();
    }, [currentDate, dayMonth, id, name, popUp]);




    return (
        <ScrollView style={[styles.body, { overflow: "unset" }]}>
            <View style={styles.screenTitle}>
                <Text style={styles.pageTitle}>TOUS LES SALONS</Text>
                <Text style={styles.titleSeparator}>|</Text>
                <Text style={styles.pageAction}>Planning (ADMIN)</Text>
            </View>
            <View style={styles.screenContainer}>
                <View style={styles.screenControllers}>
                    <View style={[styles.anchorLeftControllers, { zIndex: 300 }]}>
                        <Button variant="contained" onClick={() => { currentScreen.lastScreen !== undefined && currentScreen.lastScreen !== null ? setCurrentScreen({ type: currentScreen.lastScreen.type, data: currentScreen.lastScreen.data }) : null }} style={{ boxShadow: "none", fontFamily: "poppins", fontSize: 12, backgroundColor: "transparent", color: colors.ebony, borderRadius: 7, marginTop: 5, marginBottom: 5, textTransform: 'none', marginRight: 20 }} startIcon={<ReturnIcon height={25} width={30} style={{ fill: colors.ebony, marginRight: 10 }} resizeMode="contain" />}>Retour</Button>
                    </View>
                    <View style={styles.centeredControllers}>
                        <Text style={[styles.controllerTitle, { color: "transparent" }]}>Sélectionner la période</Text>
                    </View>
                    <View style={styles.anchorRightControllers}>
                        <Button variant="contained" onClick={() => (generatePdf("open"))} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: colors.ebony, color: "#FFFFFF", borderRadius: 7, textTransform: 'none', marginRight: 20 }}>Exporter en PDF</Button>
                        <Button variant="contained" onClick={() => (generatePdf("print"))} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: colors.smokedWhite, color: colors.ebony, borderRadius: 7, textTransform: 'none' }}>Imprimer</Button>
                    </View>

                </View>
                <View style={styles.screenControllers}>
                    <View style={styles.centeredControllers}>
                        {dayMonth == "month" ? <Text style={styles.pageTitle}>{currentDateString.long}</Text> : (
                            <View>
                                <Button style={{ backgroundColor: 'transparent', fontFamily: "poppinsSemiBold", textTransform: "uppercase", fontWeight: "bold", fontSize: 20, color: colors.ebony, padding: 0 }} endIcon={<ExpandMoreIcon />} onClick={() => setDatePickerOpened(datePickerOpened => !datePickerOpened)} >{currentDateString.long}</Button>
                                <MobileDatePicker
                                    sx={{ datepicker: { fontFamily: "poppins" } }}
                                    format='MMMM dd, yyyy'
                                    label="Selectionner une date"
                                    variant="static"
                                    value={currentDate}
                                    open={datePickerOpened}
                                    onOpen={() => setDatePickerOpened(true)}
                                    onClose={() => setDatePickerOpened(false)}
                                    onChange={(newValue) => {
                                        setCurrentDate(new Date(newValue));
                                        setDatePickerOpened(false)
                                    }}
                                    componentsProps={{
                                        actionBar: {
                                            // The actions will be the same between desktop and mobile
                                            actions: [""]
                                        }
                                    }}
                                    renderInput={(params) => null}
                                />
                            </View>
                        )}

                        <View style={styles.anchorRightControllers}>
                            <ButtonGroup variant="outlined" aria-label="outlined button group">
                                <Button style={{ borderColor: "#E2E2EA", color: colors.ebony, paddingLeft: 5, paddingRight: 5 }} onClick={() => updateDates("remove", 1)}>
                                    <KeyboardArrowLeftIcon />
                                </Button>
                                <Button disabled={true} style={{ borderColor: "#E2E2EA", color: colors.ebony, fontFamily: "poppins" }}>{currentDateString.short}</Button>
                                <Button style={{ borderColor: "#E2E2EA", color: colors.ebony, paddingLeft: 5, paddingRight: 5 }} onClick={() => updateDates("add", 1)}>
                                    <KeyboardArrowRightIcon />
                                </Button>
                            </ButtonGroup>
                        </View>
                    </View>
                </View>
                {popUp ? <View style={[styles.inlineMeeting]}><InlineMeetingScreen data={popUpData} popUp={popUp} setPopUp={setPopUp} /></View> : null}

                <View style={[styles.monthCalendar, { position: 'sticky', top: '75px', zIndex: 1000, backgroundColor: "white" }]}>
                    <View style={[styles.dayColumn, { width: 150, flex: "unset" }]}>
                        <View style={styles.dayTitle}><Text style={{ color: colors.ebony, fontFamily: "PoppinsSemiBold", textTransform: 'uppercase' }}>{currentDateString.short}</Text></View>
                    </View>
                    {
                        salons.map((element, index) => (
                            <View style={[styles.dayColumn, { marginRight: index < salons.length - 1 ? 50 : 0 }]}>
                                <View style={[styles.dayTitle, { backgroundColor: index % 3 == 0 ? colors.lightBlue : index % 2 == 0 ? colors.linen : colors.lightPink }]}><Text style={{ color: colors.ebony, fontFamily: "PoppinsSemiBold", textTransform: 'uppercase' }}>{element.name}</Text></View>
                            </View>
                        ))
                    }

                </View>

                <View style={styles.monthCalendar}>
                    <View style={styles.dayColumn}>
                        {
                            dates.map((date, dateKey) => (
                                date.day != "dim" ?
                                    (<Fragment>
                                        <View style={[{ backgroundColor: dateKey % 2 ? "transparent" : colors.iron, marginBottom: date.day == "sam" ? 20 : 0, textTransform: "uppercase", fontFamily: "poppinsBold", flexDirection: "row", alignContent: "center" }]}>
                                            <View style={{ flexDirection: "row", flex: 1, justifyContent: "center" }}>
                                                <Text style={[styles.gridBorder, { width: 50, height: "100%", lineHeight: "100%", textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", borderWidth: 2, borderColor: "white" }]}>{date.day}</Text>
                                                <Text style={[styles.gridBorder, { width: 50, height: "100%", lineHeight: "100%", textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", borderWidthr: 2, borderColor: "white" }]}>{date.date}</Text>
                                                <View style={[{ flex: 1, textAlign: "center", flexDirection: "row" }]}>
                                                    <View style={{ flexDirection: "column", width: 50 }}>
                                                        <View style={{ flexDirection: "column", flex: 1 }}>
                                                            <Text style={[styles.gridBorder, { width: "100%", textAlign: "center", borderWidth: 2, borderColor: "white" }]}>M</Text>
                                                        </View>
                                                        <View style={{ flexDirection: "column", flex: 1 }}>
                                                            <Text style={[styles.gridBorder, { width: "100%", textAlign: "center", borderWidth: 2, borderColor: "white" }]}>A</Text>
                                                        </View>
                                                        {/*<Text style={[styles.gridBorder, { width: "100%", lineHeight: "200%", minHeight: "50%", textAlign: "center", backgroundColor: "#F6F6DE" }]}>M</Text>
                                                    <Text style={[styles.gridBorder, { width: "100%", lineHeight: "200%", minHeight: "50%", textAlign: "center", backgroundColor: "#EAE98B" }]}>A</Text>*/}
                                                    </View>

                                                    {
                                                        salons.map((element, index) => (
                                                            <View style={{ flexDirection: "column", flex: 1, backgroundColor: index % 3 == 0 ? colors.lightBlue : index % 2 == 0 ? colors.linen : colors.lightPink }}>
                                                                <View style={{ flexDirection: "column", flex: 1, border: 2, borderColor: "white" }}>
                                                                    <Text style={[styles.gridBorder, { width: "100%", textAlign: "center", borderWidth: 2, borderColor: "white" }]}>{listEmployeesAtDate({ salonId: element.id, date: date.completeDate, ampm: "am" })}</Text>
                                                                </View>
                                                                <View style={{ flexDirection: "column", flex: 1, border: 2, borderColor: "white" }}>
                                                                    <Text style={[styles.gridBorder, { width: "100%", textAlign: "center", borderWidth: 2, borderColor: "white" }]}>{listEmployeesAtDate({ salonId: element.id, date: date.completeDate, ampm: "pm" })}</Text>
                                                                </View>
                                                                {/*<Text style={[styles.gridBorder, { width: "100%", lineHeight: "200%", minHeight: "50%", textAlign: "center", backgroundColor: "#F6F6DE" }]}>M</Text>
                                                            <Text style={[styles.gridBorder, { width: "100%", lineHeight: "200%", minHeight: "50%", textAlign: "center", backgroundColor: "#EAE98B" }]}>A</Text>*/}
                                                            </View>
                                                        ))
                                                    }
                                                </View>
                                            </View>


                                        </View>
                                    </Fragment>) :
                                    null
                            ))
                        }


                    </View>
                </View>

            </View>
        </ScrollView>
    );
}

export default PlanningSalon;

const styles = StyleSheet.create({

    inlineMeeting: {
        zIndex: 1500,
        flex: 1,
        flexDirection: "row",
        top: 0,
        width: "100%",
        position: "absolute",
        marginTop: 100
    },

    anchorLeftControllers: {
        flexDirection: "row",
        position: "absolute",
        left: 15,
    },

    mainView: {
        backgroundColor: colors.smokedWhite,
        flex: 1,
    },

    monthPicker: {
        borderColor: "#E2E2EA"
    },

    eventText: {
        flex: 1,
        flexDirection: "row",
        wdith: "100%",
        marginBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: "rgba(0, 0, 0, 0.1)",
        paddingTop: 5,
        paddingBottom: 5
    },

    eventTextTitle: {
        fontFamily: "poppinsSemiBold",
        marginRight: 10
    },

    eventGrid: {
        flex: 1,
        width: "100%",
        flexDirection: "row",
        top: 0,
        paddingLeft: 70,
        position: "absolute"

    },

    eventRow: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },

    eventColumn: {
        flex: 1,
        flexDirection: "column",
        paddingLeft: 5,
        paddingRight: 5
    },

    eventHeader: {
        height: 40,
        borderRadius: 25,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.yellow,
        marginBottom: 20
    },

    eventHeaderTitle: {
        fontFamily: "poppinsSemiBold",
        fontSize: 16
    },

    eventContent: {
        flex: 1,
        flexDirection: "column",
    },

    eventBox: {
        position: "absolute",
        top: 0,
        width: "100%",
        backgroundColor: colors.egg,
        borderWidth: 2,
        borderColor: colors.yellow,
        borderRadius: 10,
        height: 100,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20
    },

    eventButtons: {
        flex: 1,
        flexDirection: "row",
    },

    hourText: {
        textAlign: "center",
        width: 60,
        fontFamily: "poppinsSemiBold"
    },

    hoursGrid: {
        marginLeft: 10,
        marginRight: 10,
        paddingTop: 60,
        position: "relative",
    },

    hourRow: {
        height: 100,
        borderBottomWidth: 1,
        borderColor: "#e0e0e0",
        justifyContent: "center",

    },

    screenControllers: {
        marginBottom: 40,
        alignContent: "center",
        justifyContent: "center",
        textAlign: "center"
    },

    centeredControllers:
    {
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center"
    },

    controllerTitle: {
        fontSize: 12,
        fontFamily: "poppinsSemiBold",
        textAlignVertical: "center",
        marginTop: 8,
        marginRight: 10
    },

    buttonGroup:
    {
        flexDirection: "row",
        borderRadius: 7,
        padding: 2,
        backgroundColor: colors.milk
    },

    anchorRightControllers: {
        flexDirection: "row",
        position: "absolute",
        right: 15,
    },

    monthCalendar: {
        width: "100%",
        zIndex: 50,
        flexDirection: "row"
    },

    periodLabel: {
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center"
    },

    employeeDay: {
        flexDirection: "row",
        marginBottom: 2,
    },

    gridEmployeeName: {
        flex: 1
    },

    gridEmployeeLabel:
    {
        fontFamily: "poppinsSemiBold",
        color: colors.ebony
    },

    gridBox: {
        width: "25%",
        alignItems: "center"
    },

    gridBorder: {
        borderWidth: 0.5,
        borderColor: colors.charcoal
    },

    dayColumn:
    {
        flex: 1,
    },

    dayTitle:
    {

        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: 50,
        backgroundColor: colors.smokedWhite,
        marginBottom: 20
    },

    dateBox:
    {
        minHeight: 100,
        borderWidth: 1,
        borderColor: colors.charcoal,
        paddingLeft: 10,
        paddingBottom: 10
    },

    dayNumber: {
        paddingLeft: 10,
        fontFamily: "poppins",
        color: colors.mist,
        fontSize: 12,
        paddingTop: 5,
        paddingBttom: 5
    },

    navbar: {
        height: 75,
        width: "100%",
        backgroundColor: "#FFFFFF",
        borderBottomColor: "#E2E2EA",
        borderBottomWidth: 2,
        flexDirection: "row",
        position: "fixed",
        zIndex: 10
    },

    switchScreen: {
        backgroundColor: colors.ebony,
        width: "100%",
        height: 50,
        alignItems: "center",
        justifyContent: "center"
    },

    screenContainer: {
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        width: "100%",
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 20,
        paddingBottom: 20,
        flex: 1
    },

    categoryButton: {
        backgroundColor: colors.linen,
        width: "100%",
        height: 50,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 0
    },

    logoContainer: {
        width: 225,
        height: "100%",
        padding: 5,
        alignItems: "center",
        borderRightColor: "#E2E2EA",
        borderRightWidth: 1
    },

    shortcutContainer: {
        flex: 1,
        flexDirection: "row",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10
    },

    searchContainer: {
        flex: 1
    },

    screenTitle: {
        borderRadius: 5,
        width: "100%",
        height: 50,
        backgroundColor: colors.linen,
        marginBottom: 10,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },

    titleSeparator: {
        marginLeft: 20,
        marginRight: 20,
        fontSize: 20,
        color: "#D8D8D8"
    },

    pageAction: {
        fontSize: 18,
        fontWeight: "600",
        color: colors.mist
    },

    pageTitle: {
        fontFamily: "poppinsSemiBold",
        textTransform: "uppercase",
        fontWeight: "bold",
        fontSize: 20,
        color: colors.ebony
    },

    searchInput: {
        width: 300
    },

    mainContainer: {
        flex: 1,
        flexDirection: "row"
    },

    aside: {
        paddingTop: 20,
        marginTop: 75,
        width: 225,
        height: screenHeight - 75,
        backgroundColor: colors.snow,
        borderRightColor: "#E2E2EA",
        borderRightWidth: 1,
        position: "fixed",
        zIndex: 10
    },

    asideContainer: {
        flex: 1,
    },



});
