import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from 'moment';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


const generateNewPdf = function (meetings, date, listDate, employeeDates, listSalons, action) {

    const listEmployeesAtDate = function (datas) {
        console.log("ListEmployeeFunction");
        console.log(datas);
        var employees = [];
        var string = " ";
        employeeDates.forEach(function (item, key) {
            if (moment(item.date).format('DD/MM/YYYY') == datas.date && item.ampm == datas.ampm && item.salonId == datas.salonId) employees.push(item.Employee.name);
        });

        return employees.join(" - ") != "" ? employees.join(" - ") : " ";

    }

    var bodyMeetings = function (meetings) {
        var header = [{ colSpan: 2, text: date }, ''];
        listSalons.forEach(function (value, index) {
            header.push(value.name);
        });
        var bodyContent = [];
        bodyContent.push(header);


        if (meetings != {}) {
            listDate.map(function (date, index) {
                if (date.day != "dim") {
                    var firstRow = [{ rowSpan: 2, text: date.day + " " + date.date }, "A"];
                    var secondRow = [{ text: "" }, { text: "M", pageBreak: date.day == "sam" ? 'after' : null }];
                    listSalons.forEach(function (value, index) {
                        firstRow.push(listEmployeesAtDate({ salonId: value.id, date: date.completeDate, ampm: "am" }));
                        secondRow.push(listEmployeesAtDate({ salonId: value.id, date: date.completeDate, ampm: "pm" }));
                    })
                    bodyContent.push(firstRow);
                    bodyContent.push(secondRow);
                }

            });


        }

        console.log(bodyContent);
        return bodyContent;
    }

    var listMeetings = bodyMeetings(meetings);


    var dd = {
        pageOrientation: 'landscape',
        content: [
            {
                text: 'Présence aux salons ' + date,
                style: 'header'
            },
            {
                style: 'tableExample',
                table: {
                    widths: "*",
                    body: listMeetings,

                }
            }
        ],
        styles: {
            tableExample:
            {
                fontSize: 8,
                width: '100%'
            },
            header: {
                fontSize: 18,
                bold: true,
                alignment: "center",
                marginBottom: 20,
            },
            subheader: {
                fontSize: 15,
                bold: true
            },
            quote: {
                italics: true
            },
            small: {
                fontSize: 8
            }
        }

    }

    if (action == "print") {
        pdfMake.createPdf(dd).print();
    }
    else {
        pdfMake.createPdf(dd).open();
    }

};

export default generateNewPdf
