import { useFonts } from 'expo-font';
import { Image, StyleSheet, Text, TouchableOpacity, View, ScrollView } from 'react-native';
import React, { useEffect, useState, useContext } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import moment, { calendarFormat } from 'moment';
import ReturnIcon from '../../assets/svg/returnIcon';

import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { Button, Input, TextField, ButtonGroup, Autocomplete } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import EventNoteIcon from '@mui/icons-material/EventNoteOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import colors from '../utils/colors';
import { fr } from 'date-fns/locale'
//import contactIcon from '../../assets/contactIcon.svg';
//import planningIcon from '../../assets/planningIcon.svg';
import { style } from '@mui/system';
import { Fragment } from 'react';
import { TouchableWithoutFeedback } from 'react-native';
import CalendarIcon from '../../assets/svg/calendarIcon';
import ContactIcon from '../../assets/svg/contactIcon';
import { TextInput } from 'react-native';
import { DesktopDatePicker, MobileDateTimePicker, TimePicker } from '@mui/x-date-pickers';
import { useSnackbar } from 'material-ui-snackbar-provider';


// IMPORT DIALOGS // 
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// FIN DIALOGS // 

//IMPORT DATAS // 
import salonsApi from '../api/salons';
import customerApi from '../api/customer';
import animalApi from '../api/animal';
import prestaApi from '../api/presta';
import authStorage from '../auth/storage';
import AuthContext from '../auth/context';
import meetingApi from '../api/meeting';
import employeeApi from '../api/employee';
//  FIN DATAS // 

const screenHeight = window.innerHeight;


function LayoutScreen({ currentScreen, setCurrentScreen, animal, selectedAnimal, setSelectedAnimal }) {



    //if(animal !== undefined) alert(JSON.stringify(animal));

    const [listSalons, setListSalons] = useState([]);

    const loadSalons = async () => {

        const response = await salonsApi.getSalons();
        console.log("REPONSE");
        console.log(response.data.salonFound);
        if (!response.ok) {
            console.log("test");
            console.log(response.data.salonFound);
        }

        setListSalons(response.data.salonFound);
    };

    const [listMeetings, setListMeetings] = useState([]);

    const loadMeetings = async () => {

        if (newMeeting.salonId != null) {
            const response = await meetingApi.getMeetingsBySalon({ "salonId": newMeeting.salonId, "date": newMeeting.datePlanned.format("YYYY-MM-DD"), "period": "day" });
            console.log("REPONSE");
            console.log(response.data);
            if (!response.ok) {
                console.log("test");
                console.log(response.data);
            }

            setListMeetings(response.data);
            if (newMeeting.surbooking == 1) {

                var availableTimes = [
                    "07:00", "07:15", "07:30", "07:45",
                    "08:00", "08:15", "08:30", "08:45",
                    "09:00", "09:15", "09:30", "09:45",
                    "10:00", "10:15", "10:30", "10:45",
                    "11:00", "11:15", "11:30", "11:45",
                    "12:00", "12:15", "12:30", "12:45",
                    "13:00", "13:15", "13:30", "13:45",
                    "14:00", "14:15", "14:30", "14:45",
                    "15:00", "15:15", "15:30", "15:45",
                    "16:00", "16:15", "16:30", "16:45",
                    "17:00", "17:15", "17:30", "17:45",
                    "18:00", "18:15", "18:30", "18:45",
                    "19:00", "19:15", "19:30", "19:45",
                    "20:00"
                ];
            }
            else if (ampm == "day") {
                var availableTimes = [
                    "07:00", "07:15", "07:30", "07:45",
                    "08:00", "08:15", "08:30", "08:45",
                    "09:00", "09:15", "09:30", "09:45",
                    "10:00", "10:15", "10:30", "10:45",
                    "11:00", "11:15", "11:30", "11:45",
                    "12:00", "12:15", "12:30", "12:45",
                    "13:00", "13:15", "13:30", "13:45",
                    "14:00", "14:15", "14:30", "14:45",
                    "15:00", "15:15", "15:30", "15:45",
                    "16:00", "16:15", "16:30", "16:45",
                    "17:00", "17:15", "17:30", "17:45",
                    "18:00", "18:15", "18:30", "18:45",
                    "19:00", "19:15", "19:30", "19:45",
                    "20:00"
                ];
            }

            else if (ampm == "am") {
                var availableTimes = [
                    "07:00", "07:15", "07:30", "07:45",
                    "08:00", "08:15", "08:30", "08:45",
                    "09:00", "09:15", "09:30", "09:45",
                    "10:00", "10:15", "10:30", "10:45",
                    "11:00", "11:15", "11:30", "11:45",
                    "12:00"
                ];
            }

            else if (ampm == "pm") {
                var availableTimes = [
                    "12:00", "12:15", "12:30", "12:45",
                    "13:00", "13:15", "13:30", "13:45",
                    "14:00", "14:15", "14:30", "14:45",
                    "15:00", "15:15", "15:30", "15:45",
                    "16:00", "16:15", "16:30", "16:45",
                    "17:00", "17:15", "17:30", "17:45",
                    "18:00", "18:15", "18:30", "18:45",
                    "19:00", "19:15", "19:30", "19:45",
                    "20:00"
                ];
            }


            response.data.forEach(function (meeting, index) {
                var debut = moment(meeting.datePlanned).utc();
                var fin = moment(meeting.datePlanned).add(meeting.duration + meeting.aditionalTime, "hours").utc();
                var current = moment(meeting.datePlanned).utc();
                while (current.format("HH:mm") != fin.format("HH:mm")) {

                    if (newMeeting.surbooking == 1 && meeting.surbooking == 1) {
                        availableTimes = availableTimes.filter(e => e !== current.format("HH:mm"));
                    }
                    else if (newMeeting.surbooking == 0 && newMeeting.employeeId == meeting.employeeId && meeting.surbooking == 0) {
                        availableTimes = availableTimes.filter(e => e !== current.format("HH:mm")); // will return ['A', 'C']
                    }
                    //console.log(current.format("HH:mm"));
                    current.add(15, "minutes");
                }

            });

            console.log(availableTimes);

            var duration = newMeeting.duration + (moment.duration(newMeeting.additionalTime).asHours());
            console.log("--DUREE--")
            console.log(duration);
            availableTimes.forEach(function (depot, index) {
                var debut = moment(depot, "HH:mm");
                var fin = moment(depot, "HH:mm").add(duration, "hours").subtract(15, "minutes");
                var current = moment(depot, "HH:mm");
                var impossible = 0;
                while (current.format("HH:mm") != fin.format("HH:mm")) {
                    if (depot == "18:15") console.log(fin.format("HH:mm"));
                    if (!availableTimes.includes(current.format("HH:mm"))) impossible = 1;// will return ['A', 'C']
                    current.add(15, "minutes");
                    //console.log(current.format("HH:mm"));
                }
                if (!availableTimes.includes(current.format("HH:mm"))) impossible = 1;

                if (impossible == 1) availableTimes = availableTimes.filter(e => e !== debut.format("HH:mm"));

            });


            console.log("-- AVAILABLE --");
            console.log(availableTimes);
            setDepotList(availableTimes);

        }
    };

    const [animalInfos, setAnimalInfos] = useState([]);
    const getAnimalInfos = async () => {

        const response = await animalApi.getAnimalInfos(newMeeting.animalId);
        console.log("REPONSE");
        if (!response.ok) {
            console.log("test");
            console.log(response.data);
        }

        setAnimalInfos(response.data);


    };


    const hoursList = [
        "00:00", "00:15", "00:30", "00:45",
        "01:00", "01:15", "01:30", "01:45",
        "02:00"
    ];

    const [depotList, setDepotList] = useState([
        "07:00", "07:15", "07:30", "07:45",
        "08:00", "08:15", "08:30", "08:45",
        "09:00", "09:15", "09:30", "09:45",
        "10:00", "10:15", "10:30", "10:45",
        "11:00", "11:15", "11:30", "11:45",
        "12:00", "12:15", "12:30", "12:45",
        "13:00", "13:15", "13:30", "13:45",
        "14:00", "14:15", "14:30", "14:45",
        "15:00", "15:15", "15:30", "15:45",
        "16:00", "16:15", "16:30", "16:45",
        "17:00", "17:15", "17:30", "17:45",
        "18:00", "18:15", "18:30", "18:45",
        "19:00", "19:15", "19:30", "19:45",
        "20:00"
    ]);

    const snackbar = useSnackbar();

    var getDates = function (thisDate) {
        console.log(thisDate);
        var year = thisDate.getFullYear();
        var month = thisDate.getMonth();
        var date = new Date(year, month, 1);
        console.log(year, month);
        var names = ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'];
        var result = { "": [], "lun": [], "mar": [], "mer": [], "jeu": [], "ven": [], "sam": [], "dim": [] };
        var firstDay = names[date.getDay()];
        for (var i = 0; i < names.length; i++) {
            if (firstDay == names[i] && firstDay != "dim") break;
            if (names[i] != "dim") result[names[i]].push({ "completeDate": null });
        }

        while (date.getMonth() == month) {

            var element = { "completeDate": date.toLocaleDateString('fr-FR'), 'date': date.getDate(), "day": names[date.getDay()] };
            result[element.day].push(element);
            date.setDate(date.getDate() + 1);


        }
        return result;
    }


    const getCurrentDateString = function (type) {
        var days = ["Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche", "Lundi"];
        var months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
        var day = days[currentDate.getDay()];
        var month = months[currentDate.getMonth()];
        if (dayMonth == "day" && type == "long") {
            return day + " " + currentDate.getDate() + " " + month + " " + currentDate.getFullYear();
        }
        else {
            return month + " " + currentDate.getFullYear();
        }
    }

    const updateDates = function (type, value) {

        console.log("-------------");
        console.log(currentDate);
        console.log(dayMonth);

        if (type === "change") {
            setCurrentDate(value);
        }
        if (type == "add") {
            setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + value)));
        }
        if (type == "remove") {
            setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - value)))
        }

    }

    const [value, setValue] = useState(null);
    const [dayMonth, setDayMonth] = useState("day");
    const [currentDate, setCurrentDate] = useState(new Date());
    const [currentDateString, setCurrentDateString] = useState({ "short": getCurrentDateString("short"), "long": getCurrentDateString("long") });
    const [datePickerOpened, setDatePickerOpened] = useState(false);

    var workingTime = ["7:00", "20:00"];


    const setPrestaInfos = function (id) {
        var prestaInfos = animalInfos.Prestations.find(item => item.id === id);
        setNewMeeting((prevState) => ({
            ...prevState,
            prestaArray: prestaInfos,
            duration: prestaInfos.duration,
            prestationName: prestaInfos.name,
            price: prestaInfos.price,
            prestaInfos: prestaInfos.comments
        }));
    }


    var times = function (hours) {
        var timeList = [];
        var startingHour = hours[0];
        var lastHour = hours[1];
        var date = moment("01/01/2001 " + startingHour);

        console.log(date.format("HH:mm"));

        while (date.format("HH:mm") != lastHour) {
            timeList.push(date.format("HH:mm"));
            date.add(60, "m");
        }

        timeList.push(date.format("HH:mm"))

        return timeList;
    };

    const timesList = times(workingTime);

    var dates = getDates(currentDate);

    const emptyMeetingForm = { salonId: null, salonName: null, customerId: selectedAnimal != null ? selectedAnimal.customerId: animal !== undefined ? animal.Customer.id : null, customerName: null, animalId: selectedAnimal != null ? selectedAnimal.id : animal !== undefined ? animal.id : null, prestationId: null, prestationName: null, employeeId: null, datePlanned: moment(), comments: null, hour: "07:00", additionalTime: 0, duration: null, price: null, animal: animal, prestaInfos: null, surbooking: 0, lienReservation: null };
    const [newMeeting, setNewMeeting] = useState(emptyMeetingForm);
    const [customerSearch, setCustomerSearch] = useState([]);

    const [newPrestaForm, setNewPrestaForm] = useState(false);
    const newPrestaEmptyForm = { name: null, duration: 0.25, durationDate: "00:15", comments: null, price: null };
    const [newPresta, setNewPresta] = useState(newPrestaEmptyForm);

    const [updatePrestaForm, setUpdatePrestaForm] = useState(false);
    const updatePrestaEmptyForm = { name: null, durationDate: null, comments: null, price: null };
    const [updatePresta, setUpdatePresta] = useState(updatePrestaEmptyForm);


    const customerList = async (search) => {
        const listCustomer = await animalApi.getAnimalList(search);
        setCustomerSearch(listCustomer.data);
        console.log("List customers");
        console.log(listCustomer)
    };

    const validateForm = async () => {
        console.log(newMeeting);
        if (newMeeting.salonId == null || newMeeting.customerId == null || newMeeting.animalId == null || newMeeting.prestationId == null || newMeeting.employeeId == null || newMeeting.datePlanned == null || newMeeting.hour == null) snackbar.showMessage("Certains champs sont vides.");
        else {
            setConfirmDialog(true);
            /*
            newMeeting.datePlanned = moment(newMeeting.datePlanned).format("YYYY-MM-DD") + " " + newMeeting.hour;
            snackbar.showMessage("Rendez vous du "+ (moment(newMeeting.datePlanned).format("DD/MM/YYYY")) +" " + newMeeting.hour+" créé pour l'animal "+animalInfos.name+". Les SMS sont désactivés pour la démonstration.");
            const response = await meetingApi.newMeeting(newMeeting);
            setCurrentScreen({ type: "salon", data: { "id": newMeeting.salonId, "name":  newMeeting.salonName, "currentDate": newMeeting.datePlanned, "view": "day"} })
            */
        }
    };


    const validateUpdatePrestaForm = async () => {
        if (updatePresta.name != null && updatePresta.price > 0) {
            updatePresta.animalId = newMeeting.animalId;
            updatePresta.duration = moment.duration(updatePresta.durationDate).asHours();
            const prestaResponse = await prestaApi.updatePrestation(updatePresta);
            if (!prestaResponse.ok) snackbar.showMessage(prestaResponse.error);
            else {
                snackbar.showMessage("Prestation correctement modifiée");
                setUpdatePresta(updatePrestaEmptyForm);
                setUpdatePrestaForm(false);
                getAnimalInfos();
            }
        }
        else snackbar.showMessage("La prestation doit avoir un tarif");

    };

    const validatePrestaForm = async () => {
        console.log(newPresta);
        if (newPresta.name != null && newPresta.price > 0) {
            newPresta.animalId = newMeeting.animalId;
            newPresta.duration = moment.duration(newPresta.durationDate).asHours();

            const prestaResponse = await prestaApi.newPrestation(newPresta);
            if (!prestaResponse.ok) snackbar.showMessage(prestaResponse.error);
            else {
                snackbar.showMessage("Prestation correctement créee");
                setNewPresta(newPrestaEmptyForm);
                setNewPrestaForm(false);
                getAnimalInfos();
            }
        }
        else snackbar.showMessage("La prestation doit avoir une désignation et un tarif");

    };

    const createMeeting = async () => {
        if (newMeeting.salonId == null || newMeeting.customerId == null || newMeeting.animalId == null || newMeeting.prestationId == null || newMeeting.employeeId == null || newMeeting.datePlanned == null || newMeeting.hour == null) snackbar.showMessage("Certains champs sont vides.");
        else {
            newMeeting.comments = "Rdv pris par: " + selectedAllEmployee + " | " + newMeeting.comments;
            newMeeting.datePlanned = moment(newMeeting.datePlanned).format("YYYY-MM-DD") + " " + newMeeting.hour;
            snackbar.showMessage("Rendez-vous du " + (moment(newMeeting.datePlanned).format("DD/MM/YYYY")) + " " + newMeeting.hour + " créé pour l'animal " + animalInfos.name + ".");
            const response = await meetingApi.newMeeting(newMeeting);
            if(selectedAnimal != null) setSelectedAnimal(null);
            setCurrentScreen({ type: "salon", data: { "id": newMeeting.salonId, "name": newMeeting.salonName, "currentDate": newMeeting.datePlanned, "view": "day" }, lastScreen: { type: currentScreen.type, data: currentScreen.data } })
        }
    };

    const [employees, setListEmployees] = useState([]);
    const [allEmployees, setListAllEmployees] = useState([]);

    const [ampm, setAmpm] = useState("day");

    const loadEmployees = async () => {

        if (newMeeting.salonId != null && newMeeting.datePlanned != null) {
            const response = await employeeApi.getAvailableEmployeeBySalons({ salonId: newMeeting.salonId, date: moment(newMeeting.datePlanned).format("YYYY-MM-DD") });
            console.log("REPONSE");
            console.log(response.data);
            if (!response.ok) {
                console.log("test");
                console.log(response.data);
            }

            var employeeList = [];

            if (response.error) {

            }
            else if (response.data != []) {
                response.data.forEach(function (item, key) {
                    employeeList.push({ id: item.Employee.id, name: item.Employee.name, ampm: item.nb == 1 ? item.ampm : "day" });
                    console.log({ id: item.Employee.id, name: item.Employee.name, ampm: item.nb == 1 ? item.ampm : "day" });
                });
            }

            setListEmployees(employeeList);
        }

    };

    const loadAllEmployees = async () => {

        const response = await employeeApi.getActiveEmployees();
        console.log("REPONSE");
        console.log(response.data);
        if (!response.ok) {
            console.log("test");
            console.log(response.data);
        }

        var employeeList = [];

        if (response.error) {

        }
        else if (response.data != []) {
            response.data.forEach(function (item, key) {
                employeeList.push(item.name);
                console.log(item.name);
            });
        }



        setListAllEmployees(employeeList);
        if (selectedAllEmployee == "") setSelectedAllEmployee(employeeList[0]);

    };

    const [selectedEmployee, setSelectedEmployee] = useState("");
    const [selectedAllEmployee, setSelectedAllEmployee] = useState("");
    const [confirmDialog, setConfirmDialog] = useState(false);

    useEffect(() => {
        setCurrentDateString({ "short": getCurrentDateString("short"), "long": getCurrentDateString("long") });
        loadSalons();
        loadEmployees();
        loadAllEmployees();
        loadMeetings();
        getAnimalInfos();

    }, [currentDate, dayMonth, newMeeting, ampm, animal, selectedEmployee]);


    const [loaded] = useFonts({
        poppins: require('../fonts/Poppins-Regular.ttf'),
        poppinsMedium: require('../fonts/Poppins-Medium.ttf'),
        poppinsSemiBold: require('../fonts/Poppins-SemiBold.ttf'),
        poppinsBold: require('../fonts/Poppins-Bold.ttf')
    });

    if (!loaded) {
        return null;
    }



    return (

        <ScrollView style={styles.body}>
            <View style={[styles.screenTitle, { backgroundColor: colors.blue }]}>
                <Text style={styles.pageTitle}>CREER UN NOUVEAU RENDEZ-VOUS</Text>
            </View>
            <Dialog
                open={confirmDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirmation du rendez-vous
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Veuillez vérifier les informations ci-dessous avant de confirmer la création du rendez-vous.
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Client: {newMeeting.customerName} | Animal: {animalInfos !== null ? animalInfos.name : ""}
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Salon: {newMeeting.salonName} | Affectation: {selectedEmployee !== "" ? JSON.parse(selectedEmployee).name : null}
                    </DialogContentText>
                    <DialogContentText>
                        Date: {moment(newMeeting.datePlanned).format("DD/MM/YYYY")} | Heure : {newMeeting.hour}
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Prestation: {newMeeting.prestationName} | Durée: {moment.utc(moment.duration(newMeeting.duration, "hours").as('milliseconds')).format('HH:mm')} | Temps sup: {newMeeting.additionalTime}
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description">
                        Lien de réservation: {newMeeting.lienReservation && newMeeting.lienReservation != "" ? newMeeting.lienReservation : "Sans lien de réservation"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setConfirmDialog(false); createMeeting(); }} style={{ color: colors.blue }}>Confirmer</Button>
                    <Button onClick={() => setConfirmDialog(false)} style={{ color: colors.red }}>
                        Annuler
                    </Button>
                </DialogActions>
            </Dialog>
            <View style={styles.screenContainerExtraMarges}>
                <View style={[styles.anchorLeftControllers, { zIndex: 300 }]}>
                    <Button variant="contained" onClick={() => { currentScreen.lastScreen !== undefined && currentScreen.lastScreen !== null ? setCurrentScreen({ type: currentScreen.lastScreen.type, data: currentScreen.lastScreen.data }) : null }} style={{ boxShadow: "none", fontFamily: "poppins", fontSize: 12, backgroundColor: "transparent", color: colors.ebony, borderRadius: 7, marginTop: 5, marginBottom: 5, textTransform: 'none', marginRight: 20 }} startIcon={<ReturnIcon height={25} width={30} style={{ fill: colors.ebony, marginRight: 10 }} resizeMode="contain" />}>Retour</Button>
                </View>
                <View style={[styles.formContainer, { marginTop: 50 }]}>
                    {updatePrestaForm == true ? (
                        <Fragment>
                            <View style={styles.formColumn}>
                                <View style={styles.inputRow}>
                                    <View style={styles.formLabelColumn}>
                                        <Text style={styles.inputLabel}>Prestation</Text>
                                    </View>
                                    <View style={styles.formInputsColumn}>
                                        <TextField
                                            value={updatePresta.name != null ? updatePresta.name : ""}
                                            fullWidth label="" disabled autoCorrect='false' placeholder='Informations complémentaires' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                    </View>
                                </View>

                                <View style={styles.inputRow}>
                                    <View style={styles.formLabelColumn}>
                                        <Text style={styles.inputLabel}>Temps</Text>
                                    </View>
                                    <View style={styles.formInputsColumn}>
                                        <Select
                                            fullWidth
                                            sx={{
                                                "& fieldset": { border: 'none' },
                                            }}
                                            value={updatePresta.durationDate ? updatePresta.durationDate : "00:15"}
                                            onChange={(value) => (setUpdatePresta((prevState) => ({
                                                ...prevState,
                                                durationDate: value.target.value
                                            })))}
                                            disableUnderline={true}
                                            displayEmpty
                                            IconComponent={(props) => (
                                                <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                            )}

                                        >
                                            {hoursList.map((item, key) => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                            ))}
                                        </Select>
                                    </View>
                                </View>
                                <View style={styles.inputRow}>
                                    <View style={styles.formLabelColumn}>
                                        <Text style={styles.inputLabel}>Infos presta.</Text>
                                    </View>
                                    <View style={styles.formInputsColumn}>
                                        <TextField
                                            value={updatePresta.comments ? updatePresta.comments : ""}
                                            onChange={(value) => (setUpdatePresta((prevState) => ({
                                                ...prevState,
                                                comments: value.target.value
                                            })))}
                                            fullWidth label="" multiline rows={4} autoCorrect='false' placeholder='Informations complémentaires' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                    </View>
                                </View>
                                <View style={styles.inputRow}>
                                    <View style={styles.formLabelColumn}>
                                        <Text style={styles.inputLabel}>Tarif</Text>
                                    </View>
                                    <View style={styles.formInputsColumn}>
                                        <TextField
                                            value={updatePresta.price ? updatePresta.price : ""}
                                            onChange={(value) => (setUpdatePresta((prevState) => ({
                                                ...prevState,
                                                price: value.target.value
                                            })))}
                                            endAdornment={<InputAdornment position="end">€</InputAdornment>}
                                            fullWidth label="" type="number" min autoCorrect='false' placeholder='Prix de la prestation' variant="standard" InputProps={{ disableUnderline: true, inputProps: { min: 1 } }} InputLabelProps={{ shrink: false }} />
                                    </View>
                                </View>
                            </View>
                        </Fragment>
                    ) : newPrestaForm == true ? (
                        <Fragment>
                            <View style={styles.formColumn}>
                                <View style={styles.inputRow}>
                                    <View style={styles.formLabelColumn}>
                                        <Text style={styles.inputLabel}>Prestation</Text>
                                    </View>
                                    <View style={styles.formInputsColumn}>
                                        <Select
                                            fullWidth
                                            sx={{
                                                "& fieldset": { border: 'none' },
                                            }}
                                            value={newPresta.name != null ? newPresta.name : ""}
                                            onChange={(value) => (setNewPresta((prevState) => ({
                                                ...prevState,
                                                name: value.target.value
                                            })))}
                                            disableUnderline={true}
                                            displayEmpty
                                            IconComponent={(props) => (
                                                <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                            )}

                                        >
                                            <MenuItem value="Bain">Bain</MenuItem>
                                            <MenuItem value="Brossage">Brossage</MenuItem>
                                            <MenuItem value="Chat">Chat</MenuItem>
                                            <MenuItem value="Coupe">Coupe</MenuItem>
                                            <MenuItem value="Coupe NL">Coupe NL</MenuItem>
                                            <MenuItem value="Coupe P">Coupe P</MenuItem>
                                            <MenuItem value="Epilation">Epilation</MenuItem>
                                            <MenuItem value="Pré Toilettage">Pré Toilettage</MenuItem>
                                            <MenuItem value="Retouche">Retouche</MenuItem>
                                            <MenuItem value="Self">Self</MenuItem>
                                            <MenuItem value="Temps passé">Temps passé</MenuItem>
                                            <MenuItem value="Tonte">Tonte</MenuItem>
                                        </Select>
                                    </View>
                                </View>
                                <View style={styles.inputRow}>
                                    <View style={styles.formLabelColumn}>
                                        <Text style={styles.inputLabel}>Temps</Text>
                                    </View>
                                    <View style={styles.formInputsColumn}>
                                        <Select
                                            fullWidth
                                            sx={{
                                                "& fieldset": { border: 'none' },
                                            }}
                                            value={newPresta.durationDate ? newPresta.durationDate : "00:15"}
                                            onChange={(value) => (setNewPresta((prevState) => ({
                                                ...prevState,
                                                durationDate: value.target.value
                                            })))}
                                            disableUnderline={true}
                                            displayEmpty
                                            IconComponent={(props) => (
                                                <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                            )}

                                        >
                                            {hoursList.map((item, key) => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                            ))}
                                        </Select>
                                    </View>
                                </View>
                                <View style={styles.inputRow}>
                                    <View style={styles.formLabelColumn}>
                                        <Text style={styles.inputLabel}>Infos presta.</Text>
                                    </View>
                                    <View style={styles.formInputsColumn}>
                                        <TextField
                                            value={newPresta.comments ? newPresta.comments : ""}
                                            onChange={(value) => (setNewPresta((prevState) => ({
                                                ...prevState,
                                                comments: value.target.value
                                            })))}
                                            fullWidth label="" multiline rows={4} autoCorrect='false' placeholder='Informations complémentaires' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                    </View>
                                </View>
                                <View style={styles.inputRow}>
                                    <View style={styles.formLabelColumn}>
                                        <Text style={styles.inputLabel}>Tarif</Text>
                                    </View>
                                    <View style={styles.formInputsColumn}>
                                        <TextField
                                            value={newPresta.price ? newPresta.price : ""}
                                            onChange={(value) => (setNewPresta((prevState) => ({
                                                ...prevState,
                                                price: value.target.value
                                            })))}
                                            endAdornment={<InputAdornment position="end">€</InputAdornment>}
                                            fullWidth label="" type="number" min autoCorrect='false' placeholder='Prix de la prestation' variant="standard" InputProps={{ disableUnderline: true, inputProps: { min: 1 } }} InputLabelProps={{ shrink: false }} />
                                    </View>
                                </View>
                            </View>
                        </Fragment>
                    ) : (
                        
                        <Fragment>
                            <View style={styles.formColumn}>
                                <View style={styles.inputRow}>
                                    <View style={styles.formLabelColumn}>
                                        <Text style={styles.inputLabel}>Animal / Client / Tel</Text>
                                    </View>
                                    <View style={styles.formInputsColumn}>
                                        <Autocomplete
                                            freeSolo
                                            fullWidth
                                            options={customerSearch}
                                            value={selectedAnimal != null ? selectedAnimal : newMeeting.animalId == "" || newMeeting.animal == null ? "" : animal}
                                            onChange={(event, value) => {
                                                setNewMeeting((prevState) => ({
                                                    ...prevState,
                                                    animalId: value != null ? value.id : null,
                                                    customerId: value != null ? value.Customer.id : null,
                                                    customerName: value != null ? value.Customer.name : null
                                                })); if (value != null) (getAnimalInfos())
                                            }}

                                            getOptionLabel={option => option == "" ? "" : ((option.type == "dog" ? "Chien" : option.type == "cat" ? "Chat" : "Sans") + " " + option.name + " | Client : " + option.Customer.name + " " + option.Customer.lastName) + " | Tel : " + option.Customer.tel1 + (option.Customer.tel2 != "" && option.Customer.tel2 != null ? " - " + option.Customer.tel2 : "")}
                                            id="free-solo-2-demo"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label=""

                                                    variant="standard"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        disableUnderline: true,
                                                        type: 'search',
                                                    }}
                                                    onChange={ev => {
                                                        // dont fire API if the user delete or not entered anything
                                                        if (ev.target.value !== "" || ev.target.value !== null) {
                                                            customerList(ev.target.value);
                                                        }
                                                    }}
                                                    InputLabelProps={{ shrink: false }}
                                                />
                                            )}
                                        />
                                    </View>
                                    
                                </View>
                                { newMeeting.animalId != null && (animalInfos != null && animalInfos.Customer !== undefined && animalInfos.Customer.sms == 0)  ? (
                                    <View style={styles.inputRow}>
                                        <Text style={{color: colors.red, fontWeight: "bold"}}>Attention, les SMS sont désactivés pour ce client. Il ne recevra pas de SMS de confirmation, ni de rappel.</Text>
                                    </View>
                                ) : null 
                                }
                                


                                {newMeeting.animalId != null ? (
                                    <Fragment>
                                        <View style={styles.inputRow}>
                                            <View style={styles.formLabelColumn}>
                                                <Text style={styles.inputLabel}>Prestation</Text>
                                            </View>
                                            <View style={styles.formInputsColumn}>
                                                <Select
                                                    fullWidth
                                                    sx={{
                                                        "& fieldset": { border: 'none' },
                                                    }}
                                                    value={newMeeting.prestationId ? newMeeting.prestationId : ""}
                                                    onChange={(value) => {
                                                        setNewMeeting((prevState) => ({
                                                            ...prevState,
                                                            prestationId: value.target.value
                                                        })); setPrestaInfos(value.target.value)
                                                    }}
                                                    disableUnderline={true}
                                                    displayEmpty
                                                    IconComponent={(props) => (
                                                        <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                                    )}
                                                >
                                                    {animalInfos != null && animalInfos.Prestations !== undefined ? animalInfos.Prestations.map((item, key) => (
                                                        <MenuItem value={item.id}>{item.name} (Durée : {moment.utc(moment.duration(item.duration, "hours").as('milliseconds')).format('HH:mm')} | Tarif : {item.price}€)</MenuItem>
                                                    )) : (
                                                        <MenuItem disabled value={null}>Chargement...</MenuItem>
                                                    )}

                                                </Select>
                                            </View>
                                        </View>
                                        <View style={styles.inputRow}>
                                            <View style={styles.formLabelColumn}>
                                                <Text style={styles.inputLabel}>Remise</Text>
                                            </View>
                                            <View style={styles.formInputsColumn}>
                                                <TextField
                                                    value={animalInfos != null ? animalInfos.remise ? (animalInfos.remise * 100) + "%" : "0%" : null}
                                                    fullWidth label="" disabled autoCorrect='false' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                            </View>
                                        </View>
                                        <View style={styles.inputRow}>
                                            <View style={styles.formLabelColumn}>
                                                <Text style={styles.inputLabel}>Salon</Text>
                                            </View>
                                            <View style={styles.formInputsColumn}>
                                                <Select
                                                    fullWidth
                                                    sx={{
                                                        "& fieldset": { border: 'none' },
                                                    }}
                                                    value={newMeeting.salonId ? JSON.stringify({ name: newMeeting.salonName, id: newMeeting.salonId }) : ""}
                                                    onChange={(value) => {
                                                        setNewMeeting((prevState) => ({
                                                            ...prevState,
                                                            salonId: JSON.parse(value.target.value).id,
                                                            salonName: JSON.parse(value.target.value).name
                                                        })); console.log("-- SALON VALUE --"); console.log(value);
                                                    }}
                                                    disableUnderline={true}
                                                    displayEmpty
                                                    IconComponent={(props) => (
                                                        <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                                    )}
                                                >
                                                    {listSalons.map((item, key) => (
                                                        <MenuItem value={JSON.stringify({ name: item.name, id: item.id })}>{item.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </View>
                                        </View>
                                        {newMeeting.salonId != null ? (
                                            <Fragment>
                                                <View style={styles.inputRow}>
                                                    <View style={styles.formLabelColumn}>
                                                        <Text style={styles.inputLabel}>Date</Text>
                                                    </View>
                                                    <View style={styles.formInputsColumn}>
                                                        <DesktopDatePicker
                                                            fullWidth
                                                            sx={{ width: "100%" }}
                                                            label=""
                                                            variant="standard"
                                                            placeholder="Date"
                                                            inputFormat="DD/MM/YYYY"
                                                            minDate={moment()}
                                                            value={newMeeting.datePlanned ? newMeeting.datePlanned : moment()}
                                                            onChange={(value) => (setNewMeeting((prevState) => ({
                                                                ...prevState,
                                                                datePlanned: value
                                                            })))}
                                                            renderInput={(params) => <TextField sx={{ width: "100%", backgroundColor: colors.smokedWhite, paddingLeft: "30px", borderRadius: 25, border: "none", "& fieldset": { border: "none" } }} {...params} />}
                                                        />
                                                    </View>

                                                </View>
                                                <View style={styles.inputRow}>
                                                    <View style={styles.formLabelColumn}>
                                                        <Text style={styles.inputLabel}>Affectation</Text>
                                                    </View>
                                                    <View style={styles.formInputsColumn}>
                                                        <Select
                                                            fullWidth
                                                            sx={{
                                                                "& fieldset": { border: 'none' },
                                                            }}
                                                            value={selectedEmployee ? selectedEmployee : ""}
                                                            onChange={(value) => {
                                                                (setNewMeeting((prevState) => ({
                                                                    ...prevState,
                                                                    employeeId: JSON.parse(value.target.value).id
                                                                }))); setAmpm(JSON.parse(value.target.value).ampm); setSelectedEmployee(value.target.value)
                                                            }}
                                                            disableUnderline={true}
                                                            displayEmpty
                                                            IconComponent={(props) => (
                                                                <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                                            )}
                                                        >
                                                            {
                                                                employees != [] ? employees.map((item, key) => (
                                                                    <MenuItem value={JSON.stringify({ id: item.id, ampm: item.ampm, name: item.name })}>{item.name}</MenuItem>
                                                                )) : null
                                                            }
                                                        </Select>
                                                    </View>
                                                </View>
                                                <View style={styles.inputRow}>
                                                    <View style={styles.formLabelColumn}>
                                                        <Text style={styles.inputLabel}>Surbooking</Text>
                                                    </View>
                                                    <View style={styles.formInputsColumn}>
                                                        <Select
                                                            fullWidth
                                                            sx={{
                                                                "& fieldset": { border: 'none' },
                                                            }}
                                                            value={newMeeting.surbooking}
                                                            onChange={(value) => {
                                                                setNewMeeting((prevState) => ({
                                                                    ...prevState,
                                                                    surbooking: value.target.value
                                                                }));
                                                            }}
                                                            disableUnderline={true}
                                                            displayEmpty
                                                            IconComponent={(props) => (
                                                                <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                                            )}
                                                        >
                                                            <MenuItem value={0}>Non</MenuItem>
                                                            <MenuItem value={1}>Oui</MenuItem>
                                                        </Select>
                                                    </View>
                                                </View>
                                                <View style={styles.inputRow}>
                                                    <View style={styles.formLabelColumn}>
                                                        <Text style={styles.inputLabel}>RDV pris par</Text>
                                                    </View>
                                                    <View style={styles.formInputsColumn}>
                                                        <Select
                                                            fullWidth
                                                            sx={{
                                                                "& fieldset": { border: 'none' },
                                                            }}
                                                            value={selectedAllEmployee ? selectedAllEmployee : allEmployees[0]}
                                                            onChange={(value) => {
                                                                setSelectedAllEmployee(value.target.value)
                                                            }}
                                                            disableUnderline={true}
                                                            displayEmpty
                                                            IconComponent={(props) => (
                                                                <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                                            )}
                                                        >
                                                            {
                                                                allEmployees != [] ? allEmployees.map((item, key) => (
                                                                    <MenuItem value={item}>{item}</MenuItem>
                                                                )) : null
                                                            }
                                                        </Select>
                                                    </View>
                                                </View>
                                            </Fragment>
                                        ) : null}
                                        <View style={styles.inputRow}>
                                            <View style={styles.formLabelColumn}>
                                                <Text style={styles.inputLabel}>Infos client</Text>
                                            </View>
                                            <View style={styles.formInputsColumn}>
                                                <TextField
                                                    value={animalInfos != null && animalInfos.Customer !== undefined ? animalInfos.Customer.infos : animal != null ? animal.Customer.infos : ""}
                                                    disabled
                                                    fullWidth label="" multiline rows={4} autoCorrect='false' placeholder='Informations complémentaires' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                            </View>
                                        </View>
                                        <View style={styles.inputRow}>
                                            <View style={styles.formLabelColumn}>
                                                <Text style={styles.inputLabel}>Infos animal</Text>
                                            </View>
                                            <View style={styles.formInputsColumn}>
                                                <TextField
                                                    value={animalInfos != null && animalInfos.comments !== undefined ? animalInfos.comments : animal != null ? animal.comments : ""}
                                                    disabled
                                                    fullWidth label="" multiline rows={4} autoCorrect='false' placeholder='Informations complémentaires' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                            </View>
                                        </View>
                                    </Fragment>
                                ) : null}

                            </View>
                            <View style={[styles.formColumn, { marginLeft: 50 }]}>
                                {/*<View style={styles.inputRow}>
                            <View style={styles.formLabelColumn}>
                                <Text style={styles.inputLabel}>Heure dépot</Text>
                            </View>
                            <View style={styles.formInputsColumn}>
                                <TimePicker
                                    disableOpenPicker
                                    KeyboardButtonProps={{ disabled: true, style: { display: 'none' } }}
                                    fullWidth
                                    ampm={false}
                                    label=""
                                    minutesStep={15}
                                    placeholder="Temps"
                                    minTime={moment().utc().startOf('day').add(7, "hours")}
                                    maxTime={moment().utc().startOf('day').add(20, "hours")}
                                    value={newMeeting.hour ? moment("2010-01-01" + newMeeting.hour) : moment().utc().startOf('day')}
                                    onChange={(value) => (setNewMeeting((prevState) => ({
                                        ...prevState,
                                        hour: value.format("hh:mm:ss")
                                    })))}
                                    renderInput={(params) => <TextField sx={{ width: "100%", backgroundColor: colors.smokedWhite, paddingLeft: "30px", borderRadius: 25, border: "none", "& fieldset": { border: "none" } }} {...params} />}
                                />
                            </View>
                        </View>*/}
                                <View style={styles.inputRow}>
                                    <View style={styles.formLabelColumn}>
                                        <Text style={styles.inputLabel}>Temps sup.</Text>
                                    </View>
                                    <View style={styles.formInputsColumn}>
                                        <Select
                                            fullWidth
                                            sx={{
                                                "& fieldset": { border: 'none' },
                                            }}
                                            value={newMeeting.additionalTime ? newMeeting.additionalTime : ""}
                                            onChange={(value) => (setNewMeeting((prevState) => ({
                                                ...prevState,
                                                additionalTime: value.target.value
                                            })))}
                                            disableUnderline={true}
                                            displayEmpty
                                            IconComponent={(props) => (
                                                <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                            )}

                                        >
                                            {hoursList.map((item, key) => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                            ))}
                                        </Select>

                                    </View>
                                </View>
                                <View style={styles.inputRow}>
                                    <View style={styles.formLabelColumn}>
                                        <Text style={styles.inputLabel}>Heure dépot</Text>
                                    </View>
                                    <View style={styles.formInputsColumn}>
                                        <Select
                                            fullWidth
                                            sx={{
                                                "& fieldset": { border: 'none' },
                                            }}
                                            value={newMeeting.hour ? newMeeting.hour : "07:00"}
                                            onChange={(value) => (setNewMeeting((prevState) => ({
                                                ...prevState,
                                                hour: value.target.value
                                            })))}
                                            disableUnderline={true}
                                            displayEmpty
                                            IconComponent={(props) => (
                                                <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                            )}

                                        >
                                            {depotList.map((item, key) => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                            ))}
                                        </Select>

                                    </View>
                                </View>
                                <View style={styles.inputRow}>
                                    <View style={styles.formLabelColumn}>
                                        <Text style={styles.inputLabel}>Infos rendez-vous</Text>
                                    </View>
                                    <View style={styles.formInputsColumn}>
                                        <TextField
                                            value={newMeeting.comments ? newMeeting.comments : ""}
                                            onChange={(value) => (setNewMeeting((prevState) => ({
                                                ...prevState,
                                                comments: value.target.value
                                            })))}
                                            fullWidth label="" multiline rows={4} autoCorrect='false' placeholder='Informations complémentaires' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                    </View>
                                </View>
                                <View style={styles.inputRow}>
                                    <View style={styles.formLabelColumn}>
                                        <Text style={styles.inputLabel}>Infos prestation</Text>
                                    </View>
                                    <View style={styles.formInputsColumn}>
                                        <TextField
                                            value={newMeeting.prestaInfos ? newMeeting.prestaInfos : ""}
                                            disabled
                                            fullWidth label="" multiline rows={4} autoCorrect='false' placeholder='Informations complémentaires' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                    </View>
                                </View>
                                { newMeeting.animalId != null && (animalInfos != null && animalInfos.Customer !== undefined && animalInfos.Customer.sms == 1)  ? (
                                    <View style={styles.inputRow}>
                                    <View style={styles.formLabelColumn}>
                                        <Text style={styles.inputLabel}>Lien réservation</Text>
                                    </View>
                                        <View style={styles.formInputsColumn}>
                                            <TextField
                                                value={newMeeting.lienReservation ? newMeeting.lienReservation : ""}
                                                onChange={(value) => (setNewMeeting((prevState) => ({
                                                    ...prevState,
                                                    lienReservation: value.target.value
                                                })))}
                                                fullWidth label="" autoCorrect='false' placeholder='Lien de paiement réservation' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                        </View>
                                    </View>
                                ) : null 
                                }

                            </View>
                        </Fragment>
                    )}

                </View>
                {updatePrestaForm == true ? (
                    <View style={styles.centeredControllers}>
                        <Button variant="contained" onClick={() => { validateUpdatePrestaForm(); }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, fontSize: 12, backgroundColor: colors.blue, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 180, alignSelf: "center", height: 50 }}>CONFIRMER MODIFICATION</Button>
                        <Button variant="contained" onClick={() => { setUpdatePresta(newPrestaEmptyForm); setUpdatePrestaForm(false); }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: colors.red, color: "#FFFFFF", borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 180, alignSelf: "center", height: 50 }}>ANNULER MODIFICATION</Button>
                    </View>
                ) : newPrestaForm == true ? (
                    <View style={styles.centeredControllers}>
                        <Button variant="contained" onClick={() => { validatePrestaForm(); }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, fontSize: 12, backgroundColor: colors.blue, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 180, alignSelf: "center", height: 50 }}>CONFIRMER PRESTATION</Button>
                        <Button variant="contained" onClick={() => { setNewPresta(newPrestaEmptyForm); setNewPrestaForm(false); }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: colors.red, color: "#FFFFFF", borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 180, alignSelf: "center", height: 50 }}>ANNULER PRESTA</Button>
                    </View>
                ) : (
                    <View style={styles.centeredControllers}>
                        <Button variant="contained" onClick={() => { validateForm(); }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, fontSize: 12, backgroundColor: colors.blue, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 180, alignSelf: "center", height: 50 }}>CONFIRMER</Button>
                        <Button variant="contained" onClick={() => (setNewMeeting(emptyMeetingForm))} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: colors.red, color: "#FFFFFF", borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 180, alignSelf: "center", height: 50 }}>ANNULER</Button>
                        {newMeeting.animalId != null ? (
                            <Fragment>
                                <Button variant="contained" onClick={() => { setNewPrestaForm(true); }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, marginLeft: 50, fontSize: 12, backgroundColor: colors.blue, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 180, alignSelf: "center", height: 50 }}>AJOUTER UNE PRESTATION</Button>
                                {newMeeting.prestationId != null ? (
                                    <Button variant="contained" onClick={() => { setUpdatePrestaForm(true); setUpdatePresta({ name: newMeeting.prestaArray.name, durationDate: moment.utc(moment.duration(newMeeting.prestaArray.duration, "hours").as('milliseconds')).format('HH:mm'), comments: newMeeting.prestaArray.comments, price: newMeeting.prestaArray.price, prestationId: newMeeting.prestaArray.id }) }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, fontSize: 12, backgroundColor: colors.blue, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 180, alignSelf: "center", height: 50 }}>MODIFIER LA PRESTATION</Button>
                                ) : null}

                            </Fragment>
                        ) : null}
                    </View>
                )}

            </View>
        </ScrollView >
    );
}

export default LayoutScreen;


const styles = StyleSheet.create({

    anchorLeftControllers: {
        flexDirection: "row",
        position: "absolute",
        left: 15,
    },


    mainView: {
        backgroundColor: colors.smokedWhite,
        flex: 1,
    },

    //FORM

    formContainer: {
        flex: 1,
        flexDirection: "row",
        marginRight: 50,
        marginLeft: 50,
        marginBottom: 50

    },

    formColumn: {
        flex: 1,
        flexDirection: "column",
        width: "auto",

    },

    formLabelColumn: {
        flex: 1,
        flexDirection: "row",
        paddingTop: 20,

    },

    formInputsColumn: {
        width: "70%",
        flexDirection: "row",
        paddingLeft: 20,
        paddingRight: 20
    },

    formInputsGroup: {
        flexDirection: "row",
        flex: 1,
        justifyContent: "space-evenly"
    },

    inputRow: {
        flexDirection: "row",
        marginBottom: 20,
    },

    inputLabel: {
        fontFamily: "poppinsSemiBold",
        color: colors.ebony,
        lineHeight: "100%",
        height: "100%",
    },

    separator: {
        height: 2,
        backgroundColor: colors.ebony,
        width: "100%",
        marginBottom: 50
    },

    // END FORM



    monthPicker: {
        borderColor: "#E2E2EA"
    },

    customSearchInputContainer: {
        borderColor: colors.ebony,
        borderWidth: 1,
        width: 400,
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        flexDirection: "row",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 5,
        paddingBottom: 5,
        borderRadius: 25,

    },

    customSearchInput: {
        borderWidth: 0,
        borderColor: "transparent",
        flex: 1,
        height: "100%",
        width: "auto",
        fontFamily: "poppinsSemiBold",
        fontWeight: "bold",
        fontSize: 14,
        color: colors.ebony,
        outlineColor: 'transparent'

    },

    eventText: {
        flex: 1,
        flexDirection: "row",
        wdith: "100%",
        marginBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: "rgba(0, 0, 0, 0.1)",
        paddingTop: 5,
        paddingBottom: 5
    },

    eventTextTitle: {
        fontFamily: "poppinsSemiBold",
        marginRight: 10
    },

    eventGrid: {
        flex: 1,
        width: "100%",
        flexDirection: "row",
        top: 0,
        paddingLeft: 70,
        position: "absolute"

    },

    eventRow: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },

    eventColumn: {
        flex: 1,
        flexDirection: "column",
        paddingLeft: 5,
        paddingRight: 5
    },

    eventHeader: {
        height: 40,
        borderRadius: 25,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.yellow,
        marginBottom: 20
    },

    eventHeaderTitle: {
        fontFamily: "poppinsSemiBold",
        fontSize: 16
    },

    eventContent: {
        flex: 1,
        flexDirection: "column",
    },

    eventBox: {
        backgroundColor: colors.egg,
        borderWidth: 4,
        borderColor: colors.yellow,
        borderRadius: 10,
        height: 100,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20
    },

    eventButtons: {
        flex: 1,
        flexDirection: "row",
    },

    hourText: {
        textAlign: "center",
        width: 60,
        fontFamily: "poppinsSemiBold"
    },

    hoursGrid: {
        marginLeft: 10,
        marginRight: 10,
        paddingTop: 60,
        position: "relative",
    },

    hourRow: {
        height: 100,
        borderBottomWidth: 1,
        borderColor: "#e0e0e0",
        justifyContent: "center",

    },

    screenControllers: {
        marginBottom: 40,
        alignContent: "center",
        justifyContent: "center",
        textAlign: "center"
    },

    centeredControllers:
    {
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center",
        marginBottom: 20
    },


    controllerTitle: {
        fontSize: 12,
        fontFamily: "poppinsSemiBold",
        textAlignVertical: "center",
        marginTop: 8,
        marginRight: 10
    },

    buttonGroup:
    {
        flexDirection: "row",
        borderRadius: 7,
        padding: 2,
        backgroundColor: colors.milk
    },

    anchorRightControllers: {
        flexDirection: "row",
        position: "absolute",
        right: 15,
    },

    monthCalendar: {
        width: "100%",
        flex: 1,
        zIndex: 50,
        flexDirection: "row"
    },

    periodLabel: {
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center"
    },

    employeeDay: {
        flexDirection: "column",
        flex: 1,
        marginBottom: 2,
    },

    gridEmployeeName: {
        flex: 1
    },

    gridEmployeeLabel:
    {
        fontFamily: "poppinsSemiBold",
        color: colors.ebony
    },

    gridBox: {
        width: "100%",
        flex: 1,
        alignItems: "center"
    },

    gridBorder: {
        borderWidth: 0.5,
        borderColor: colors.charcoal
    },

    dayColumn:
    {
        flex: 1,
    },

    dayTitle:
    {
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: 50,
        backgroundColor: colors.smokedWhite,
        marginBottom: 20
    },

    dateBox:
    {
        height: 100,
        borderWidth: 1,
        borderColor: colors.charcoal,
        paddingLeft: 10,
        paddingRight: 10,
    },

    dayNumber: {
        paddingLeft: 10,
        fontFamily: "poppins",
        color: colors.mist,
        fontSize: 12,
        paddingTop: 10,
        paddingBttom: 10
    },

    navbar: {
        height: 75,
        width: "100%",
        backgroundColor: "#FFFFFF",
        borderBottomColor: "#E2E2EA",
        borderBottomWidth: 2,
        flexDirection: "row",
        position: "fixed",
        zIndex: 10
    },

    switchScreen: {
        backgroundColor: colors.ebony,
        width: "100%",
        height: 50,
        alignItems: "center",
        justifyContent: "center"
    },

    screenContainer: {
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        width: "100%",
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 20,
        paddingBottom: 20,
        flex: 1
    },

    screenContainerExtraMarges: {
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        width: "100%",
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 50,
        paddingBottom: 50,
        flex: 1
    },

    categoryButton: {
        backgroundColor: colors.linen,
        width: "100%",
        height: 50,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 0
    },

    logoContainer: {
        width: 225,
        height: "100%",
        padding: 5,
        alignItems: "center",
        borderRightColor: "#E2E2EA",
        borderRightWidth: 1
    },

    shortcutContainer: {
        flex: 1,
        flexDirection: "row",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10
    },

    searchContainer: {
        flex: 1
    },

    screenTitle: {
        borderRadius: 5,
        width: "100%",
        height: 50,
        backgroundColor: colors.linen,
        marginBottom: 10,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },

    titleSeparator: {
        marginLeft: 20,
        marginRight: 20,
        fontSize: 20,
        color: "#D8D8D8"
    },

    pageAction: {
        fontSize: 18,
        fontWeight: "600",
        color: colors.mist
    },

    pageTitle: {
        fontFamily: "poppinsSemiBold",
        textTransform: "uppercase",
        fontWeight: "bold",
        fontSize: 20,
        color: colors.ebony
    },

    searchInput: {
        width: 300
    },

    mainContainer: {
        flex: 1,
        flexDirection: "row"
    },

    aside: {
        paddingTop: 20,
        marginTop: 75,
        width: 225,
        height: screenHeight - 75,
        backgroundColor: colors.snow,
        borderRightColor: "#E2E2EA",
        borderRightWidth: 1,
        position: "fixed",
        zIndex: 10
    },

    asideContainer: {
        flex: 1,
    },

});
