import client from './client';

const getActiveEmployees = () => client.get("/getActiveEmployees");
const getAvailableEmployeeBySalons = (datas) => client.post("/getAvailableEmployeeBySalons", datas);
const newEmployee = (datas) => client.post("/newEmployee", datas);
const updateEmployee = (datas) => client.post('/updateEmployee', datas);
const getEmployeeInfos = (employeeId) => client.get("/getEmployeeInfos?employeeId=" + employeeId);
const getListEmployees = () => client.get("/getListEmployees");
const getActiveEmployeeDate = (datas) => client.post("/getActiveEmployeeDate", datas);
const getAllActiveEmployeeDate = (datas) => client.post("/getAllActiveEmployeeDate", datas);
const getAllEmployeeDate = (datas) => client.post("/getAllEmployeeDate", datas);
const getEmployeeDateTypes = () => client.get('/getEmployeeDateTypes');
const setEmployeeDate = (datas) => client.post('/setEmployeeDate', datas);
export default
    {
        getActiveEmployees, setEmployeeDate, getAvailableEmployeeBySalons,getEmployeeDateTypes, getAllEmployeeDate, newEmployee, updateEmployee, getEmployeeInfos, getListEmployees,getActiveEmployeeDate, getAllActiveEmployeeDate
    }