/* global require */
import { useFonts } from 'expo-font';
import React, { useContext, useState, useEffect } from 'react';
import { StatusBar } from 'expo-status-bar';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ElementsScreen from './app/screens/ElementsScreen';
import HeaderMenu from './app/screens/HeaderMenu';
import HeaderMenuAdmin from './app/screens/HeaderMenuAdmin';
import LayoutScreen from './app/screens/LayoutScreen';
import EmployeeScreen from './app/screens/PlanningEmployee';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { LocalizationProvider, frFR } from '@mui/x-date-pickers';
import deLocale from 'date-fns/locale/fr'
import { TextField } from '@mui/material';
import colors from './app/utils/colors';
import { borderBottom, borderBottomColor } from '@mui/system';
import { ToastProvider } from 'react-native-toast-notifications';
import { SnackbarProvider } from 'material-ui-snackbar-provider'
import AuthNavigator from './app/navigation/AuthNavigator';
import AuthContext from './app/auth/context';
import authStorage from './app/auth/storage';
import { NavigationContainer, useNavigation } from '@react-navigation/native';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import 'moment/locale/fr';

export default function App() {

  moment.locale('fr');

  const THEME = createTheme({
    typography: {
      "fontFamily": "poppins",
      "fontSize": 14,
      "fontStyle": 'normal',
      "fontWeightLight": 300,
      "fontWeightRegular": 400,
      "fontWeightMedium": 500
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            fontSize: '50',
            backgroundColor: colors.smokedWhite
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            "&:input":
            {
              padding: 0
            }
          },
          input: {
            padding: "10px 40px !important"
          }
        }
      },
      MuiIconButton: {
        root: {
          backgroundColor: 'red'
        }
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            border: "none",
            "&:fieldset": {
              border: "none"
            }
          }
        }
      },
      MuiTimePicker: {
        styleOverrides: {
          root: {
            backgroundColor: "red",
            "&:fieldset": {
              border: "none"
            }
          }
        }
      },
      MuiDatePicker: {
        styleOverrides: {
          root: {
            backgroundColor: "red",
            "&:fieldset": {
              border: "none"
            }
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          border: "none",
          select: {
            padding: "10px 40px !important",
            borderRadius: 25,
            "&:fieldset": {
              backgroundColor: "red"
            }
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            backgroundColor: colors.smokedWhite,
            borderRadius: 25,
            fontFamily: "poppins",
            fontStyle: "normal",
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 40,
            paddingRight: 40,
            color: colors.ebony,
            '&::before': {
              border: "none",
              borderBottomColor: "transparent",
            },
            '& fieldset': {
              border: "none",
              outline: "none",
              color: "transparent"
            }

          }
        }
      },
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            borderRadius: 25
          }
        }
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            borderRadius: 25
          },
        }
      },
      MuiTextField: {
        styleOverrides: {
          input: {
            color: colors.ebony,
            backgroundColor: colors.smokedWhite,
            '&::before': {
              border: "none" // use your color
            },
            '& fieldset': {
              border: "none"
            }
          }
        },
        defaultProps: {
          // The props to change the default for.
          disableRipple: true, // No more ripple, on the whole application 💣!
          borderRadius: 25,
          backgroundColor: "red"
        },


      }
    },
    icon: {
      color: "#FFFFFF"
    },
    selectPaper: {
      backgroundColor: "#1E1E24",
      border: "1px solid #484850",
      borderRadius: "5px",
      color: "#FFFFFF",
      "& li:hover": {
        backgroundColor: "#303039"
      }
    }
  }, frFR
  );

  const [user, setUser] = useState();

  const [adminView, setAdminView] = useState(false);

  const restoreUser = async () => {
    const thisUser = await authStorage.getUser();

    if (!thisUser) return;
    setUser(thisUser);
    console.log('user:' + thisUser);
    console.log(thisUser);

  };


  useEffect(() => {
    //requestPermission();
    restoreUser();

  }, [])

  const [loaded] = useFonts({
    poppins: require('./app/fonts/Poppins-Regular.ttf'),
    poppinsMedium: require('./app/fonts/Poppins-Medium.ttf'),
    poppinsSemiBold: require('./app/fonts/Poppins-SemiBold.ttf'),
    poppinsBold: require('./app/fonts/Poppins-Bold.ttf')
  });

  if (!loaded) {
    return null;
  }

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      <ThemeProvider successColor={colors.blue} dangerColor={colors.red} warningColor={colors.yellow} offset={500} theme={THEME}>
        <LocalizationProvider locale={"fr"} dateAdapter={AdapterMoment}>
          <SnackbarProvider SnackbarProps={{
            autoHideDuration: 10000, anchorOrigin: {
              vertical: "bottom",
              horizontal: "center"
            }, bodyStyle: { backgroundColor: "red" }
          }}>
            <ToastProvider>

              <NavigationContainer>
                {user ? 
                  adminView ? (<HeaderMenuAdmin admin={user.isAdmin} adminView={adminView} setAdminView={setAdminView}/>) : 
                <HeaderMenu admin={user.isAdmin} adminView={adminView} setAdminView={setAdminView}/> 
                : <AuthNavigator />}
              </NavigationContainer>
            </ToastProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </AuthContext.Provider>

  );
}

const styles = StyleSheet.create({



});
