export default {
    lightPink : "#FDE5E5",
    iron: "#E3E3E5",
    milk: "#FCFCFE",
    snow: "#FAFAFA",
    mist: "#92929D",
    ebony: "#171725",
    lightBlue: "#E0F6FA",
    blue: "#A2D8E3",
    charcoal: "#707070",
    egg: "#F8F7E2",
    yellow: "#EFEA80",
    smokedWhite: "#F8F4EF",
    linen: "#FAEEE2",
    cashmere: "#E0C3A4",
    red: "#ED6B69"
    
};