import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;


const generateNewPdf = function (meetings, date, salon, action) {

    var bodyMeetings = function (meetings) {

        var bodyContent = [];

        if (meetings != {}) {
            Object.entries(meetings).map(function (value, index) {
                var hasMeeting = 0;
                var rdv = [];
                value[1].map(function (element, index) {
                    console.log(new Date(element.datePlanned).toLocaleDateString('fr-FR'));
                    if (new Date(element.datePlanned).toLocaleDateString('fr-FR') == date.toLocaleDateString('fr-FR')) {
                        hasMeeting = 1;
                        rdv.push(element);
                    }
                });
                if (hasMeeting == 1 && rdv.length > 0) {
                    if (rdv[0].surbooking == 1) {
                        bodyContent.push([{ text: "SURBOOKING", alignment: "center", bold: true, colSpan: 8 }, '', '', '', '', '', '', ''])
                        rdv.forEach(function (element, value) {
                            bodyContent.push([("0" + (new Date(element.datePlanned).getUTCHours())).slice(-2) + ":" + ("0" + (new Date(element.datePlanned).getUTCMinutes())).slice(-2) + " (" + element.Employee.name + ")", element.Customer.lastName + " " + element.Customer.name, element.Animal.name + ' (' + (element.Animal.type == "dog" ? "Chien" : "Chat") + " | " + element.Animal.race + " )", element.Prestation.name, Math.trunc(element.duration + element.aditionalTime) + "H" + ("0" + (60 * ((element.duration + element.aditionalTime) % 1))).slice(-2), element.price, element.endPrice, element.comments]);
                        });
                    }
                    else {
                        bodyContent.push([{ text: rdv[0].Employee.name, alignment: "center", bold: true, colSpan: 8 }, '', '', '', '', '', '', ''])
                        rdv.forEach(function (element, value) {
                            bodyContent.push([("0" + (new Date(element.datePlanned).getUTCHours())).slice(-2) + ":" + ("0" + (new Date(element.datePlanned).getUTCMinutes())).slice(-2), element.Customer.lastName + " " + element.Customer.name, element.Animal.name + ' (' + (element.Animal.type == "dog" ? "Chien" : "Chat") + " | " + element.Animal.race + " )", element.Prestation.name, Math.trunc(element.duration + element.aditionalTime) + "H" + ("0" + (60 * ((element.duration + element.aditionalTime) % 1))).slice(-2), element.price, element.endPrice, element.comments]);
                        });
                    }
                }

                //if (index == 0) 
            });
        }

        console.log(bodyContent);
        return bodyContent;
    }

    var listMeetings = bodyMeetings(meetings);


    var dd = {
        pageOrientation: 'landscape',
        content: [
            {
                text: 'Planning du ' + date.toLocaleDateString('fr-FR') + ' pour le salon de ' + salon,
                style: 'header'
            },
            {
                style: 'tableExample',
                table: {
                    widths: [50, 100, 100, 100, 25, 50, 50, '*'],
                    body: [['HEURE', 'CLIENT', 'ANIMAL', 'PRESTATION', 'DUREE', 'PRIX', 'PRIX PAYE', 'COMMENTAIRE RDV']]
                }
            },
            {
                style: 'tableExample',
                table: {
                    heights: 25,
                    widths: [50, 100, 100, 100, 25, 50, 50, '*'],
                    body: listMeetings

                }
            }
        ],
        styles: {
            tableExample:
            {
                fontSize: 8,
                width: '100%'
            },
            header: {
                fontSize: 18,
                bold: true,
                alignment: "center",
                marginBottom: 20,
            },
            subheader: {
                fontSize: 15,
                bold: true
            },
            quote: {
                italics: true
            },
            small: {
                fontSize: 8
            }
        }

    }

    if (action == "print") {
        pdfMake.createPdf(dd).print();
    }
    else {
        pdfMake.createPdf(dd).open();
    }

};

export default generateNewPdf
