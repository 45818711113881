import { useFonts } from 'expo-font';
import { StyleSheet, Text, View } from 'react-native';
import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';

import { Button, TextField } from '@mui/material';
import colors from '../utils/colors';
import CloseIcon from '@mui/icons-material/Close';
import { DesktopDatePicker, MobileDateTimePicker, TimePicker } from '@mui/x-date-pickers';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSnackbar } from 'material-ui-snackbar-provider';
import InputAdornment from '@mui/material/InputAdornment';


// IMPORT DIALOGS // 
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// FIN DIALOGS // 

//IMPORT DATAS // 
import meetingApi from '../api/meeting';
import animalApi from '../api/animal';
import salonsApi from '../api/salons';
import employeeApi from '../api/employee';
//  FIN DATAS // 


const screenHeight = window.innerHeight;


function LayoutScreen({ popUp, setPopUp, data, currentScreen, setCurrentScreen }) {


    console.log("DATAS ->");
    console.log(data);
    console.log(moment(data.datePlanned).add(data.duration, 'hours').add(data.aditionalTime, 'hours').utc().format("HH:mm"));

    const snackbar = useSnackbar();

    var getDates = function (thisDate) {
        console.log(thisDate);
        var year = thisDate.getFullYear();
        var month = thisDate.getMonth();
        var date = new Date(year, month, 1);
        console.log(year, month);
        var names = ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'];
        var result = { "": [], "lun": [], "mar": [], "mer": [], "jeu": [], "ven": [], "sam": [], "dim": [] };
        var firstDay = names[date.getDay()];
        for (var i = 0; i < names.length; i++) {
            if (firstDay == names[i] && firstDay != "dim") break;
            if (names[i] != "dim") result[names[i]].push({ "completeDate": null });
        }

        while (date.getMonth() == month) {

            var element = { "completeDate": date.toLocaleDateString('fr-FR'), 'date': date.getDate(), "day": names[date.getDay()] };
            result[element.day].push(element);
            date.setDate(date.getDate() + 1);


        }
        return result;
    }

    const cancelMeeting = async (id, sms) => {
        if (id == null) snackbar.showMessage("Un problème est servenu");
        else {
            const response = await meetingApi.cancelMeeting({ sms: sms, meetingId: id });
            if (response.ok) {
                snackbar.showMessage("Rendez-vous correctement annulé.");
                setPopUp(false);
            }
            else snackbar.showMessage("Un problème est servenu");
        }
    };

    const [listSalons, setListSalons] = useState([]);

    const loadSalons = async () => {

        const response = await salonsApi.getSalons();
        console.log("REPONSE");
        console.log(response.data.salonFound);
        if (!response.ok) {
            console.log("test");
            console.log(response.data.salonFound);
        }

        setListSalons(response.data.salonFound);
    };

    const closeMeeting = async (id, sms) => {
        if (id == null) snackbar.showMessage("Un problème est servenu");
        else {
            if ((closeFormInfos.endPrice == null || closeFormInfos.endPrice <= 0)) {
                snackbar.showMessage("Le prix payé ne peut pas être de zéro.");
            }
            else {
                const response = await meetingApi.updateMeeting({ sms: sms, meetingId: id, comments: (data.comments != null && data.comments != "" ? data.comments + " " : "") + "Fin de rdv : " + closeFormInfos.comments + " | Nouveau RDV : " + (closeFormInfos.newRdv == 1 ? "Oui" : "Non"), endPrice: closeFormInfos.endPrice, state: 1, animalComment: closeFormInfos.animalComment, prestaComment: closeFormInfos.prestaComment, customerComment: closeFormInfos.customerComment, });
                if (response.ok) {
                    snackbar.showMessage("Rendez-vous correctement terminé. Si les sms sont activés, le client recevra confirmation.");
                    if (closeFormInfos.newRdv == 1) {
                        setClosureForm(false);
                        setCurrentScreen({ type: "newMeeting", animal: { "id": data.animalId, "name": animalInfos.name, "type": animalInfos.type, "race": animalInfos.race, "Customer": { "id": animalInfos.customerId, "name": animalInfos.Customer.name, "lastName": animalInfos.Customer.lastName } } });
                    }
                    else {
                        setClosureForm(false);
                        setCloseFormInfos(emptyCloseForm);
                        setPopUp(false);
                    }


                }
                else snackbar.showMessage("Un problème est survenu");
            }
        }
    };

    const noShowMeeting = async (id) => {
        if (noShowReason == null || noShowReason == "") snackbar.showMessage("Le commentaire ne peut pas être vide.");
        else {
            if (id == null) snackbar.showMessage("Un problème est servenu");
            else {
                const response = await meetingApi.noshowMeeting({ meetingId: id, reason: noShowReason });
                if (response.ok) {
                    snackbar.showMessage("NoShow validé.");
                    setnoShowReason("");
                    setPopUp(false);
                }
                else snackbar.showMessage("Un problème est servenu");
            }
        }
    };


    const getCurrentDateString = function (type) {
        var days = ["Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche", "Lundi"];
        var months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
        var day = days[currentDate.getDay()];
        var month = months[currentDate.getMonth()];
        if (dayMonth == "day" && type == "long") {
            return day + " " + currentDate.getDate() + " " + month + " " + currentDate.getFullYear();
        }
        else {
            return month + " " + currentDate.getFullYear();
        }
    }

    const updateDates = function (type, value) {

        console.log("-------------");
        console.log(currentDate);
        console.log(dayMonth);

        if (type === "change") {
            setCurrentDate(value);
        }
        if (type == "add") {
            setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + value)));
        }
        if (type == "remove") {
            setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - value)))
        }

    }

    const [value, setValue] = useState(null);
    const [dayMonth, setDayMonth] = useState("day");
    const [currentDate, setCurrentDate] = useState(new Date());
    const [currentDateString, setCurrentDateString] = useState({ "short": getCurrentDateString("short"), "long": getCurrentDateString("long") });
    const [datePickerOpened, setDatePickerOpened] = useState(false);

    const emptyMeetingForm = { meetingId: data.id, salonId: data.salonId, datePlanned: moment(data.datePlanned), duration: data.duration, additionalTime: moment.utc(moment.duration(data.aditionalTime, "hours").as("milliseconds")).format("HH:mm"), employeeId: data.employeeId, surbooking: data.surbooking };
    const [newMeeting, setNewMeeting] = useState(emptyMeetingForm);
    const [disableForm, setDisableForm] = useState(true);

    const [closureForm, setClosureForm] = useState(false);
    const emptyCloseForm = { endPrice: 0, comments: "", newRdv: 0 };
    const [closeFormInfos, setCloseFormInfos] = useState(emptyCloseForm);

    const [noShowReason, setnoShowReason] = useState(null);
    const [disableNoShowForm, setDisableNoShowForm] = useState(true);

    var workingTime = ["8:00", "20:00"];
    const [ampm, setAmpm] = useState("day");
    const [depotList, setDepotList] = useState([
        "07:00", "07:15", "07:30", "07:45",
        "08:00", "08:15", "08:30", "08:45",
        "09:00", "09:15", "09:30", "09:45",
        "10:00", "10:15", "10:30", "10:45",
        "11:00", "11:15", "11:30", "11:45",
        "12:00", "12:15", "12:30", "12:45",
        "13:00", "13:15", "13:30", "13:45",
        "14:00", "14:15", "14:30", "14:45",
        "15:00", "15:15", "15:30", "15:45",
        "16:00", "16:15", "16:30", "16:45",
        "17:00", "17:15", "17:30", "17:45",
        "18:00", "18:15", "18:30", "18:45",
        "19:00", "19:15", "19:30", "19:45",
        "20:00"
    ]);

    const [animalInfos, setAnimalInfos] = useState([]);
    const getAnimalInfos = async () => {

        const response = await animalApi.getAnimalInfos(data.animalId);
        console.log("REPONSE");
        if (!response.ok) {
            console.log("test");
            console.log(response.data);
        }

        setAnimalInfos(response.data);


    };

    const validateForm = async (sms) => {
        console.log(newMeeting);
        if (newMeeting.meetingId == null) snackbar.showMessage("Un problème est survenu, veuillez réessayer.");
        else {
            if (newMeeting.length < 2) snackbar.showMessage("Vous n'avez appliqué aucunes modifications au rendez-vous.");
            else {
                newMeeting.sms = sms;
                newMeeting.datePlanned = (newMeeting.datePlanned ? moment(newMeeting.datePlanned).format("YYYY-MM-DD") : moment(data.datePlanned).format("YYYY-MM-DD")) + " " + (newMeeting.hour ? newMeeting.hour : moment(data.datePlanned).utc().format("HH:mm"));
                snackbar.showMessage("Modification du rendez-vous en cours.");
                const response = await meetingApi.updateMeeting(newMeeting);
                setNewMeeting(emptyMeetingForm);
                setDisableForm(true);
                setPopUp(false);
            }

        }
    };


    const loadMeetingInfos = async () => {
        const response = await meetingApi.getMeeting(data.id);
        data = response.data;
    }


    var times = function (hours) {
        var timeList = [];
        var startingHour = hours[0];
        var lastHour = hours[1];
        var date = moment("01/01/2001 " + startingHour);

        console.log(date.format("HH:mm"));

        while (date.format("HH:mm") != lastHour) {
            timeList.push(date.format("HH:mm"));
            date.add(60, "m");
        }

        timeList.push(date.format("HH:mm"))

        return timeList;
    };

    const timesList = times(workingTime);

    const [listMeetings, setListMeetings] = useState([]);

    const loadMeetings = async () => {

        if (newMeeting.salonId != null) {
            const response = await meetingApi.getMeetingsBySalon({ "salonId": newMeeting.salonId, "date": newMeeting.datePlanned.format("YYYY-MM-DD"), "period": "day" });
            console.log("REPONSE");
            console.log(response.data);
            if (!response.ok) {
                console.log("test");
                console.log(response.data);
            }

            setListMeetings(response.data);
            if(newMeeting.surbooking == 1)
            {
                
                var availableTimes = [
                    "07:00", "07:15", "07:30", "07:45",
                    "08:00", "08:15", "08:30", "08:45",
                    "09:00", "09:15", "09:30", "09:45",
                    "10:00", "10:15", "10:30", "10:45",
                    "11:00", "11:15", "11:30", "11:45",
                    "12:00", "12:15", "12:30", "12:45",
                    "13:00", "13:15", "13:30", "13:45",
                    "14:00", "14:15", "14:30", "14:45",
                    "15:00", "15:15", "15:30", "15:45",
                    "16:00", "16:15", "16:30", "16:45",
                    "17:00", "17:15", "17:30", "17:45",
                    "18:00", "18:15", "18:30", "18:45",
                    "19:00", "19:15", "19:30", "19:45",
                    "20:00"
                ];
            }
            else if (ampm == "day") {
                var availableTimes = [
                    "07:00", "07:15", "07:30", "07:45",
                    "08:00", "08:15", "08:30", "08:45",
                    "09:00", "09:15", "09:30", "09:45",
                    "10:00", "10:15", "10:30", "10:45",
                    "11:00", "11:15", "11:30", "11:45",
                    "12:00", "12:15", "12:30", "12:45",
                    "13:00", "13:15", "13:30", "13:45",
                    "14:00", "14:15", "14:30", "14:45",
                    "15:00", "15:15", "15:30", "15:45",
                    "16:00", "16:15", "16:30", "16:45",
                    "17:00", "17:15", "17:30", "17:45",
                    "18:00", "18:15", "18:30", "18:45",
                    "19:00", "19:15", "19:30", "19:45",
                    "20:00"
                ];
            }

            else if (ampm == "am") {
                var availableTimes = [
                    "07:00", "07:15", "07:30", "07:45",
                    "08:00", "08:15", "08:30", "08:45",
                    "09:00", "09:15", "09:30", "09:45",
                    "10:00", "10:15", "10:30", "10:45",
                    "11:00", "11:15", "11:30", "11:45",
                    "12:00"
                ];
            }

            else if (ampm == "pm") {
                var availableTimes = [
                    "12:00", "12:15", "12:30", "12:45",
                    "13:00", "13:15", "13:30", "13:45",
                    "14:00", "14:15", "14:30", "14:45",
                    "15:00", "15:15", "15:30", "15:45",
                    "16:00", "16:15", "16:30", "16:45",
                    "17:00", "17:15", "17:30", "17:45",
                    "18:00", "18:15", "18:30", "18:45",
                    "19:00", "19:15", "19:30", "19:45",
                    "20:00"
                ];
            }


            response.data.forEach(function (meeting, index) {
                var debut = moment(meeting.datePlanned).utc();
                var fin = moment(meeting.datePlanned).add(meeting.duration + meeting.aditionalTime, "hours").utc();
                var current = moment(meeting.datePlanned).utc();
                while (current.format("HH:mm") != fin.format("HH:mm")) {

                    if(newMeeting.surbooking == 1 && meeting.surbooking == 1)
                    {
                        availableTimes = availableTimes.filter(e => e !== current.format("HH:mm"));
                    }
                    else if (newMeeting.surbooking == 0 && newMeeting.employeeId == meeting.employeeId && meeting.surbooking == 0) {
                        availableTimes = availableTimes.filter(e => e !== current.format("HH:mm")); // will return ['A', 'C']
                    }
                    //console.log(current.format("HH:mm"));
                    current.add(15, "minutes");
                } 

            });
            var duration = newMeeting.duration + (moment.duration(newMeeting.additionalTime).asHours());
            console.log("--DUREE--")
            console.log(duration);
            availableTimes.forEach(function (depot, index) {
                var debut = moment(depot, "HH:mm");
                var fin = moment(depot, "HH:mm").add(duration, "hours").subtract(15, "minutes");
                var current = moment(depot, "HH:mm");
                var impossible = 0;
                while (current.format("HH:mm") != fin.format("HH:mm")) {
                    if (depot == "18:15") console.log(fin.format("HH:mm"));
                    if (!availableTimes.includes(current.format("HH:mm"))) impossible = 1;// will return ['A', 'C']
                    current.add(15, "minutes");
                    //console.log(current.format("HH:mm"));
                }
                if (!availableTimes.includes(current.format("HH:mm"))) impossible = 1;

                if (impossible == 1) availableTimes = availableTimes.filter(e => e !== debut.format("HH:mm"));

            });

            console.log("-- AVAILABLE --");
            console.log(availableTimes);
            setDepotList(availableTimes);

        }
    };

    const setPrestaInfos = function (id) {
        var prestaInfos = animalInfos.Prestations.find(item => item.id === id);
        setNewMeeting((prevState) => ({
            ...prevState,
            duration: prestaInfos.duration,
            price: prestaInfos.price
        }));
    }

    const hoursList = [
        "00:00", "00:15", "00:30", "00:45",
        "01:00", "01:15", "01:30", "01:45",
        "02:00"
    ];

    const [employees, setListEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState("");
    const loadEmployees = async () => {

        const response = await employeeApi.getAvailableEmployeeBySalons({ salonId: newMeeting.salonId ? newMeeting.salonId : data.salonId, date: moment(newMeeting.datePlanned ? newMeeting.datePlanned : data.datePlanned).format("YYYY-MM-DD") });
        console.log("REPONSE");
        console.log(response.data);
        if (!response.ok) {
            console.log("test");
            console.log(response.data);
        }

        var employeeList = [];

        if (response.error) {

        }
        else if (response.data != []) {
            response.data.forEach(function (item, key) {
                employeeList.push({ id: item.Employee.id, name: item.Employee.name, ampm: item.nb == 1 ? item.ampm : "day" });
            });
        }



        setListEmployees(employeeList);

    };


    const [confirmDialog, setConfirmDialog] = useState({ open: false, text: "", action: null });
    var dates = getDates(currentDate);

    useEffect(() => {
        setCurrentDateString({ "short": getCurrentDateString("short"), "long": getCurrentDateString("long") });
        loadSalons();
        loadEmployees();
        loadMeetings();
        getAnimalInfos();
    }, [currentDate, dayMonth, newMeeting]);


    const [loaded] = useFonts({
        poppins: require('../fonts/Poppins-Regular.ttf'),
        poppinsMedium: require('../fonts/Poppins-Medium.ttf'),
        poppinsSemiBold: require('../fonts/Poppins-SemiBold.ttf'),
        poppinsBold: require('../fonts/Poppins-Bold.ttf')
    });

    if (!loaded) {
        return null;
    }



    return (

        <View style={[styles.screenContainerExtraMarges, { backgroundColor: "#ffffff", margin: 50, shadowColor: "#000", shadowOffset: 0, shadowOpacity: 0.23, shadowRadius: 5 }]}>
            <Dialog
                open={confirmDialog.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirmation
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {confirmDialog.text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setConfirmDialog(false); confirmDialog.action == "noshow" ? noShowMeeting(data.id) : confirmDialog.action == "cancelMeeting" ? cancelMeeting(data.id, 1) : confirmDialog.action == "closeMeeting" ? closeMeeting(data.id, confirmDialog.sms) : null }} style={{ color: colors.blue }}>Confirmer</Button>
                    {confirmDialog.action == "cancelMeeting" ? (
                        <Button onClick={() => { setConfirmDialog(false); cancelMeeting(data.id, 0); }} style={{ color: colors.blue }}>Confirmer (sans sms)</Button>
                    ) : null}
                    <Button onClick={() => setConfirmDialog(false)} style={{ color: colors.red }}>
                        Annuler
                    </Button>
                </DialogActions>
            </Dialog>

            <Button variant="contained" onClick={() => (setPopUp(false))} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 14, backgroundColor: "transparent", color: colors.ebony, borderRadius: 7, marginBottom: 20, textTransform: 'none', width: 180, alignSelf: "flex-end", height: 50 }} endIcon={<CloseIcon />}>Fermer</Button>
            {
                !disableNoShowForm ? (
                    <View style={styles.formContainer}>
                        <View style={styles.formColumn}>
                            <View style={styles.inputRow}>
                                <View style={styles.formLabelColumn}>
                                    <Text style={styles.inputLabel}>Infos noShow</Text>
                                </View>
                                <View style={styles.formInputsColumn}>
                                    <TextField
                                        value={noShowReason ? noShowReason : ""}
                                        onChange={(value) => (setnoShowReason(value.target.value))}
                                        fullWidth label="" multiline rows={4} autoCorrect='false' placeholder='Informations noshow' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                </View>
                            </View>
                        </View>
                    </View>
                ) :
                    closureForm ? (
                        <View style={styles.formContainer}>
                            <View style={styles.formColumn}>
                                <View style={styles.inputRow}>
                                    <View style={styles.formLabelColumn}>
                                        <Text style={styles.inputLabel}>Prix payé</Text>
                                    </View>
                                    <View style={styles.formInputsColumn}>
                                        <TextField
                                            value={closeFormInfos.endPrice ? closeFormInfos.endPrice : ""}
                                            onChange={(value) => (setCloseFormInfos((prevState) => ({
                                                ...prevState,
                                                endPrice: value.target.value
                                            })))}
                                            endAdornment={<InputAdornment position="end">€</InputAdornment>}
                                            fullWidth label="" type="number" min autoCorrect='false' placeholder='Prix de la prestation' variant="standard" InputProps={{ disableUnderline: true, inputProps: { min: 1 } }} InputLabelProps={{ shrink: false }} />
                                    </View>
                                </View>
                                <View style={styles.inputRow}>
                                    <View style={styles.formLabelColumn}>
                                        <Text style={styles.inputLabel}>Commentaire fin RDV</Text>
                                    </View>
                                    <View style={styles.formInputsColumn}>
                                        <TextField
                                            value={closeFormInfos.comments ? closeFormInfos.comments : ""}
                                            onChange={(value) => (setCloseFormInfos((prevState) => ({
                                                ...prevState,
                                                comments: value.target.value
                                            })))}
                                            fullWidth label="" multiline rows={4} autoCorrect='false' placeholder='Commentaire de fin de rdv, visible sur la fiche du rendez vous' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                    </View>
                                </View>
                                <View style={styles.inputRow}>
                                    <View style={styles.formLabelColumn}>
                                        <Text style={styles.inputLabel}>Modification Infos Animal</Text>
                                    </View>
                                    <View style={styles.formInputsColumn}>
                                        <TextField
                                            value={closeFormInfos.animalComment ? closeFormInfos.animalComment : data.Animal.comments}
                                            onChange={(value) => (setCloseFormInfos((prevState) => ({
                                                ...prevState,
                                                animalComment: value.target.value
                                            })))}
                                            fullWidth label="" multiline rows={4} autoCorrect='false' placeholder="Modification des infos de l'animal, visible sur la fiche de l'animal" variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                    </View>
                                </View>
                                <View style={styles.inputRow}>
                                    <View style={styles.formLabelColumn}>
                                        <Text style={styles.inputLabel}>Modification Infos Prestation</Text>
                                    </View>
                                    <View style={styles.formInputsColumn}>
                                        <TextField
                                            value={closeFormInfos.prestaComment ? closeFormInfos.prestaComment : data.Prestation.comments}
                                            onChange={(value) => (setCloseFormInfos((prevState) => ({
                                                ...prevState,
                                                prestaComment: value.target.value
                                            })))}
                                            fullWidth label="" multiline rows={4} autoCorrect='false' placeholder="Modification des infos de la prestation, visible sur la fiche prestation" variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                    </View>
                                </View>
                                <View style={styles.inputRow}>
                                    <View style={styles.formLabelColumn}>
                                        <Text style={styles.inputLabel}>Modification Infos Client</Text>
                                    </View>
                                    <View style={styles.formInputsColumn}>
                                        <TextField
                                            value={closeFormInfos.customerComment ? closeFormInfos.customerComment : data.Customer.infos}
                                            onChange={(value) => (setCloseFormInfos((prevState) => ({
                                                ...prevState,
                                                customerComment: value.target.value
                                            })))}
                                            fullWidth label="" multiline rows={4} autoCorrect='false' placeholder="Modification des infos du client, visible sur la fiche client" variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                    </View>
                                </View>
                                <View style={styles.inputRow}>
                                    <View style={styles.formLabelColumn}>
                                        <Text style={styles.inputLabel}>Nouveau RDV ?</Text>
                                    </View>
                                    <View style={styles.formInputsColumn}>
                                        <Select
                                            fullWidth
                                            sx={{
                                                "& fieldset": { border: 'none' },
                                            }}
                                            value={closeFormInfos.newRdv}
                                            disableUnderline={true}
                                            displayEmpty
                                            IconComponent={(props) => (
                                                <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                            )}
                                            onChange={(value) => (setCloseFormInfos((prevState) => ({
                                                ...prevState,
                                                newRdv: value.target.value
                                            })))}
                                        >
                                            <MenuItem value={0}>Non</MenuItem>
                                            <MenuItem value={1}>Oui</MenuItem>
                                        </Select>
                                    </View>
                                </View>
                            </View>
                        </View>
                    ) :

                        disableForm ? (
                            <View style={styles.formContainer}>
                                <View style={styles.formColumn}>
                                    <View style={styles.inputRow}>
                                        <View style={styles.formLabelColumn}>
                                            <Text style={styles.inputLabel}>Etat</Text>
                                        </View>
                                        <View style={styles.formInputsColumn}>
                                            <TextField

                                                fullWidth label="" value={data.state == 0 ? "A venir" : data.state == 1 ? "Terminé" : data.state == 2 && data.noShow == 0 ? "Annulé" : data.state == 2 && data.noShow == 1 ? "NOSHOW" : ""} autoCorrect='false' placeholder='Etat' disabled variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                        </View>
                                    </View>
                                    <View style={styles.inputRow}>
                                        <View style={styles.formLabelColumn}>
                                            <Text style={styles.inputLabel}>Nom client</Text>
                                        </View>
                                        <View style={styles.formInputsColumn}>
                                            <TextField fullWidth value={data.Customer.lastName + " " + data.Customer.name} label="" autoCorrect='false' placeholder='Client' disabled variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                        </View>
                                    </View>
                                    <View style={styles.inputRow}>
                                        <View style={styles.formLabelColumn}>
                                            <Text style={styles.inputLabel}>Animal</Text>
                                        </View>
                                        <View style={styles.formInputsColumn}>
                                            <TextField fullWidth value={data.Animal.name} label="" autoCorrect='false' placeholder='Animal' disabled variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                        </View>
                                    </View>
                                    <View style={styles.inputRow}>
                                        <View style={styles.formLabelColumn}>
                                            <Text style={styles.inputLabel}>Race</Text>
                                        </View>
                                        <View style={styles.formInputsColumn}>
                                            <TextField fullWidth value={data.Animal.race} label="" autoCorrect='false' placeholder='Race' disabled variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                        </View>
                                    </View>
                                    <View style={styles.inputRow}>
                                        <View style={styles.formLabelColumn}>
                                            <Text style={styles.inputLabel}>Affectation</Text>
                                        </View>
                                        <View style={styles.formInputsColumn}>
                                            <TextField fullWidth value={data.Employee.name} label="" autoCorrect='false' placeholder='Race' disabled variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                        </View>
                                    </View>
                                    <View style={styles.inputRow}>
                                        <View style={styles.formLabelColumn}>
                                            <Text style={styles.inputLabel}>Heure rdv</Text>
                                        </View>
                                        <View style={styles.formInputsColumn}>
                                            <TextField fullWidth value={moment(data.datePlanned).utc().format("HH:mm")} label="" autoCorrect='false' placeholder='00:00' disabled variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                        </View>
                                    </View>
                                    <View style={styles.inputRow}>
                                        <View style={styles.formLabelColumn}>
                                            <Text style={styles.inputLabel}>Fin rdv</Text>
                                        </View>
                                        <View style={styles.formInputsColumn}>
                                            <TextField fullWidth value={moment(data.datePlanned).add(data.duration, 'hours').add(data.aditionalTime, 'hours').utc().format("HH:mm")} label="" autoCorrect='false' placeholder='Client' disabled variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                        </View>
                                    </View>
                                    <View style={[styles.inputRow, {textAlign: "right"}]}>
                                        <Text>Dont temps supplémentaire {moment.utc(moment.duration(data.aditionalTime, "hours").as("milliseconds")).format("HH:mm")}</Text>
                                    </View>
                                    <View style={styles.inputRow}>
                                        <View style={styles.formLabelColumn}>
                                            <Text style={styles.inputLabel}>Surbooking</Text>
                                        </View>
                                        <View style={styles.formInputsColumn}>
                                            <TextField fullWidth value={data.surbooking == 1 ? "Oui" : "Non"} label="" autoCorrect='false' placeholder='Client' disabled variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                        </View>
                                    </View>
                                    <View style={styles.inputRow}>
                                        <View style={styles.formLabelColumn}>
                                            <Text style={styles.inputLabel}>Rdv pris le</Text>
                                        </View>
                                        <View style={styles.formInputsColumn}>
                                            <TextField fullWidth value={new Date(data.createdAt).toLocaleDateString('fr-FR')} label="" autoCorrect='false' placeholder='00:00' disabled variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                        </View>
                                    </View>
                                    <View style={styles.inputRow}>
                                        <View style={styles.formLabelColumn}>
                                            <Text style={styles.inputLabel}>Salon</Text>
                                        </View>
                                        <View style={styles.formInputsColumn}>
                                            <TextField fullWidth value={data.User.username} label="" autoCorrect='false' placeholder='00:00' disabled variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                        </View>
                                    </View>
                                    {
                                        data.createdAt != data.updatedAt ? (
                                            <View style={styles.inputRow}>
                                                <View style={styles.formLabelColumn}>
                                                    <Text style={styles.inputLabel}>Modification</Text>
                                                </View>
                                                <View style={styles.formInputsColumn}>
                                                    <TextField fullWidth value={new Date(data.updatedAt).toLocaleDateString('fr-FR') + " (" + data.User.username + ")"} label="" autoCorrect='false' placeholder='00:00' disabled variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                                </View>
                                            </View>
                                        ) : null
                                    }

                                </View>
                                <View style={[styles.formColumn, { marginLeft: 50 }]}>
                                    <View style={styles.inputRow}>
                                        <View style={styles.formLabelColumn}>
                                            <Text style={styles.inputLabel}>Prestation</Text>
                                        </View>
                                        <View style={styles.formInputsColumn}>
                                            <TextField fullWidth value={data.Prestation.name} label="" autoCorrect='false' placeholder='Tel' disabled variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                        </View>
                                    </View>
                                    <View style={styles.inputRow}>
                                        <View style={styles.formLabelColumn}>
                                            <Text style={styles.inputLabel}>Tarif</Text>
                                        </View>
                                        <View style={styles.formInputsColumn}>
                                            <TextField fullWidth value={data.price + " €"} label="" autoCorrect='false' placeholder='Tel' disabled variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                        </View>
                                    </View>
                                    <View style={styles.inputRow}>
                                        <View style={styles.formLabelColumn}>
                                            <Text style={styles.inputLabel}>Remise</Text>
                                        </View>
                                        <View style={styles.formInputsColumn}>
                                            <TextField fullWidth value={(data.Animal.remise*100) + " %"} label="" autoCorrect='false' placeholder='Tel' disabled variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                        </View>
                                    </View>
                                    <View style={styles.inputRow}>
                                        <View style={styles.formLabelColumn}>
                                            <Text style={styles.inputLabel}>Tel port.</Text>
                                        </View>
                                        <View style={styles.formInputsColumn}>
                                            <TextField fullWidth value={data.Customer.tel1} label="" autoCorrect='false' placeholder='Téléphone 1' disabled variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                        </View>
                                    </View>
                                    <View style={styles.inputRow}>
                                        <View style={styles.formLabelColumn}>
                                            <Text style={styles.inputLabel}>Tel</Text>
                                        </View>
                                        <View style={styles.formInputsColumn}>
                                            <TextField fullWidth value={data.Customer.tel2} label="" autoCorrect='false' placeholder='Tel' disabled variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                        </View>
                                    </View>
                                    <View style={styles.inputRow}>
                                        <View style={styles.formLabelColumn}>
                                            <Text style={styles.inputLabel}>Infos rendez-vous</Text>
                                        </View>
                                        <View style={styles.formInputsColumn}>
                                            <TextField fullWidth value={data.comments} label="" multiline rows={4} autoCorrect='false' disabled placeholder='Informations rdv' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                        </View>
                                    </View>
                                    <View style={styles.inputRow}>
                                        <View style={styles.formLabelColumn}>
                                            <Text style={styles.inputLabel}>Infos presta / animal</Text>
                                        </View>
                                        <View style={styles.formInputsColumn}>
                                            <TextField fullWidth value={(data.Prestation.comments != null ? data.Prestation.comments + "\n" : "") + (data.Animal.comments != null ? data.Animal.comments : "")} label="" multiline rows={3} autoCorrect='false' disabled placeholder='Informations prestation' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                        </View>
                                    </View>
                                    <View style={styles.inputRow}>
                                        <View style={styles.formLabelColumn}>
                                            <Text style={styles.inputLabel}>Infos client</Text>
                                        </View>
                                        <View style={styles.formInputsColumn}>
                                            <TextField fullWidth value={data.Customer.infos} label="" multiline rows={2} autoCorrect='false' disabled placeholder='Informations client' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                        </View>
                                    </View>
                                </View>
                            </View>
                        ) : (
                            <View style={styles.formContainer}>
                                <View style={styles.formColumn}>
                                    <Fragment>
                                        <View style={styles.inputRow}>
                                            <View style={styles.formLabelColumn}>
                                                <Text style={styles.inputLabel}>Nom client</Text>
                                            </View>
                                            <View style={styles.formInputsColumn}>
                                                <TextField fullWidth value={data.Customer.lastName + " " + data.Customer.name} label="" autoCorrect='false' placeholder='Client' disabled variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                            </View>
                                        </View>
                                        <View style={styles.inputRow}>
                                            <View style={styles.formLabelColumn}>
                                                <Text style={styles.inputLabel}>Prestation</Text>
                                            </View>
                                            <View style={styles.formInputsColumn}>
                                                <Select
                                                    fullWidth
                                                    sx={{
                                                        "& fieldset": { border: 'none' },
                                                    }}
                                                    value={newMeeting.prestationId ? newMeeting.prestationId : data.prestationId}
                                                    onChange={(value) => {
                                                        setNewMeeting((prevState) => ({
                                                            ...prevState,
                                                            prestationId: value.target.value
                                                        })); setPrestaInfos(value.target.value)
                                                    }}
                                                    disableUnderline={true}
                                                    displayEmpty
                                                    IconComponent={(props) => (
                                                        <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                                    )}
                                                >
                                                    {animalInfos != null && animalInfos.Prestations !== undefined ? animalInfos.Prestations.map((item, key) => (
                                                        <MenuItem value={item.id}>{item.name} (Durée : {moment.utc(moment.duration(item.duration, "hours").as('milliseconds')).format('HH:mm')}H)</MenuItem>
                                                    )) : null}

                                                </Select>
                                            </View>
                                        </View>
                                        <View style={styles.inputRow}>
                                            <View style={styles.formLabelColumn}>
                                                <Text style={styles.inputLabel}>Salon</Text>
                                            </View>
                                            <View style={styles.formInputsColumn}>
                                                <Select
                                                    fullWidth
                                                    sx={{
                                                        "& fieldset": { border: 'none' },
                                                    }}
                                                    value={newMeeting.salonId ? newMeeting.salonId : data.salonId}
                                                    onChange={(value) => (setNewMeeting((prevState) => ({
                                                        ...prevState,
                                                        salonId: value.target.value
                                                    })))}
                                                    disableUnderline={true}
                                                    displayEmpty
                                                    IconComponent={(props) => (
                                                        <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                                    )}
                                                >
                                                    {listSalons.map((item, key) => (
                                                        <MenuItem value={item.id}>{item.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </View>
                                        </View>
                                        <Fragment>
                                            <View style={styles.inputRow}>
                                                <View style={styles.formLabelColumn}>
                                                    <Text style={styles.inputLabel}>Date</Text>
                                                </View>
                                                <View style={styles.formInputsColumn}>
                                                    <DesktopDatePicker
                                                        fullWidth
                                                        sx={{ width: "100%" }}
                                                        label=""
                                                        variant="standard"
                                                        placeholder="Date"
                                                        inputFormat="DD/MM/YYYY"
                                                        value={newMeeting.datePlanned ? newMeeting.datePlanned : data.datePlanned}
                                                        onChange={(value) => {
                                                            (setNewMeeting((prevState) => ({
                                                                ...prevState,
                                                                datePlanned: value
                                                            })));
                                                        }}
                                                        renderInput={(params) => <TextField sx={{ width: "100%", backgroundColor: colors.smokedWhite, paddingLeft: "30px", borderRadius: 25, border: "none", "& fieldset": { border: "none" } }} {...params} />}
                                                    />
                                                </View>

                                            </View>
                                            <View style={styles.inputRow}>
                                                <View style={styles.formLabelColumn}>
                                                    <Text style={styles.inputLabel}>Affectation</Text>
                                                </View>
                                                <View style={styles.formInputsColumn}>
                                                    <Select
                                                        fullWidth
                                                        sx={{
                                                            "& fieldset": { border: 'none' },
                                                        }}
                                                        placeholder={data.Employee.name}
                                                        value={selectedEmployee != "" ? selectedEmployee : "none"}
                                                        onChange={(value) => {
                                                            (setNewMeeting((prevState) => ({
                                                                ...prevState,
                                                                employeeId: JSON.parse(value.target.value).id
                                                            }))); setAmpm(JSON.parse(value.target.value).ampm); setSelectedEmployee(value.target.value)
                                                        }}
                                                        disableUnderline={true}
                                                        displayEmpty
                                                        IconComponent={(props) => (
                                                            <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                                        )}
                                                    >
                                                        <MenuItem value="none" disabled>
                                                            {data.Employee.name}
                                                        </MenuItem>
                                                        {
                                                            employees != [] ? employees.map((item, key) => (

                                                                <MenuItem value={JSON.stringify({ id: item.id, ampm: item.ampm })}>{item.name}</MenuItem>
                                                            )) : null
                                                        }
                                                    </Select>
                                                </View>
                                            </View>
                                            <View style={styles.inputRow}>
                                                <View style={styles.formLabelColumn}>
                                                    <Text style={styles.inputLabel}>Surbooking</Text>
                                                </View>
                                                <View style={styles.formInputsColumn}>
                                                    <Select
                                                        fullWidth
                                                        sx={{
                                                            "& fieldset": { border: 'none' },
                                                        }}
                                                        value={newMeeting.surbooking}
                                                        onChange={(value) => {
                                                            (setNewMeeting((prevState) => ({
                                                                ...prevState,
                                                                surbooking: value.target.value
                                                            })));
                                                        }}
                                                        disableUnderline={true}
                                                        displayEmpty
                                                        IconComponent={(props) => (
                                                            <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                                        )}
                                                    >
                                                        <MenuItem value={0}>Non</MenuItem>
                                                        <MenuItem value={1}>Oui</MenuItem>
                                                    </Select>
                                                </View>
                                            </View>
                                        </Fragment>

                                    </Fragment>
                                </View>
                                <View style={[styles.formColumn, { marginLeft: 50 }]}>
                                    <View style={styles.inputRow}>
                                        <View style={styles.formLabelColumn}>
                                            <Text style={styles.inputLabel}>Animal</Text>
                                        </View>
                                        <View style={styles.formInputsColumn}>
                                            <TextField fullWidth value={data.Animal.name} label="" autoCorrect='false' placeholder='Animal' disabled variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                        </View>
                                    </View>
                                    <View style={styles.inputRow}>
                                        <View style={styles.formLabelColumn}>
                                            <Text style={styles.inputLabel}>Heure dépot</Text>
                                        </View>
                                        <View style={styles.formInputsColumn}>
                                            <Select
                                                fullWidth
                                                sx={{
                                                    "& fieldset": { border: 'none' },
                                                }}
                                                value={newMeeting.hour ? newMeeting.hour : "none"}
                                                onChange={(value) => (setNewMeeting((prevState) => ({
                                                    ...prevState,
                                                    hour: value.target.value
                                                })))}
                                                disableUnderline={true}
                                                displayEmpty
                                                IconComponent={(props) => (
                                                    <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                                )}

                                            >
                                                <MenuItem value="none" disabled>{moment(data.datePlanned).utc().format("HH:mm")}</MenuItem>
                                                {depotList.map((item, key) => (
                                                    <MenuItem value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>

                                        </View>
                                    </View>
                                    <View style={styles.inputRow}>
                                        <View style={styles.formLabelColumn}>
                                            <Text style={styles.inputLabel}>Temps sup.</Text>
                                        </View>
                                        <View style={styles.formInputsColumn}>
                                            <Select
                                                fullWidth
                                                sx={{
                                                    "& fieldset": { border: 'none' },
                                                }}
                                                value={newMeeting.additionalTime ? newMeeting.additionalTime : "00:00"}
                                                onChange={(value) => 
                                                    {
                                                        setNewMeeting((prevState) => ({
                                                            ...prevState,
                                                            additionalTime: value.target.value
                                                        }));
                                                        console.log("value "+value.target.value);
                                                    }
                                                }
                                                /*
                                                
                                                */
                                                disableUnderline={true}
                                                displayEmpty
                                                IconComponent={(props) => (
                                                    <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                                )}

                                            >
                                                <MenuItem value="none" disabled>{moment.utc(moment.duration(data.aditionalTime, "hours").as("milliseconds")).format("HH:mm")}</MenuItem>
                                                {hoursList.map((item, key) => (
                                                    <MenuItem value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>

                                        </View>
                                    </View>

                                    <View style={styles.inputRow}>
                                        <View style={styles.formLabelColumn}>
                                            <Text style={styles.inputLabel}>Infos rendez-vous</Text>
                                        </View>
                                        <View style={styles.formInputsColumn}>
                                            <TextField
                                                value={newMeeting.comments ? newMeeting.comments : data.comments}
                                                onChange={(value) => (setNewMeeting((prevState) => ({
                                                    ...prevState,
                                                    comments: value.target.value
                                                })))}
                                                fullWidth label="" multiline rows={4} autoCorrect='false' placeholder='Informations complémentaires' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                                        </View>
                                    </View>
                                </View>
                            </View>
                        )}



            <View style={styles.centeredControllers}>

                {
                    !disableNoShowForm ? (
                        <Fragment>
                            <Button variant="contained" onClick={() => { setConfirmDialog({ open: true, text: "Veuillez confirmer le noshow pour le rendez-vous sélectionné.", action: "noshow" }) }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, fontSize: 12, backgroundColor: colors.blue, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 300, alignSelf: "center", height: 50 }}>VALIDER LE NOSHOW</Button>
                            <Button variant="contained" onClick={() => { setnoShowReason(""); setDisableNoShowForm(true); snackbar.showMessage('Formulaire réinitialisé.') }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, fontSize: 12, backgroundColor: colors.red, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 300, alignSelf: "center", height: 50 }}>ANNULER LE NOSHOW</Button>
                        </Fragment>
                    ) :
                        disableForm && !closureForm ?

                            (<Fragment>
                                {
                                    data.state == 0 ?
                                        (
                                            <Fragment>
                                                <Button variant="contained" onClick={() => { setDisableNoShowForm(false) }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, fontSize: 12, backgroundColor: colors.cashmere, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 180, alignSelf: "center", height: 50 }}>NO SHOW</Button>
                                                <Button variant="contained" onClick={() => { setClosureForm(true); }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, fontSize: 12, backgroundColor: colors.lightBlue, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 180, alignSelf: "center", height: 50 }}>VALIDER RDV</Button>
                                                <Button variant="contained" onClick={() => { setDisableForm(false) }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, fontSize: 12, backgroundColor: colors.blue, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 180, alignSelf: "center", height: 50 }}>MODIFIER</Button>
                                                <Button variant="contained" onClick={() => { setConfirmDialog({ open: true, text: "Veuillez confirmer l'annulation du rendez-vous sélectionné.", action: "cancelMeeting" }) }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: colors.red, color: "#FFFFFF", borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 180, alignSelf: "center", height: 50 }}>ANNULER RDV</Button>
                                            </Fragment>
                                        )
                                        :
                                        null
                                }
                            </Fragment>)


                            : !closureForm ?
                                (<Fragment>
                                    <Button variant="contained" onClick={() => { validateForm(1) }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, fontSize: 12, backgroundColor: colors.blue, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 300, alignSelf: "center", height: 50 }}>VALIDER LES MODIFICATIONS</Button>
                                    <Button variant="contained" onClick={() => { validateForm(0) }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, fontSize: 12, backgroundColor: colors.blue, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 300, alignSelf: "center", height: 50 }}>VALIDER LES MODIFICATIONS SANS SMS</Button>
                                    <Button variant="contained" onClick={() => { setNewMeeting(emptyMeetingForm); setDisableForm(true); snackbar.showMessage('Formulaire réinitialisé.') }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, fontSize: 12, backgroundColor: colors.red, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 300, alignSelf: "center", height: 50 }}>ANNULER LES MODIFICATIONS</Button>
                                </Fragment>)
                                : (
                                    <Fragment>
                                        <Button variant="contained" onClick={() => { setConfirmDialog({ open: true, text: "Veuillez confirmer la validation du rendez-vous avec SMS.", action: "closeMeeting", sms: 1 }) }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, fontSize: 12, backgroundColor: colors.lightBlue, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 180, alignSelf: "center", height: 50 }}>VALIDER PAR SMS</Button>
                                        <Button variant="contained" onClick={() => { setConfirmDialog({ open: true, text: "Veuillez confirmer la validation du rendez-vous sans SMS.", action: "closeMeeting", sms: 0 }) }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, fontSize: 12, backgroundColor: colors.lightBlue, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 180, alignSelf: "center", height: 50 }}>VALIDER SANS SMS</Button>
                                        <Button variant="contained" onClick={() => { setCloseFormInfos(emptyCloseForm); setClosureForm(false); snackbar.showMessage('Formulaire réinitialisé.') }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, fontSize: 12, backgroundColor: colors.red, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 180, alignSelf: "center", height: 50 }}>ANNULER VALIDATION</Button>
                                    </Fragment>
                                )
                }



            </View>
        </View>
    );
}

export default LayoutScreen;


const styles = StyleSheet.create({

    body: {
        backgroundColor: colors.lightBlue
    },

    mainView: {
        backgroundColor: colors.smokedWhite,
        flex: 1,
    },

    //FORM

    formContainer: {
        flex: 1,
        flexDirection: "row",
        marginRight: 50,
        marginLeft: 50,
        marginBottom: 50

    },

    formColumn: {
        flex: 1,
        flexDirection: "column",
        width: "auto",

    },

    formLabelColumn: {
        flex: 1,
        flexDirection: "row",
        paddingTop: 20,

    },

    formInputsColumn: {
        width: "70%",
        flexDirection: "row",
        paddingLeft: 20,
        paddingRight: 20
    },

    formInputsGroup: {
        flexDirection: "row",
        flex: 1,
        justifyContent: "space-evenly"
    },

    inputRow: {
        flexDirection: "row",
        marginBottom: 20,
    },

    inputLabel: {
        fontFamily: "poppinsSemiBold",
        color: colors.ebony,
        lineHeight: "100%",
        height: "100%",
    },

    separator: {
        height: 2,
        backgroundColor: colors.ebony,
        width: "100%",
        marginBottom: 50
    },

    // END FORM



    monthPicker: {
        borderColor: "#E2E2EA"
    },

    customSearchInputContainer: {
        borderColor: colors.ebony,
        borderWidth: 1,
        width: 400,
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        flexDirection: "row",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 5,
        paddingBottom: 5,
        borderRadius: 25,

    },

    customSearchInput: {
        borderWidth: 0,
        borderColor: "transparent",
        flex: 1,
        height: "100%",
        width: "auto",
        fontFamily: "poppinsSemiBold",
        fontWeight: "bold",
        fontSize: 14,
        color: colors.ebony,
        outlineColor: 'transparent'

    },

    eventText: {
        flex: 1,
        flexDirection: "row",
        wdith: "100%",
        marginBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: "rgba(0, 0, 0, 0.1)",
        paddingTop: 5,
        paddingBottom: 5
    },

    eventTextTitle: {
        fontFamily: "poppinsSemiBold",
        marginRight: 10
    },

    eventGrid: {
        flex: 1,
        width: "100%",
        flexDirection: "row",
        top: 0,
        position: "absolute"

    },

    eventRow: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },

    eventColumn: {
        flex: 1,
        flexDirection: "column",
        paddingLeft: 5,
        paddingRight: 5
    },

    eventHeader: {
        height: 40,
        borderRadius: 25,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.yellow,
        marginBottom: 20
    },

    eventHeaderTitle: {
        fontFamily: "poppinsSemiBold",
        fontSize: 16
    },

    eventContent: {
        flex: 1,
        flexDirection: "column",
    },

    eventBox: {
        backgroundColor: colors.egg,
        borderWidth: 4,
        borderColor: colors.yellow,
        borderRadius: 10,
        height: 100,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20
    },

    eventButtons: {
        flex: 1,
        flexDirection: "row",
    },

    hourText: {
        textAlign: "center",
        width: 60,
        fontFamily: "poppinsSemiBold"
    },

    hoursGrid: {
        marginLeft: 10,
        marginRight: 10,
        paddingTop: 60,
        position: "relative",
    },

    hourRow: {
        height: 100,
        borderBottomWidth: 1,
        borderColor: "#e0e0e0",
        justifyContent: "center",

    },

    screenControllers: {
        marginBottom: 40,
        alignContent: "center",
        justifyContent: "center",
        textAlign: "center"
    },

    centeredControllers:
    {
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center",
        marginBottom: 20,
        paddingLeft: 50,
        paddingRight: 50
    },


    controllerTitle: {
        fontSize: 12,
        fontFamily: "poppinsSemiBold",
        textAlignVertical: "center",
        marginTop: 8,
        marginRight: 10
    },

    buttonGroup:
    {
        flexDirection: "row",
        borderRadius: 7,
        padding: 2,
        backgroundColor: colors.milk
    },

    anchorRightControllers: {
        flexDirection: "row",
        position: "absolute",
        right: 15,
    },

    monthCalendar: {
        width: "100%",
        flex: 1,
        zIndex: 50,
        flexDirection: "row"
    },

    periodLabel: {
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center"
    },

    employeeDay: {
        flexDirection: "column",
        flex: 1,
        marginBottom: 2,
    },

    gridEmployeeName: {
        flex: 1
    },

    gridEmployeeLabel:
    {
        fontFamily: "poppinsSemiBold",
        color: colors.ebony
    },

    gridBox: {
        width: "100%",
        flex: 1,
        alignItems: "center"
    },

    gridBorder: {
        borderWidth: 0.5,
        borderColor: colors.charcoal
    },

    dayColumn:
    {
        flex: 1,
    },

    dayTitle:
    {
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: 50,
        backgroundColor: colors.smokedWhite,
        marginBottom: 20
    },

    dateBox:
    {
        height: 100,
        borderWidth: 1,
        borderColor: colors.charcoal,
        paddingLeft: 10,
        paddingRight: 10,
    },

    dayNumber: {
        paddingLeft: 10,
        fontFamily: "poppins",
        color: colors.mist,
        fontSize: 12,
        paddingTop: 10,
        paddingBttom: 10
    },

    navbar: {
        height: 75,
        width: "100%",
        backgroundColor: "#FFFFFF",
        borderBottomColor: "#E2E2EA",
        borderBottomWidth: 2,
        flexDirection: "row",
        position: "fixed",
        zIndex: 10
    },

    switchScreen: {
        backgroundColor: colors.ebony,
        width: "100%",
        height: 50,
        alignItems: "center",
        justifyContent: "center"
    },

    screenContainer: {
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        width: "100%",
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 20,
        paddingBottom: 20,
        flex: 1
    },

    screenContainerExtraMarges: {
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        width: "100%",
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 50,
        paddingBottom: 50,
        flex: 1
    },

    categoryButton: {
        backgroundColor: colors.linen,
        width: "100%",
        height: 50,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 0
    },

    logoContainer: {
        width: 225,
        height: "100%",
        padding: 5,
        alignItems: "center",
        borderRightColor: "#E2E2EA",
        borderRightWidth: 1
    },

    shortcutContainer: {
        flex: 1,
        flexDirection: "row",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10
    },

    searchContainer: {
        flex: 1
    },

    screenTitle: {
        borderRadius: 5,
        width: "100%",
        height: 50,
        backgroundColor: colors.linen,
        marginBottom: 10,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },

    titleSeparator: {
        marginLeft: 20,
        marginRight: 20,
        fontSize: 20,
        color: "#D8D8D8"
    },

    pageAction: {
        fontSize: 18,
        fontWeight: "600",
        color: colors.mist
    },

    pageTitle: {
        fontFamily: "poppinsSemiBold",
        textTransform: "uppercase",
        fontWeight: "bold",
        fontSize: 20,
        color: colors.ebony
    },

    searchInput: {
        width: 300
    },

    mainContainer: {
        flex: 1,
        flexDirection: "row"
    },

    aside: {
        paddingTop: 20,
        marginTop: 75,
        width: 225,
        height: screenHeight - 75,
        backgroundColor: colors.snow,
        borderRightColor: "#E2E2EA",
        borderRightWidth: 1,
        position: "fixed",
        zIndex: 10
    },

    asideContainer: {
        flex: 1,
    },

});
