import { StatusBar } from 'expo-status-bar';
import { useFonts } from 'expo-font';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import React, { useEffect, useState, useContext } from 'react';

import DataTable from 'react-data-table-component-with-filter';
import companyApi from '../api/company';
import { useToast } from "react-native-toast-notifications";
import authApi from '../api/auth';
import AuthContext from '../auth/context';
import jwtDecode from 'jwt-decode';
import authStorage from '../auth/storage';
import { Button, Input, TextField } from '@mui/material';
import colors from '../utils/colors';
import { useSnackbar } from 'material-ui-snackbar-provider';



function LoginScreen({ route, navigation }) {

    const snackbar = useSnackbar();

    const toast = useToast();
    const authContext = useContext(AuthContext);

    const [secured, setSecured] = useState(true);

    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);



    const handleLogin = async () => {
        if ((username != "" && username != null) && (password != "" && password != null)) {
            console.log('values');
            console.log({ username, password });
            const result = await authApi.login(username, password);
            console.log(result.data);
            if (!result.ok) return snackbar.showMessage("Identifiants éronnés");
            const user = jwtDecode(result.data.token);
            authContext.setUser(result.data);
            authStorage.storeToken(result.data);
            console.log("RESULTAT");
            console.log(result.data);
            snackbar.showMessage("Connexion réussie");
        }
        else {
            snackbar.showMessage("Les champs ne peuvent pas être vides.");
        }
    }

    return (
        <View style={styles.mainView}>
            <View style={styles.container}>
                <Image style={styles.logo} source={require("../../assets/logo-dog-model.png")} resizeMode="contain" ></Image>
                <TextField onChange={(value) => (setUsername(value.target.value))} fullWidth sx={{ marginBottom: "20px" }} label="" autoCorrect='false' value={username} placeholder='Identifiant (salon)' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                <TextField onKeyDown={(e) => {
                                if (e.keyCode == 13) {
                                    handleLogin();
                                }}} onChange={(value) => (setPassword(value.target.value))} fullWidth sx={{ marginBottom: "20px" }} type="password" label="" autoCorrect='false' value={password} placeholder='Mot de passe' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                <Button onClick={() => handleLogin()} variant="contained" style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: colors.blue, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 180, alignSelf: "center", height: 50 }}>CONNEXION</Button>
            </View>
        </View >

    );
}

export default LoginScreen;

const styles = StyleSheet.create({

    input: {
        marginBottom: 20,
        borderRadius: 100,
        backgroundColor: "#F8F4EF",
    },

    square: {
        width: 50,
        height: 50,
    },

    navTitle:
    {
        fontFamily: "Brandon Bold"
    },

    logo: {
        width: "90%",
        minHeight: 200,
        marginBottom: 20,
        alignSelf: "center"
    },

    mainView: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#F8F4EF"
    },

    container: {
        width: 500,
        backgroundColor: "white",
        borderRadius: 10,
        padding: 20,
    },

    buttonContainer:
    {
        backgroundColor: "black",
        borderRadius: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5
    },


    buttonText:
    {
        fontWeight: "bold",
        color: "white",
        justifyContent: "center",
        textAlign: "center",
        width: "100%"
    },

});
