import client from './client';

const newAnimal = (datas) => client.post("/newAnimal", datas);
const updateAnimal = (datas) => client.post("/updateAnimal", datas);
const getAnimalList = (searchQuery) => client.get("/getAnimalList?searchQuery=" + searchQuery);
const getAnimalByCustomer = (customerId) => client.get("/getAnimalByCustomer?customerId=" + customerId);
const getAnimalInfos = (animalId) => client.get("/getAnimalInfos?animalId=" + animalId)
/*const getAllInterventions = () => client.get(endpoint + 'getAllInterventions');
const updateIntervention = (datas) => client.put(endpoint + "updateIntervention", datas);
const getPOS = () => client.get("pointOfSale/getAllPointOfSales");
const getSellerPOS = () => client.get("pointOfSale/getSellerPos");
const newIntervention = (datas) => client.post(endpoint + "createIntervention", datas);
const generatePDF = (datas) => client.put(endpoint + "generateRapport2", datas);
const downloadPDF = (name) => client.get(endpoint+"downloadPDF/"+name);*/

export default
    {
        newAnimal, getAnimalList, getAnimalByCustomer, getAnimalInfos, updateAnimal
    }