import * as React from "react"
import Svg, { Path } from "react-native-svg"

const CalendarIcon = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" {...props}>
    <Path
      d="M30.6 36H5.4C2.42 36 0 33.58 0 30.6V6.66c0-1.89 1.53-3.42 3.42-3.42H8.1V1.8C8.1.81 8.91 0 9.9 0s1.8.81 1.8 1.8v1.44h14.22V1.8c0-.99.81-1.8 1.8-1.8s1.8.81 1.8 1.8v1.44h3.06c1.89 0 3.42 1.53 3.42 3.42V30.6c0 2.98-2.42 5.4-5.4 5.4Zm-27-22.68V30.6c0 .99.81 1.8 1.8 1.8h25.2c.99 0 1.8-.81 1.8-1.8V13.32H3.6Zm0-6.48v2.88h28.8V6.84h-2.89c-.1.99-.98 1.71-1.97 1.62-.86-.08-1.53-.76-1.62-1.62H11.69c-.1.99-.98 1.71-1.97 1.62-.86-.08-1.53-.76-1.62-1.62H3.6Zm17.64 19.44H8.28c-.99 0-1.8-.81-1.8-1.8s.81-1.8 1.8-1.8h12.96c.99 0 1.8.81 1.8 1.8s-.81 1.8-1.8 1.8Zm-6.48-6.48H8.28c-.99 0-1.8-.81-1.8-1.8s.81-1.8 1.8-1.8h6.48c.99 0 1.8.81 1.8 1.8s-.81 1.8-1.8 1.8Z"
      style={{
        fill: "#44444f",
      }}
    />
  </Svg>
)

export default CalendarIcon
