import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;


const generateNewPdf = function (meetings, date, employee, action) {

    var bodyMeetings = function (meetings) {

        var bodyContent = [];

        if (meetings.length > 0) {
            meetings.forEach(function (element, index) {
                if (index === 0) bodyContent.push(['HEURE', 'SALON', 'CLIENT', 'ANIMAL', 'PRESTATION', 'DUREE', 'PRIX', 'PRIX PAYE', 'COMMENTAIRE RDV']);
                if (new Date(element.datePlanned).toLocaleDateString('fr-FR') == date.toLocaleDateString('fr-FR')) bodyContent.push([("0" + (new Date(element.datePlanned).getUTCHours())).slice(-2) + ":" + ("0" + (new Date(element.datePlanned).getUTCMinutes())).slice(-2), element.Salon.name, element.Customer.lastName + " " + element.Customer.name, element.Animal.name + ' (' + (element.Animal.type == "dog" ? "Chien" : "Chat") + " | " + element.Animal.race + " )", element.Prestation.name, Math.trunc(element.duration + element.aditionalTime) + "H" + ("0" + (60 * ((element.duration + element.aditionalTime) % 1))).slice(-2), element.price, element.endPrice, element.comments]);
            });
        }


        return bodyContent;
    }

    var listMeetings = bodyMeetings(meetings);


    var dd = {
        pageOrientation: 'landscape',
        content: [
            {
                text: 'Planning du ' + date.toLocaleDateString('fr-FR') + ' pour ' + employee,
                style: 'header'
            },
            {
                style: 'tableExample',
                table: {
                    heights: 50,
                    widths: [50, 100, 100, 100, 25, 50, 50, 50, '*'],
                    body: listMeetings

                }
            }
        ],
        styles: {
            tableExample:
            {
                fontSize: 8,
                width: '100%'
            },
            header: {
                fontSize: 18,
                bold: true,
                alignment: "center",
                marginBottom: 20,
            },
            subheader: {
                fontSize: 15,
                bold: true
            },
            quote: {
                italics: true
            },
            small: {
                fontSize: 8
            }
        }

    }

    if (action == "print") {
        pdfMake.createPdf(dd).print();
    }
    else {
        pdfMake.createPdf(dd).open();
    }

};

export default generateNewPdf
