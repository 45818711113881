import client from './client';

const getSalons = () => client.get('/getSalons');
const getListSalons = () => client.get('/getListSalons');
const newSalon = (datas) => client.post('/newSalon', datas);
const getSalonInfos = (salonId) => client.get("/getSalonInfos?salonId=" + salonId);
const updateSalon = (datas) => client.post("/updateSalon", datas);
/*const getAllInterventions = () => client.get(endpoint + 'getAllInterventions');
const updateIntervention = (datas) => client.put(endpoint + "updateIntervention", datas);
const getPOS = () => client.get("pointOfSale/getAllPointOfSales");
const getSellerPOS = () => client.get("pointOfSale/getSellerPos");
const newIntervention = (datas) => client.post(endpoint + "createIntervention", datas);
const generatePDF = (datas) => client.put(endpoint + "generateRapport2", datas);
const downloadPDF = (name) => client.get(endpoint+"downloadPDF/"+name);*/

export default
    {
        getSalons, newSalon, getListSalons, getSalonInfos, updateSalon
    }