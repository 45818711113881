import { useFonts } from 'expo-font';
import { Image, StyleSheet, Text, TouchableOpacity, View, ScrollView } from 'react-native';
import React, { useEffect, useState } from 'react';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import moment from 'moment';
import xlsx from "json-as-xlsx"


import { Button, TextField, ButtonGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import colors from '../utils/colors';
import { Fragment } from 'react';
import CalendarIcon from '../../assets/svg/calendarIcon';
import ContactIcon from '../../assets/svg/contactIcon';
import ReactToPrint from 'react-to-print';

import ReturnIcon from '../../assets/svg/returnIcon';
import CustomerIcon from '@mui/icons-material/PermContactCalendar';
import MeetingIcon from '@mui/icons-material/CalendarMonth';
import { IconButton } from '@mui/material';

const screenHeight = window.innerHeight;

// IMPORT SCREENS //
import InlineMeetingScreen from "./InlineMeetingScreen";
// FIN SCREENS //

//IMPORT DATAS // 
import salonsApi from '../api/salons';
import customerApi from '../api/customer';
import animalApi from '../api/animal';
import prestaApi from '../api/presta';
import authStorage from '../auth/storage';
import AuthContext from '../auth/context';
import meetingApi from '../api/meeting';
import statApi from '../api/stat';
//  FIN DATAS // 

//Gestion couleurs//
const alternanceColors = [{ border: colors.blue, bg: colors.lightBlue }, { border: colors.yellow, bg: colors.egg }, { border: colors.red, bg: colors.lightPink }];
//Gestions couleurs//


import generateNewPdf from '../utils/pdfSalon';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;


function StatsScreen({ id, name, dateToShow, view, currentScreen, setCurrentScreen }) {

    
    const [stats, setStats] = useState(null);
    const [salonId, setSalonId] = useState(id);
    const [meetingList, setMeetingList] = useState([]);
    const [popUp, setPopUp] = useState(false);
    const [popUpData, setPopUpData] = useState(null);

    const loadStats = async() => {
        const result = await statApi.getStats({"duration": dayMonth == "day" ? "custom" : dayMonth, "date": currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1) + "-" + String(currentDate.getDate()).padStart(2, '0'), "dateEnd": dateTo.getFullYear() + "-" + (dateTo.getMonth() + 1) + "-" + String(dateTo.getDate()).padStart(2, '0')});
        if(result.error === undefined)
        {
            console.log(result.data);
            setStats(result.data);
        }
    }


    const exportExcel = async() => {
        if(stats != null)
        {
            var fileName = "Statistiques DOG MODEL - ";
            if(dayMonth == "year") fileName += "Année " + currentDateString.year;
            if(dayMonth == "month") fileName += currentDateString.short;
            if(dayMonth == "day") fileName += "Du " + currentDate.toLocaleDateString("fr") + " au "+ dateTo.toLocaleDateString("fr")
            var employeesContent = [];
            stats.employees.forEach(function(item, index){
                employeesContent.push({name: item.name, soldHours: item.soldHours, surbooking: item.surbooking, amount: item.amount + " €", totalAmount: item.totalAmount + "€"})
            });
            var salonsContent = [];
            stats.salons.forEach(function(item, index){
                salonsContent.push({name: item.name, soldHours: item.soldHours, surbooking: item.surbooking, amount: item.amount + " €", totalAmount: item.totalAmount + "€"})
            });
            var originsContent = [];
            stats.origins.forEach(function(item, index){
                originsContent.push({name: item.origin, soldHours: item.soldHours, surbooking: item.surbooking, amount: item.amount + " €", totalAmount: item.totalAmount + "€"})
            });
            var data = [
                {
                  sheet: "Global",
                  columns: [
                    { label: "Désignation", value: "name" },
                    { label: "Valeur", value: "value" },

                  ],
                  content: [
                    { name: "RDV créés", value: stats.newMeetings }, // Top level data
                    { name: "RDV planifiés", value: stats.plannedMeetings},
                    { name: "RDV non cloturés", value: stats.waitingMeetings},
                    { name: "RDV annulés", value: stats.cancelMeetings},
                    { name: "Dont Noshows", value: stats.noshowMeetings},
                    { name: "Heures vendues", value: stats.soldHours},
                    { name: "Chiffre d'affaire estimé", value: stats.amount + "€"},
                    { name: "Chiffre d'affaire final", value: stats.totalAmount + "€"},
                  ],
                },
                {
                  sheet: "Toiletteurs",
                  columns: [
                    {label: "Nom", value:"name"},
                    {label: "Heures vendues", value:"soldHours"},
                    {label: "Dont surbooking", value:"surbooking"},
                    {label: "Chiffre d'affaire estimé", value:"amount"},
                    {label: "Chiffre d'affaire réel", value:"totalAmount"}

                  ],
                  content: employeesContent,
                },
                {
                    sheet: "Salons",
                    columns: [
                      {label: "Salon", value:"name"},
                      {label: "Heures vendues", value:"soldHours"},
                      {label: "Dont surbooking", value:"surbooking"},
                      {label: "Chiffre d'affaire estimé", value:"amount"},
                      {label: "Chiffre d'affaire réel", value:"totalAmount"}
  
                    ],
                    content: salonsContent,
                  },
                  {
                    sheet: "Origines",
                    columns: [
                      {label: "Origine", value:"name"},
                      {label: "Heures vendues", value:"soldHours"},
                      {label: "Dont surbooking", value:"surbooking"},
                      {label: "Chiffre d'affaire estimé", value:"amount"},
                      {label: "Chiffre d'affaire réel", value:"totalAmount"}
  
                    ],
                    content: originsContent,
                  },
              ]
              
              let settings = {
                fileName: fileName, // Name of the resulting spreadsheet
                extraLength: 3, // A bigger number means that columns will be wider
              }
    
              xlsx(data, settings);
        }
    }

    var getDates = function (thisDate) {
        console.log(thisDate);
        var year = thisDate.getFullYear();
        var month = thisDate.getMonth();
        var date = new Date(year, month, 1);
        console.log(year, month);
        var names = ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'];
        var result = { "lun": [], "mar": [], "mer": [], "jeu": [], "ven": [], "sam": [], "dim": [] };
        var firstDay = names[date.getDay()];
        for (var i = 0; i < names.length; i++) {
            if (firstDay == names[i] && firstDay != "dim") break;
            if (names[i] != "dim") result[names[i]].push({ "completeDate": null });
        }

        while (date.getMonth() == month) {

            var element = { "dateObject": date.toISOString(), "completeDate": date.toLocaleDateString('fr-FR'), 'date': date.getDate(), "day": names[date.getDay()] };
            result[element.day].push(element);
            date.setDate(date.getDate() + 1);


        }
        return result;
    }


    const getCurrentDateString = function (type) {
        var days = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
        var months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
        var day = days[currentDate.getDay() - 1];
        var month = months[currentDate.getMonth()];
        if(type == "year")
        {
            return currentDate.getFullYear();
        }
        if (dayMonth == "day" && type == "long") {
            return day + " " + currentDate.getDate() + " " + month + " " + currentDate.getFullYear();
        }
        else {
            return month + " " + currentDate.getFullYear();
        }
    }

    const getCurrentDateToString = function (type) {
        var days = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
        var months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
        var day = days[dateTo.getDay() - 1];
        var month = months[dateTo.getMonth()];
        if(type == "year")
        {
            return dateTo.getFullYear();
        }
        if (dayMonth == "day" && type == "long") {
            return day + " " + dateTo.getDate() + " " + month + " " + dateTo.getFullYear();
        }
        else {
            return month + " " + dateTo.getFullYear();
        }
    }

    const generatePdf = (action) => {
        generateNewPdf(meetingList, currentDate, name, action);
    }

    const updateDates = function (type, value) {

        console.log("-------------");
        console.log(currentDate);
        console.log(dayMonth);

        if (type === "change") {
            setCurrentDate(value);
        }
        if (type == "add") {
            setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + value)));
        }
        if (type == "remove") {
            setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - value)))
        }

    }

    const [value, setValue] = useState(null);
    const [dayMonth, setDayMonth] = useState(view ? view : "month");
    const [currentDate, setCurrentDate] = useState(dateToShow ? new Date(dateToShow) : new Date());
    const [currentDateString, setCurrentDateString] = useState({ "short": getCurrentDateString("short"), "long": getCurrentDateString("long"), "year": getCurrentDateString("year") });
    const [dateTo, setDateTo] = useState(dateToShow ? new Date(dateToShow) : new Date());
    const [currentDateToString, setCurrentDateToString] = useState({ "short": getCurrentDateToString("short"), "long": getCurrentDateToString("long"), "year": getCurrentDateToString("year") });
    const [datePickerOpened, setDatePickerOpened] = useState(false);
    const [datePickerToOpened, setDatePickerToOpened] = useState(false);

    var workingTime = ["7:00", "20:00"];


    var times = function (hours) {
        var timeList = [];
        var startingHour = hours[0];
        var lastHour = hours[1];
        var date = moment("01/01/2001 " + startingHour);

        console.log(date.format("HH:mm"));

        while (date.format("HH:mm") != lastHour) {
            timeList.push(date.format("HH:mm"));
            date.add(15, "m");
        }

        timeList.push(date.format("HH:mm"))

        return timeList;
    };

    const timesList = times(workingTime);


    const setDuration = function (meetings, type) {
        var durationMorning = 0.0;
        var durationAfternoon = 0.0;
        var durationDay = 0.0;

        meetings.forEach(function (element) {
            var time = new Date(element.datePlanned).getUTCHours();
            durationDay += element.duration + element.aditionalTime;
        });

        return moment.utc(moment.duration(durationDay, "hours").as('milliseconds')).format('HH:mm');

    }

    const generateDayEmployee = function (value, date) {
        //VALUE = 0-JULIE 1-[...RDV]
        var thisDayEmployee = [];
        value[1].map(function (element, index) {
            if (new Date(element.datePlanned).toLocaleDateString('fr-FR') == date.completeDate) {
                thisDayEmployee.push(element);
            }
        });

        console.log(thisDayEmployee);
        if (thisDayEmployee.length > 0) {
            return (
                <View style={styles.employeeDay}>
                    <View style={styles.gridEmployeeName}><Text style={styles.gridEmployeeLabel}>{value[0]}</Text></View>
                    <TouchableOpacity onPress={() => {
                        updateDates("change", new Date(date.dateObject)); setDayMonth("day"); console.log("RESULTAT HEURES");
                    }} style={[styles.gridBox, styles.gridBorder, { width: "50%" }]}><Text style={{ fontFamily: "poppinsSemiBold" }}>{setDuration(thisDayEmployee)}</Text></TouchableOpacity>
                </View>
            );
        }
        else {
            return (
                <View style={styles.employeeDay}>

                </View>
            );
        }
    }

    var dates = getDates(currentDate);

    useEffect(() => {
        setCurrentDateString({ "short": getCurrentDateString("short"), "long": getCurrentDateString("long"), "year": getCurrentDateString("year") });
        setCurrentDateToString({ "short": getCurrentDateToString("short"), "long": getCurrentDateToString("long"), "year": getCurrentDateToString("year") });
        loadStats();
    }, [currentDate, dateTo, dayMonth, id, name, popUp]);




    return (
        <ScrollView style={styles.body}>
            <View style={styles.screenTitle}>
                <Text style={styles.pageTitle}>Statistiques</Text>
                <Text style={styles.titleSeparator}>|</Text>
                <Text style={styles.pageAction}>{dayMonth == "year" ? "Année courante" : dayMonth == "month" ? "Mois courant" : dayMonth == "day" ? "Période personnalisée" : ""}</Text>
            </View>
            <View style={styles.screenContainer}>
                <View style={styles.screenControllers}>
                    <View style={[styles.anchorLeftControllers, { zIndex: 300 }]}>
                        <Button variant="contained" onClick={() => { currentScreen.lastScreen !== undefined && currentScreen.lastScreen !== null ? setCurrentScreen({ type: currentScreen.lastScreen.type, data: currentScreen.lastScreen.data }) : null }} style={{ boxShadow: "none", fontFamily: "poppins", fontSize: 12, backgroundColor: "transparent", color: colors.ebony, borderRadius: 7, marginTop: 5, marginBottom: 5, textTransform: 'none', marginRight: 20 }} startIcon={<ReturnIcon height={25} width={30} style={{ fill: colors.ebony, marginRight: 10 }} resizeMode="contain" />}>Retour</Button>
                    </View>
                    <View style={styles.centeredControllers}>
                        <Text style={styles.controllerTitle}>Sélectionner la période</Text>
                        <View style={styles.buttonGroup}>
                        <Button variant="contained" onClick={() => { if (dayMonth != "year") { setDayMonth("year"); updateDates("change", currentDate) } else null }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 10, backgroundColor: dayMonth == "year" ? colors.cashmere : colors.linen, color: colors.ebony, borderRadius: 7, textTransform: 'none', marginRight: 5 }}>Année</Button>
                            <Button variant="contained" onClick={() => { if (dayMonth != "month") { setDayMonth("month"); updateDates("change", currentDate) } else null }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 10, backgroundColor: dayMonth == "month" ? colors.cashmere : colors.linen, color: colors.ebony, borderRadius: 7, textTransform: 'none', marginRight: 5 }}>Mois</Button>
                            <Button variant="contained" onClick={() => { if (dayMonth != "day") { setDayMonth("day"); updateDates("change", currentDate) } else null }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 10, backgroundColor: dayMonth == "day" ? colors.cashmere : colors.linen, color: colors.ebony, borderRadius: 7, textTransform: 'none' }}>Personnalisée</Button>
                        </View>
                    </View>
                    <View style={styles.anchorRightControllers}>
                        {stats != null ? (
                            <Button variant="contained" onClick={() => exportExcel()} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: colors.ebony, color: "#FFFFFF", borderRadius: 7, textTransform: 'none', marginRight: 20 }}>Exporter en fichier Excel</Button>
                        ) : null}
                    </View>

                </View>
                <View style={styles.screenControllers}>
                    <View style={styles.centeredControllers}>
                        {dayMonth == "month" ? <Text style={styles.pageTitle}>{currentDateString.long}</Text> : dayMonth == "year" ? <Text style={styles.pageTitle}>{currentDateString.year}</Text> : (
                            <Fragment>
                                <Text style={{ fontFamily: "poppinsSemiBold", textTransform: "uppercase", fontWeight: "bold", fontSize: 20, color: colors.ebony, marginRight: 10, marginTop: 1}}> Du </Text>
                                <View>
                                    <Button style={{ backgroundColor: 'transparent', fontFamily: "poppinsSemiBold", textTransform: "uppercase", fontWeight: "bold", fontSize: 20, color: colors.ebony, padding: 0 }} endIcon={<ExpandMoreIcon />} onClick={() => setDatePickerOpened(datePickerOpened => !datePickerOpened)} >{currentDateString.long}</Button>
                                    <MobileDatePicker
                                        sx={{ datepicker: { fontFamily: "poppins" } }}
                                        format='MMMM dd, yyyy'
                                        label="Selectionner une date"
                                        variant="static"
                                        value={currentDate}
                                        open={datePickerOpened}
                                        onOpen={() => setDatePickerOpened(true)}
                                        onClose={() => setDatePickerOpened(false)}
                                        onChange={(newValue) => {
                                            setCurrentDate(new Date(newValue));
                                            setDatePickerOpened(false)
                                        }}
                                        componentsProps={{
                                            actionBar: {
                                                // The actions will be the same between desktop and mobile
                                                actions: [""]
                                            }
                                        }}
                                        disableOpenPicker={true}
                                        renderInput={(params) => null}
                                    />
                                </View>
                                <Text style={{ fontFamily: "poppinsSemiBold", textTransform: "uppercase", fontWeight: "bold", fontSize: 20, color: colors.ebony, marginRight: 10, marginLeft: 10, marginTop: 1 }}> au </Text>
                                <View>
                                    <Button style={{ backgroundColor: 'transparent', fontFamily: "poppinsSemiBold", textTransform: "uppercase", fontWeight: "bold", fontSize: 20, color: colors.ebony, padding: 0 }} endIcon={<ExpandMoreIcon />} onClick={() => setDatePickerToOpened(datePickerToOpened => !datePickerToOpened)} >{currentDateToString.long}</Button>
                                    <MobileDatePicker
                                        sx={{ datepicker: { fontFamily: "poppins" } }}
                                        format='MMMM dd, yyyy'
                                        label="Selectionner une date"
                                        variant="static"
                                        value={dateTo}
                                        open={datePickerToOpened}
                                        onOpen={() => setDatePickerToOpened(true)}
                                        onClose={() => setDatePickerToOpened(false)}
                                        onChange={(newValue) => {
                                            setDateTo(new Date(newValue));
                                            setDatePickerToOpened(false)
                                        }}
                                        componentsProps={{
                                            actionBar: {
                                                // The actions will be the same between desktop and mobile
                                                actions: [""]
                                            }
                                        }}
                                        disableOpenPicker={true}
                                        renderInput={(params) => null}
                                    />
                                </View>
                            </Fragment>
                            
                        )}

                        <View style={styles.anchorRightControllers}>
                            <ButtonGroup variant="outlined" aria-label="outlined button group">
                                <Button style={{ borderColor: "#E2E2EA", color: colors.ebony, paddingLeft: 5, paddingRight: 5 }} onClick={() => updateDates("remove", dayMonth == "year" ? 12 : 1)}>
                                    <KeyboardArrowLeftIcon />
                                </Button>
                                <Button disabled={true} style={{ borderColor: "#E2E2EA", color: colors.ebony, fontFamily: "poppins" }}>{dayMonth != "year" ? currentDateString.short : currentDateString.year}</Button>
                                <Button style={{ borderColor: "#E2E2EA", color: colors.ebony, paddingLeft: 5, paddingRight: 5 }} onClick={() => updateDates("add", dayMonth == "year" ? 12 : 1)}>
                                    <KeyboardArrowRightIcon />
                                </Button>
                            </ButtonGroup>
                        </View>
                    </View>
                </View>
                <Text style={{ width: "100%", backgroundColor: colors.red, color: "#ffffff", textAlign: "center", marginBottom: 10 }}>POUR QUE LES STATISTIQUES SOIENT JUSTES, S'ASSURER DE BIEN CLOTURER LES RENDEZ-VOUS.</Text>
                {
                    stats != [] && stats !== null ? (
                        <View style={styles.gridContainer}>
                            <View style={[styles.statBox, {width: "50%"}]}>
                                <Text style={styles.statTitle}>Statistiques globaux pour la période</Text>
                                <View style={styles.statTable}>
                                    <View style={styles.statRow}>
                                        <Text style={styles.statColumn}>Rdv créés</Text>
                                        <Text style={styles.statColumn}>{stats.newMeetings}</Text>
                                    </View>
                                    <View style={styles.statRow}>
                                        <Text style={styles.statColumn}>rdv plannifiés</Text>
                                        <Text style={styles.statColumn}>{stats.plannedMeetings}</Text>
                                    </View>
                                    <View style={styles.statRow}>
                                        <Text style={styles.statColumn}>Rdv non cloturés</Text>
                                        <Text style={styles.statColumn}>{stats.waitingMeetings}</Text>
                                    </View>
                                    <View style={styles.statRow}>
                                        <Text style={styles.statColumn}>Rdv annulés</Text>
                                        <Text style={styles.statColumn}>{stats.cancelMeetings}</Text>
                                    </View>
                                    <View style={styles.statRow}>
                                        <Text style={[styles.statColumn, {fontStyle: "italic"}]}>(Dont noshows)</Text>
                                        <Text style={styles.statColumn}>{stats.noshowMeetings}</Text>
                                    </View>
                                    <View style={styles.statRow}>
                                        <Text style={styles.statColumn}>Heures vendues</Text>
                                        <Text style={styles.statColumn}>{stats.soldHours}</Text>
                                    </View>
                                    <View style={styles.statRow}>
                                        <Text style={styles.statColumn}>CA prévu</Text>
                                        <Text style={styles.statColumn}>{stats.amount} €</Text>
                                    </View>
                                    <View style={styles.statRow}>
                                        <Text style={styles.statColumn}>CA final</Text>
                                        <Text style={styles.statColumn}>{stats.totalAmount} €</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={[styles.statBox, {width: "50%"}]}>
                                <Text style={styles.statTitle}>Statistiques par toiletteur</Text>
                                <View style={styles.statTable}>
                                    <View style={styles.statRow}>
                                        <Text style={[styles.statColumn, {width: "20%", fontSize: 10}]}></Text>
                                        <Text style={[styles.statColumn, {width: "30%", fontSize: 10}]}>Heures vendues</Text>
                                        <Text style={[styles.statColumn, {width: "30%", fontSize: 10}]}>Dont surbooking</Text>
                                        <Text style={[styles.statColumn, {width: "10%", fontSize: 10}]}>CA estimé</Text>
                                        <Text style={[styles.statColumn, {width: "10%", fontSize: 10}]}>CA réel</Text>
                                    </View>
                                    {
                                        
                                        stats.employees.map((item) => (
                                            <View style={styles.statRow}>
                                                <Text style={[styles.statColumn, {width: "20%", fontSize: 10}]}>{item.name}</Text>
                                                <Text style={[styles.statColumn, {width: "30%", fontSize: 10}]}>{item.soldHours}</Text>
                                                <Text style={[styles.statColumn, {width: "30%", fontSize: 10}]}>{item.surbooking}</Text>
                                                <Text style={[styles.statColumn, {width: "10%", fontSize: 10}]}>{item.amount} €</Text>
                                                <Text style={[styles.statColumn, {width: "10%", fontSize: 10}]}>{item.totalAmount} €</Text>
                                            </View>
                                        ))
                                        
                                    }
                                </View>
                            </View>
                            <View style={[styles.statBox, {width: "50%"}]}>
                                <Text style={styles.statTitle}>Statistiques par salon</Text>
                                <View style={styles.statTable}>
                                    <View style={styles.statRow}>
                                        <Text style={[styles.statColumn, {width: "20%", fontSize: 10}]}></Text>
                                        <Text style={[styles.statColumn, {width: "30%", fontSize: 10}]}>Heures vendues</Text>
                                        <Text style={[styles.statColumn, {width: "30%", fontSize: 10}]}>Dont surbooking</Text>
                                        <Text style={[styles.statColumn, {width: "10%", fontSize: 10}]}>CA estimé</Text>
                                        <Text style={[styles.statColumn, {width: "10%", fontSize: 10}]}>CA réel</Text>
                                    </View>
                                    {
                                        stats.salons.map((item) => (
                                            <View style={styles.statRow}>
                                                <Text style={[styles.statColumn, {width: "20%", fontSize: 10}]}>{item.name}</Text>
                                                <Text style={[styles.statColumn, {width: "30%", fontSize: 10}]}>{item.soldHours}</Text>
                                                <Text style={[styles.statColumn, {width: "30%", fontSize: 10}]}>{item.surbooking}</Text>
                                                <Text style={[styles.statColumn, {width: "10%", fontSize: 10}]}>{item.amount} €</Text>
                                                <Text style={[styles.statColumn, {width: "10%", fontSize: 10}]}>{item.totalAmount} €</Text>
                                            </View>
                                        ))
                                    }
                                </View>
                            </View>
                            <View style={[styles.statBox, {width: "50%"}]}>
                                <Text style={styles.statTitle}>Statistiques par origines</Text>
                                <View style={styles.statTable}>
                                    <View style={styles.statRow}>
                                        <Text style={[styles.statColumn, {width: "20%", fontSize: 10}]}></Text>
                                        <Text style={[styles.statColumn, {width: "30%", fontSize: 10}]}>Heures vendues</Text>
                                        <Text style={[styles.statColumn, {width: "30%", fontSize: 10}]}>Dont surbooking</Text>
                                        <Text style={[styles.statColumn, {width: "10%", fontSize: 10}]}>CA estimé</Text>
                                        <Text style={[styles.statColumn, {width: "10%", fontSize: 10}]}>CA réel</Text>
                                    </View>
                                    {
                                        stats.origins.map((item) => (
                                            <View style={styles.statRow}>
                                                <Text style={[styles.statColumn, {width: "20%", fontSize: 10}]}>{item.origin}</Text>
                                                <Text style={[styles.statColumn, {width: "30%", fontSize: 10}]}>{item.soldHours}</Text>
                                                <Text style={[styles.statColumn, {width: "30%", fontSize: 10}]}>{item.surbooking}</Text>
                                                <Text style={[styles.statColumn, {width: "10%", fontSize: 10}]}>{item.amount} €</Text>
                                                <Text style={[styles.statColumn, {width: "10%", fontSize: 10}]}>{item.totalAmount} €</Text>
                                            </View>
                                        ))
                                    }
                                </View>
                            </View>
                        </View>
                    ) : <Text style={{ width: "100%",  color: colors.ebony, textAlign: "center", marginTop: 50 }}>CHARGEMENT...</Text>
                }
                

            </View>
        </ScrollView>
    );
}

export default StatsScreen;

const styles = StyleSheet.create({

    statBox: {
        padding: 20,
    },  

    statTable: {
        width: "100%",
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 10,
        paddingBottom: 10,
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap"
    },

    statRow: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
    },

    statColumn: {
        width: "50%",
        borderColor: colors.ebony,
        borderWidth: 1,
        textAlign: "left",
        textTransform: "uppercase",
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 10

    },  

    statTitle: {
        backgroundColor: 'transparent', 
        fontFamily: "poppinsSemiBold", 
        textTransform: "uppercase", 
        textAlign: "center", 
        fontWeight: "bold", 
        fontSize: 15, 
        color: colors.ebony
    },  

    gridContainer: {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "row",
        flexWrap: "wrap"
    },

    inlineMeeting: {
        zIndex: 200,
        flex: 1,
        flexDirection: "row",
        top: 0,
        width: "100%",
        position: "absolute",
        marginTop: 100
    },

    mainView: {
        backgroundColor: colors.smokedWhite,
        flex: 1,
    },

    monthPicker: {
        borderColor: "#E2E2EA"
    },

    eventText: {
        flex: 1,
        flexDirection: "row",
        wdith: "100%",
        marginBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: "rgba(0, 0, 0, 0.1)",
        paddingTop: 5,
        paddingBottom: 5
    },

    eventTextTitle: {
        fontFamily: "poppinsSemiBold",
        marginRight: 10
    },

    eventGrid: {
        flex: 1,
        width: "100%",
        flexDirection: "row",
        top: 0,
        paddingLeft: 70,
        position: "absolute"

    },

    eventRow: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },

    eventColumn: {
        flex: 1,
        flexDirection: "column",
        paddingLeft: 5,
        paddingRight: 5
    },

    eventHeader: {
        height: 40,
        borderRadius: 25,
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 20
    },

    eventHeaderTitle: {
        fontFamily: "poppinsSemiBold",
        fontSize: 16
    },

    eventContent: {
        flex: 1,
        flexDirection: "column",
    },

    eventBox: {
        position: "absolute",
        top: 0,
        width: "100%",
        borderWidth: 2,
        borderRadius: 10,
        height: 100,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20
    },

    eventButtons: {
        position: "absolute",
        right: 20,
        top: 10,
        flexDirection: "row",
    },

    hourText: {
        textAlign: "center",
        width: 60,
        fontFamily: "poppinsSemiBold"
    },

    hoursGrid: {
        marginLeft: 10,
        marginRight: 10,
        paddingTop: 60,
        position: "relative",
    },

    hourRow: {
        height: 25,
        borderTopWidth: 1,
        borderColor: colors.charcoal,
        justifyContent: "center",

    },

    screenControllers: {
        marginBottom: 40,
        alignContent: "center",
        justifyContent: "center",
        textAlign: "center"
    },

    centeredControllers:
    {
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center"
    },

    controllerTitle: {
        fontSize: 12,
        fontFamily: "poppinsSemiBold",
        textAlignVertical: "center",
        marginTop: 8,
        marginRight: 10
    },

    buttonGroup:
    {
        flexDirection: "row",
        borderRadius: 7,
        padding: 2,
        backgroundColor: colors.milk
    },

    anchorRightControllers: {
        flexDirection: "row",
        position: "absolute",
        right: 15,
    },

    anchorLeftControllers: {
        flexDirection: "row",
        position: "absolute",
        left: 15,
    },

    monthCalendar: {
        width: "100%",
        flex: 1,
        zIndex: 50,
        flexDirection: "row"
    },

    periodLabel: {
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center"
    },

    employeeDay: {
        flexDirection: "row",
        marginBottom: 2,
    },

    gridEmployeeName: {
        flex: 1
    },

    gridEmployeeLabel:
    {
        fontFamily: "poppinsSemiBold",
        color: colors.ebony
    },

    gridBox: {
        width: "25%",
        alignItems: "center"
    },

    gridBorder: {
        borderWidth: 0.5,
        borderColor: colors.charcoal
    },

    dayColumn:
    {
        flex: 1,
    },

    dayTitle:
    {
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: 50,
        backgroundColor: colors.smokedWhite,
        marginBottom: 20
    },

    dateBox:
    {
        minHeight: 150,
        borderWidth: 1,
        borderColor: colors.charcoal,
        paddingLeft: 10,
        paddingBottom: 10
    },

    dayNumber: {
        paddingLeft: 10,
        fontFamily: "poppins",
        color: colors.mist,
        fontSize: 12,
        paddingTop: 5,
        paddingBttom: 5
    },

    navbar: {
        height: 75,
        width: "100%",
        backgroundColor: "#FFFFFF",
        borderBottomColor: "#E2E2EA",
        borderBottomWidth: 2,
        flexDirection: "row",
        position: "fixed",
        zIndex: 10
    },

    switchScreen: {
        backgroundColor: colors.ebony,
        width: "100%",
        height: 50,
        alignItems: "center",
        justifyContent: "center"
    },

    screenContainer: {
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        width: "100%",
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 20,
        paddingBottom: 20,
        flex: 1
    },

    categoryButton: {
        backgroundColor: colors.linen,
        width: "100%",
        height: 50,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 0
    },

    logoContainer: {
        width: 225,
        height: "100%",
        padding: 5,
        alignItems: "center",
        borderRightColor: "#E2E2EA",
        borderRightWidth: 1
    },

    shortcutContainer: {
        flex: 1,
        flexDirection: "row",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10
    },

    searchContainer: {
        flex: 1
    },

    screenTitle: {
        borderRadius: 5,
        width: "100%",
        height: 50,
        backgroundColor: colors.linen,
        marginBottom: 10,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },

    titleSeparator: {
        marginLeft: 20,
        marginRight: 20,
        fontSize: 20,
        color: "#D8D8D8"
    },

    pageAction: {
        fontSize: 18,
        fontWeight: "600",
        color: colors.mist
    },

    pageTitle: {
        fontFamily: "poppinsSemiBold",
        textTransform: "uppercase",
        fontWeight: "bold",
        fontSize: 20,
        color: colors.ebony
    },

    searchInput: {
        width: 300
    },

    mainContainer: {
        flex: 1,
        flexDirection: "row"
    },

    aside: {
        paddingTop: 20,
        marginTop: 75,
        width: 225,
        height: screenHeight - 75,
        backgroundColor: colors.snow,
        borderRightColor: "#E2E2EA",
        borderRightWidth: 1,
        position: "fixed",
        zIndex: 10
    },

    asideContainer: {
        flex: 1,
    },



});
