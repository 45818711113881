import { create } from 'apisauce';
import authStorage from '../auth/storage';

const apiClient = create({
    baseURL: 
    "https://dogmodel.herokuapp.com/api"
    //"http://192.168.1.74:3000/api"
    //http://10.33.43.95:3000/api
    //http://192.168.1.71:3000/api
    //http://192.168.1.58:3000/api
    //https://dogmodel.herokuapp.com/api
});
//baseURL: "https://DogModelapp.com/api/"
apiClient.addAsyncRequestTransform(async (request) => {
    const authToken = await authStorage.getToken();
    console.log('token', authToken);
    if (!authToken) return;
    request.headers["Authorization"] = "Bearer " + authToken;
});

export default apiClient;
